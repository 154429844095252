import { axiosConfigInstance } from "../config";

// 관리자 로그인
export const apiAdmLogin = async(admin_email:any,admin_password:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/account').post('',{
            method: 'adminLoginAccount',
            params:{
                admin_email,admin_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 방문자 수 (어제,오늘,통합)
export const apiAdmGetVisit = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatUserVisitCount',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 방문자 차트
export const apiAdmGetChartVisit = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatChartUserVisit',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 차트
export const apiAdmGetChartOrder = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatChartOrder',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 차트
export const apiAdmGetChartInquiry = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatChartInquiry',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 차트
export const apiAdmGetChartReview = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatChartReview',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 총 건수 가져오기
export const apiAdmGetTotalCount = async(token:string,admin_id:any,part:'faq'|'review'|'product'|'order'|'inquiry') =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatCount',
            params:{
                admin_id,part
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

