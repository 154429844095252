import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import LeftNavBar from '../../../common/part/LeftNavBar';
import styled from 'styled-components';
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { fileTypeRes, phoneNumberFormat, valueChange } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { apiGetInquiryCate, apiSetInquiry } from '../../../../api/api_faq';

export default function AdminInquiryUpdatePage() {
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [cate,setCate] = useState('');
    const [cateList,setCateList] = useState<any>([]);
    const [content,setContent] = useState('');
    const fileRef = useRef<any>(null);
    const [file,setFile] = useState<any>(null);
    const [filePreview,setFilePreview] = useState('');

    useEffect(()=>{
        getCateList();
    },[])

    const getCateList = async() =>{
        const res = await apiGetInquiryCate(1,100);
        if(res.result){
            setCateList(res.data);
            setCate(res.data[0].inquiry_category_id);
        }
    }

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const btnAction = async(e:any) =>{
        if(name === '' || phone === '' || email === '' || content === '' || cate === ''){
            alert('값을 입력해주세요.')
            return;
        }
        if(!isEmail(email)){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiSetInquiry(name,phone,email,cate,content,'adm_imcompany123!',file);
        if(res.result){
            alert('문의를 등록했습니다.');
            reset();
        }else{
            alert(res.error);
        }
    }

    const reset = () =>{
        setName('');
        setPhone('');
        setEmail('');
        setContent('');
        setCate(cateList[0].inquiry_category_id);
        fileRef.current.value = null;
        setFilePreview('');
        setFile(null);
    }

    return (
        <LeftNavBar idKey='4'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>문의 등록</BasicText>
                    <InnerWrap onSubmit={btnAction}>
                        <ContentWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>작성자</BasicText>
                                <InputWrap>
                                    <input value={name} onChange={(e:any)=>setName(e.target.value)} maxLength={25} />
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>연락처</BasicText>
                                <InputWrap>
                                    <input value={phoneNumberFormat(phone)} onChange={(e:any)=>setPhone(valueChange(e))} maxLength={15} />
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>이메일</BasicText>
                                <InputWrap>
                                    <input value={email} onChange={(e:any)=>setEmail(e.target.value)} maxLength={60} />
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>카테고리</BasicText>
                                <InputWrap style={{padding:0}}>
                                    <SelectItem value={cate} setValue={setCate} none='none'>
                                        {cateList === undefined || cateList.length === 0 ?
                                            <MenuItem value={''}>{''}</MenuItem> :
                                            cateList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`inquiry-cate-${index}`} value={data.inquiry_category_id}>{data.inquiry_title}</MenuItem>
                                                )
                                            })
                                        }
                                    </SelectItem>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120} align='start'>
                                <BasicText>문의내용</BasicText>
                                <div style={{width:'100%'}}>
                                    <InputWrap>
                                        <textarea value={content} onChange={(e:any) => setContent(e.target.value)} rows={6} maxLength={2000}></textarea>
                                    </InputWrap>
                                    <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                    <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                    {filePreview === '' ?
                                        <></> :
                                        <FilePreviewWrap>
                                            <img src={filePreview} alt='file-img'></img>
                                        </FilePreviewWrap>
                                    }
                                </div>
                            </ItemWrap>
                        </ContentWrap>
                        <Row style={{marginTop:'20px'}}>
                            <BtnItem bg={addTheme.main} padding='14px 25px' onClick={btnAction}>문의 등록</BtnItem>
                        </Row>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.form`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;
