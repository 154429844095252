import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useNavigate, useParams } from 'react-router';
import QnaInquiryPart from '../part/QnaInquiryPart';
import QnaContactItem from '../item/QnaContactItem';
import QnaListPart from '../part/QnaListPart';
import { apiGetFaqCate } from '../../../api/api_faq';

export default function QnaPage() {
    const {tab} = useParams();
    const navigate = useNavigate();
    const [faqCateList,setFaqCateList] = useState([]);
    const tabClick = (id:any) =>{
        navigate(`/qna/${id}`);
    }

    useEffect(()=>{
        getCate();
    },[])

    const getCate = async() =>{
        const res = await apiGetFaqCate(1,20);
        if(res.result){
            setFaqCateList(res.data);
        }
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <Wrap>
                    <TitleWrap>
                        <BasicText fontSize={38} mFontSize={24}  fontWeight={700} spColor={addTheme.main}><span>마이폰</span> 고객센터</BasicText>
                    </TitleWrap>
                    <SelectWrap>
                        <SelectBtnItem state={tab === '2'} onClick={()=>tabClick('2')}>자주하는 질문</SelectBtnItem>
                        <SelectBtnItem state={tab === '1'} onClick={()=>tabClick('1')}>1:1 문의</SelectBtnItem>
                    </SelectWrap>
                    <ContentWrap>
                        {tab === '1' &&
                            <QnaInquiryPart />
                        }
                        {tab === '2' &&
                            <QnaListPart faqCateList={faqCateList} />
                        }
                    </ContentWrap>
                </Wrap>
                <QnaContactItem />
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Center)`
    margin-top: 100px;
`;

const SelectWrap = styled(Center)`
    gap: 20px;
    margin-top: 30px;
`;
const SelectBtnItem = styled(Center)<{state:boolean}>`
    background-color: ${({state}) => state ? addTheme.main : '#f0f0f0'};
    color: ${({state}) => state ? '#fff' : '#888'};
    width: 180px;
    height: 58px;
    cursor: pointer;
    border-radius: ${addTheme.round}px;
    font-size: 20px;
    @media ${addTheme.media.s}{
        width: 140px;
        height: 42px;
        font-size: 16px;
    }
`;

const ContentWrap = styled.div`
    width: 100%;
    max-width: 1080px;
    margin: 40px auto 0;

`;
