import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Center, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { useNavigate, useParams } from 'react-router';
import { addTheme } from '../../../../style/addTheme';
import { ADMIN_REVIEW_PAGE } from '../../../../data/tableTitle';
import { TableCell, TableRow } from '@mui/material';
import { apiAdmGetReview, apiAdmSetReviewDel } from '../../../../api/adm/adm_api_review';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { momentFormat } from '../../../../utils/format/time';

const ListItem = ({data,itemClick,delClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[0].align}>{data.review_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[1].align}>{data.phone_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[2].align}>
                <BasicText line={1}>{data.review_content}</BasicText>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[3].align}>{data.review_user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[4].align}>{momentFormat('YYYY-MM-DD hh:mm',data.review_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_REVIEW_PAGE[5].align}>
                <Center style={{gap:'8px'}}>
                    <DetailBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={itemClick}>상세</DetailBtnItem>
                    <DelBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={delClick}>삭제</DelBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminReviewPage() {
    const {page} = useParams();
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getList();
    },[page,adminInfo.admin_user_id])

    const setPage = (p:any) =>{
        navigate(`/adm/review/${p}`);
    }

    const goReviewUpdate = (id?:any) =>{
        navigate(`/adm/reviewUpdate/${id ?? ''}`)
    }

    const getList = async() =>{
        const res = await apiAdmGetReview(adminInfo.admin_jwt_token,adminInfo.admin_user_id,page ?? 1,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const delClick = async(id:any) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            const res = await apiAdmSetReviewDel(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('삭제 했습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <LeftNavBar idKey='3'>
            <Wrap>
                <AdminPageTotalPart title='전체후기' part='review'>
                    <BtnItem bg='#ff7600' onClick={()=>goReviewUpdate()}>후기등록</BtnItem>
                </AdminPageTotalPart>
                <AdminWrap>
                    <TitleWrap>
                        <BasicText fontSize={18} mFontSize={16} fontWeight={700}>후기관리</BasicText>
                    </TitleWrap>
                    <TableWrap>
                        <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_REVIEW_PAGE} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <ListItem key={`review-item-${index}`} data={data} itemClick={()=>goReviewUpdate(data.review_id)} delClick={()=>delClick(data.review_id)}/>
                                    )
                                })
                            }
                        </AdminTablePart>
                    </TableWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;

const DetailBtnItem = styled(BtnItem)``;
const DelBtnItem = styled(BtnItem)``;