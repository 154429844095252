import React from 'react'
import styled from 'styled-components'
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADMIN_HOME_INQUIRY } from '../../../../data/tableTitle';
import { TableCell, TableRow } from '@mui/material';
import { momentFormat } from '../../../../utils/format/time';
import { useNavigate } from 'react-router';

const ListItem = ({data,itemClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={ADMIN_HOME_INQUIRY[0].align} onClick={itemClick}>{data.inquiry_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_HOME_INQUIRY[1].align}>{momentFormat('YYYY-MM-DD hh:mm',data.inquiry_question_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function AdminHomeInquiryPart({list}:any) {
    const navigate = useNavigate();
    const itemClick = (id:any) =>{
        navigate(`/adm/inquiryAnswer/${id}`);
    }
    return (
        <Wrap>
            <AdminTablePart title={ADMIN_HOME_INQUIRY}>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any) =>{
                        return(
                            <ListItem key={`inquiry-list-${index}`} data={data} itemClick={()=>itemClick(data.inquiry_id)}/>
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div``;

