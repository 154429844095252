export const INQUIRY_STATE:any = {"0":"준비중","1":"답변완료"};
export const PRODUCT_STATE:any ={
    "0": "비활성화",
    "1": "활성화"
}
export const PRODUCT_STATE_R:any ={
    "0": "활성화",
    "1": "비활성화",
}

export const ORDER_USER:any = {
    "1":"성인",
    "2":"미성년자"
}

export const DISCOUNT_TYPE:any = {
    "1": '공시지원금',
    "2": '선택약정할인'
}

export const DISCOUNT_TYPE_DESC:any = {
    "1":{
        title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',
    },
    "2":{
        title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인'
    }
}