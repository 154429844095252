import React, { useEffect, useRef, useState } from 'react'
import { BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import styled from 'styled-components';
import { addTheme } from '../../../../style/addTheme';
import AdminProductAddColorItem from '../item/AdminProductAddColorItem';

const FILE_INIT = {file:null,preview:'',fileData:''};

export default function AdminProductAdd2Part({data,setData,prev,next}:any) {
    const imgRef = useRef<any>(null);
    const contentImgRef = useRef<any>(null);
    const [thumbnail,setThumbnail] = useState<any>(FILE_INIT);
    const [content,setContent] = useState<any>(FILE_INIT);
    const [colorText,setColorText] = useState('');
    const [colorList,setColorList] = useState<any>([]);

    useEffect(()=>{
        setData((obj:any)=>({...obj,phone_color: colorList}));
    },[colorList]);

    useEffect(()=>{
        setData((obj:any)=>({...obj,phone_thumbnail: thumbnail.fileData}));
    },[thumbnail.fileData])

    useEffect(()=>{
        setData((obj:any)=>({...obj,phone_desc_image: content.fileData}));
    },[content.fileData])

    const imgInputClick = () =>{
        if(imgRef.current){
            imgRef.current.click();
        }
    }
    const contentInputClick = () =>{
        if(contentImgRef.current){
            contentImgRef.current.click();
        }
    }
    // 썸네일 파일 선택
    const thumbnailChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const preImg = URL.createObjectURL(file);
            const reader = new FileReader();
            setThumbnail((obj:any)=>({...obj,file:file,preview:preImg}))
            reader.onload = () => {
                const result = reader.result;
                setThumbnail((obj:any)=>({...obj,fileData:result}))
            };

            reader.readAsDataURL(file);
        }
    }
    // 상품정보 파일 선택
    const contentChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const preImg = URL.createObjectURL(file);
            const reader = new FileReader();
            setContent((obj:any)=>({...obj,file:file,preview:preImg}))
            reader.onload = () => {
                const result = reader.result;
                setContent((obj:any)=>({...obj,fileData:result}))
            };

            reader.readAsDataURL(file);
        }
    }

    const colorCreateList = (val:string) =>{
        setColorText(val);
        const arr = val.split(',');
        const newArr = arr.filter((data:any)=>data).map((data:any)=>({"color_name": data, "color_hex": "#000000", "color_image": ''}));
        setColorList(newArr);
    }
    
    return (
        <Wrap>
            <ContentWrap gap={'15px'}>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>색상</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <input value={colorText} onChange={(e:any)=>colorCreateList(e.target.value)} placeholder='색상을 ","로 구분해서 입력해주세요. ex)바이올렛, 그레이, 블랙'/>
                        </InputWrap>
                        
                        {colorList.length === 0 ?
                            <></> :
                            <ColorListWrap>
                                {colorList.map((item:any,index:any)=>{
                                    return(
                                        <AdminProductAddColorItem key={`color-${index}`} item={item} setColorList={setColorList}/>
                                    )
                                })}
                            </ColorListWrap>
                        }
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>썸네일</BasicText>
                    <div style={{width:'100%'}}>
                        <FileInputWrap>
                            <InputWrap>
                                <BasicText>{thumbnail?.file !== null ? thumbnail?.file.name : '이미지 파일명'}</BasicText>
                                <input className='file-input' ref={imgRef} type='file' accept='image' onChange={thumbnailChange}/>
                            </InputWrap>
                            <ImgShowBtn onClick={imgInputClick}>
                                이미지 등록
                            </ImgShowBtn>
                        </FileInputWrap>
                        {thumbnail?.preview ?
                            <PreImgWrap>
                                <img src={thumbnail?.preview}></img>
                            </PreImgWrap> :
                            <></>
                        }
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>상품정보</BasicText>
                    <div style={{width:'100%'}}>
                        <FileInputWrap>
                            <InputWrap>
                                <BasicText>{content?.file !== null ? content?.file.name : '이미지 파일명'}</BasicText>
                                <input className='file-input' ref={contentImgRef} type='file' accept='image' onChange={contentChange}/>
                            </InputWrap>
                            <ImgShowBtn onClick={contentInputClick}>
                                이미지 등록
                            </ImgShowBtn>
                        </FileInputWrap>
                        {content?.preview ?
                            <PreImgWrap>
                                <img src={content?.preview}></img>
                            </PreImgWrap> :
                            <></>
                        }
                    </div>
                </ItemWrap>
            </ContentWrap>
            <Row style={{marginTop:'20px',gap:'15px'}}>
                <BtnItem bg={'#ff7600'} padding='10px 25px' onClick={prev}>이전</BtnItem>
                <BtnItem bg={'#ad63ff'} padding='10px 25px' onClick={next}>저장</BtnItem>
            </Row>
        </Wrap>
    )
}

const Wrap = styled.div``;

const FileInputWrap = styled(Row)`
    align-items: stretch;
    gap: 10px;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
    & .file-input{
        display: none;
    }
`;

const ColorListWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
`;

const ImgShowBtn = styled(Center)`
    flex-shrink: 0;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 18px;
    cursor: pointer;
`;

const PreImgWrap = styled.div`
    width: 100%;
    max-width: 280px;
    margin-top: 10px;
    &>img{
        width: 100%;
    }
`;