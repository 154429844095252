import { axiosConfigInstance } from "../config";

// 관리자 배너 리스트
// banner_part_type: 0- 전체 / 1-메인배너 / 2-팝업
export const apiAdmGetBannerList= async(token:string,admin_id:any,page:any,count:any,banner_part_type:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/banner',token).post('',{
            method: 'getAdminBannerList',
            params:{
                admin_id,page,count,banner_part_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 배너 삭제
export const apiAdmSetDelBanner = async(token:string,admin_id:any,banner_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/banner',token).post('',{
            method: 'adminBannerStatusChange',
            params:{
                admin_id,banner_id,banner_status:'0',
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 배너 등록
export const apiAdmSetBanner = async(token:string,admin_id:any,banner_title:any,banner_part_type:'1'|'2',banner_link_url:any,banner_order:any,banner_img_web:any,banner_img_mo:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/banner',token).post('',{
            method: 'adminBannerAdd',
            params:{
                admin_id,banner_title,banner_part_type,banner_link_url,banner_order,banner_img_web,banner_img_mo
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 배너 수정
export const apiAdmSetBannerUpdate = async(token:string,admin_id:any,banner_id:any,banner_title:any,banner_part_type:'1'|'2',banner_link_url:any,banner_order:any,banner_img_web:any,banner_img_mo:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/banner',token).post('',{
            method: 'adminBannerInfoEdit',
            params:{
                admin_id,banner_id,banner_title,banner_part_type,banner_link_url,banner_order,banner_img_web,banner_img_mo
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 배너 디테일
export const apiAdmGetBannerDetail = async(token:string,admin_id:any,banner_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/banner',token).post('',{
            method: 'getAdminBannerDetailInfo',
            params:{
                admin_id,banner_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}