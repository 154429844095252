import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import BasicText from '../../basic/BasicText';
import { Center, ContentWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { fileTypeRes } from '../../../utils/format/string';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import StarPart from '../../common/part/StarPart';
import { apiGetPhList } from '../../../api/api_phone';
import { apiSetReview } from '../../../api/api_review';

export default function ReviewCreatePage() {
    const [name,setName] = useState('');
    const [product,setProduct] = useState('');
    const [itemList,setItemList] = useState<any>([]);
    const [content,setContent] = useState('');
    const fileRef = useRef<any>(null);
    const [file,setFile] = useState<any>(null);
    const [filePreview,setFilePreview] = useState('');
    const [chk,setChk] = useState(false);
    const [starCount,setStarCount] = useState(0);

    useEffect(()=>{
        getItemList();
    },[])

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const getItemList = async() =>{
        const res = await apiGetPhList(1,50);
        
        if(res.result){
            setItemList(res.data);
            setProduct(res.data[0].phone_id);
        }
    }

    const createAction = async(e:any) =>{
        e.preventDefault();
        if(!chk){
            alert('개인정보 수집 및 이용을 동의해주세요.');
        }
        if(name === '' || starCount === 0 || product === '' ){
            alert('값을 입력해주세요.');
        }
        
        const res = await apiSetReview(product,name,starCount,content,file);
        if(res.result){
            alert('구매후기를 작성했습니다.');
            reset();
        }else{
            alert(res.error);
        }
    }

    const reset = () =>{
        location.reload();
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center' ls={1.5}>휴대폰 구매후기 작성</BasicText>
                    <BasicText fontSize={20} mFontSize={15} align='center' marginTop={1}>휴대폰을 구매한 후기를 작성하여 주세요.</BasicText>
                </TitleWrap>
                <Wrap>
                    <form onSubmit={createAction}>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>작성자 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={name} onChange={(e:any) => setName(e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>제품명 <span>*</span></BasicText>
                                <ItemInputWrap style={{padding:0}}>
                                    <SelectItem value={product} setValue={setProduct} none={'none'} width='100%'>
                                        {itemList === undefined || itemList.length === 0 ?
                                            <MenuItem value={''}></MenuItem> :
                                            itemList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`cate-${index}`} value={data.phone_id}>{data.phone_model}</MenuItem>
                                                )
                                            })
                                        }
                                    </SelectItem>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>별점 <span>*</span></BasicText>
                                <ItemInputWrap style={{border:'none'}}>
                                    <StarPart count={starCount} setCount={setStarCount} size={20} colorText='#000'/>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap align='start'>
                                <BasicText>후기 내용</BasicText>
                                <div style={{width:'100%'}}>
                                    <ItemInputWrap>
                                        <textarea value={content} onChange={(e:any) => setContent(e.target.value)} rows={6} maxLength={1000}></textarea>
                                    </ItemInputWrap>
                                    <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                    <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                    {filePreview === '' ?
                                        <></> :
                                        <FilePreviewWrap>
                                            <img src={filePreview} alt='file-img'></img>
                                        </FilePreviewWrap>
                                    }
                                </div>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>개인정보 수집 및 이용 <span>*</span></BasicText>
                                <label>
                                    <input type='checkbox' onChange={(e:any)=>setChk(e.target.checked)} required></input> 동의
                                </label>
                            </ItemWrap>
                        </ContentWrap>
                        <BtnWrap type='submit'>작성완료</BtnWrap>
                    </form>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 100px;
`;
const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 60px auto 0;
`;
const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;