import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import ContentBuyReviewItem from '../item/ContentBuyReviewItem';
import ContentInquiryItem from '../item/ContentInquiryItem';
import ContentQnaItem from '../item/ContentQnaItem';
import { useObserver } from '../../../utils/custom-hooks/common/useObserver';
import { addTheme } from '../../../style/addTheme';
import { IMG_URL } from '../../../api/config';

export default function ContentPart({info}:any) {
    const [titleIdx,setTitleIdx] = useState<any>(0);
    const refContent = useObserver(0,setTitleIdx);
    const refReview = useObserver(1,setTitleIdx);
    const refInquiry = useObserver(2,setTitleIdx);
    const refQna = useObserver(3,setTitleIdx);


    const scMove = (ref:any,idx:number) =>{
        if(ref === null) return;
        ref.current.scrollIntoView({ behavior: 'auto',block: idx === 0 ? 'start' : 'center',inline: 'nearest' });
    }

    const titleClick = (idx:number) =>{
        setTitleIdx(idx);
        if(idx === 0) {
            scMove(refContent,idx);
        }else if(idx === 1){
            scMove(refReview,idx);
        }else if(idx === 2){
            scMove(refInquiry,idx);
        }else if(idx === 3){
            scMove(refQna,idx);
        }
    }
    return (
        <Wrap>
            <TitleWrap>
                <TitleInnerWrap titleIdx={titleIdx}>
                    <TitleItem state={titleIdx === 0} onClick={()=>titleClick(0)}>상품정보</TitleItem>
                    <TitleItem state={titleIdx === 1} onClick={()=>titleClick(1)}>구매후기</TitleItem>
                    <TitleItem state={titleIdx === 2} onClick={()=>titleClick(2)}>1:1문의</TitleItem>
                    <TitleItem state={titleIdx === 3} onClick={()=>titleClick(3)}>자주하는질문</TitleItem>
                </TitleInnerWrap>
            </TitleWrap>
            <ContentWrap ref={refContent} className='scrollElement' id='sc0'>
                <img src={info?.phone_desc_image} alt='content'></img>
            </ContentWrap>
            <BuyReviewWrap ref={refReview} className='scrollElement'id='sc1'>
                <ContentBuyReviewItem />
            </BuyReviewWrap>
            <InquiryWrap ref={refInquiry} className='scrollElement'id='sc2'>
                <ContentInquiryItem />
            </InquiryWrap>
            <QnaWrap ref={refQna} className='scrollElement'id='sc3'>
                <ContentQnaItem />
            </QnaWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const TitleWrap = styled.div`
    position: sticky;
    top:0;
    left:0;
    width: 100%;
    background-color: #fff;
    z-index: 50;
    border-bottom: 1px solid #dcdcdc;
`;

const TitleInnerWrap = styled(Row)<{titleIdx:number}>`
    max-width: 400px;
    padding: 5px 0;
    gap: 6px;
    position: relative;
    &::after{
        content:'';
        width: 25%;
        position: absolute;
        bottom: 0;
        left: ${({titleIdx}) => titleIdx * 25}%;
        height: 2px;
        background-color: #000;
        transition: 0.3s ease;
    }
`;
const TitleItem = styled(Center)<{state:boolean}>`
    width: calc(25% - 5px);
    padding: 8px 0;
    cursor: pointer;
    color: ${({state}) => state ? '#000' : '#999'};
    position: relative;
    border-radius: 2px;
    text-align: center;
    &:hover{
        background-color: ${({state}) => state ? 'transparent' : '#f5f5f5'};
    }
    &::after{
        content: '|';
        position: absolute;
        right: -6px;
    }
    &:nth-last-of-type(1)::after{
        content: none;
    }
    @media ${addTheme.media.s}{
        font-size: 15px;
    }
`;
const ContentWrap = styled.div`
    padding-top: 10px;
    
    & img{
        width: 100%;
    }
`;

const BuyReviewWrap = styled.div`
    padding-top: 45px;
`;
const InquiryWrap = styled.div`
    margin-top: 20px;
    padding-top: 60px;
`;

const QnaWrap = styled.div`
    margin-top: 20px;
    padding-top: 60px;
`;