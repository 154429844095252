import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../../style/basic/commonStyle';

export default function AdminTablePart({minWidth,title,children,bg,color,page,setPage,total}:any) {
    const pageChange = (p:any) =>{
        if(setPage){
            setPage(p);
        }
    }
    return (
        <Wrap>
            <TableContainer>
                <Table sx={{ minWidth: minWidth ?? 500 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {title === undefined || title.length === 0 ?
                                <></> :
                                title.map((data:any,index:any)=>{
                                    return(
                                        <TableCell key={`table-${index}`} sx={{width:`${data.size}%`,padding:'10px',border:'none',backgroundColor:bg ?? '#fff',color: color ?? '#000'}} align={data.align}>{data.name}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
            {page &&
                <PaginWrap>
                    <Pagination shape="rounded" page={typeof page === 'string' ? Number(page) : page} count={total} onChange={(e:any, page:any) => pageChange(page)} sx={{'.MuiPagination-ul li button':{color:'#7d7d7d'},'.MuiPagination-ul li .Mui-selected':{color:'#111'}}} color='secondary'></Pagination>
                </PaginWrap>
            }
        </Wrap>

    )
}

const Wrap = styled.div``;
const PaginWrap = styled(Row)`
    justify-content: end;
    margin-top: 20px;
`;