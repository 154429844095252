import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_ADMIN_USER_LOGIN = 'subox-dev/common/SET_ADMIN_USER_LOGIN';
const SET_ADMIN_USER_LOGOUT = 'subox-dev/common/SET_ADMIN_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_ADMIN_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_ADMIN_USER_LOGOUT);

export function setAdminUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface userInfo {
  "admin_jwt_token": string;
  "admin_user_address":string;
  "admin_user_code":string;
  "admin_user_id":number;
  "admin_user_membership":string;
  "admin_user_nickname":string;
  "admin_user_profile":string;
  "admin_user_token":string;
}

export const userState: userInfo = {
  "admin_jwt_token":'',
  "admin_user_address":"",
  "admin_user_code":"",
  "admin_user_id":0,
  "admin_user_membership":'0',
  "admin_user_nickname":"",
  "admin_user_profile":"",
  "admin_user_token":"",
}

export const adminUser = handleActions<userInfo, any>({
  [SET_ADMIN_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
    admin_jwt_token: action.payload.data.admin_jwt_token ?? state.admin_jwt_token,
    admin_user_address: action.payload.data.admin_user_address ?? state.admin_user_address,
    admin_user_code: action.payload.data.admin_user_code ?? state.admin_user_code,
    admin_user_id: action.payload.data.admin_user_id ?? state.admin_user_id,
    admin_user_membership: action.payload.data.admin_user_membership ?? state.admin_user_membership,
    admin_user_nickname: action.payload.data.admin_user_nickname ?? state.admin_user_nickname,
    admin_user_profile: action.payload.data.admin_user_profile ?? state.admin_user_profile,
    admin_user_token: action.payload.data.admin_user_token ?? state.admin_user_token,
  })
,
  [SET_ADMIN_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (userState),
}, userState);
