import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ContentPageItem from './ContentPageItem';
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { apiGetFaq, apiGetFaqCate } from '../../../api/api_faq';

const ListItem = ({data}:any) =>{
    const [state,setState] = useState(false);
    const clickAction = () =>{
        setState(!state);
    }
    return (
        <ItemWrap>
            <ItemTitleWrap onClick={clickAction}>
                <BasicText fontWeight={700} fontSize={24}>Q</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15}>{data.faq_question}</BasicText>
            </ItemTitleWrap>
            <ItemContentWrap state={state}>
                <BasicText fontWeight={700} fontSize={24}>A</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15} wh='pre-wrap'>{data.faq_answer}</BasicText>
            </ItemContentWrap>
        </ItemWrap>
    )
}

export default function ContentQnaItem() {
    const navigate = useNavigate();
    const [titleIdx,setTitleIdx] = useState(0);
    const [cateList,setCateList] = useState<any>([])
    const [page,setPage] = useState(1);
    const [list,setList] = useState([]);

    useEffect(()=>{
        getCate();
    },[])

    useEffect(()=>{
        getList();
    },[titleIdx])

    const titleClick = (idx:number) =>{
        if(titleIdx === idx) return;
        setTitleIdx(idx);
    }

    const writeBtnClick = () =>{
        navigate('/inquiryCreate');
    }
    
    const getCate = async() =>{
        const res = await apiGetFaqCate(1,20);
        if(res.result){
            setCateList(res.data);
            setTitleIdx(res.data[0].faq_category_id);
        }
    }

    const getList = async() =>{
        const res = await apiGetFaq(titleIdx,1,50);
        if(res.result){
            setList(res.data);
        }
    }

    return (
        <Wrap>
            <TitleWrap>
                {cateList === undefined || cateList.length === 0 ?
                    <></> :
                    cateList.map((data:any,index:any) => {
                        return(
                            <TitleItem key={`faq-title-${index}`} onClick={()=>titleClick(data.faq_category_id)} state={titleIdx === data.faq_category_id}><BasicText line={1}>{data.faq_category_title}</BasicText></TitleItem>
                        )
                    })
                }
            </TitleWrap>
            <ContentWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`faq-${index}`} data={data} />
                        )
                    })
                }
            </ContentWrap>
            <PageWrap>
                <ContentPageItem total={0} btnName='문의 하기' btnAction={writeBtnClick}/>
            </PageWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const PageWrap = styled.div`
    margin-top: 10px;
    width: 100%;
`;

const TitleWrap = styled(Row)``;
const TitleItem = styled(Center)<{state:boolean}>`
    padding: 12px 15px;
    border: 1px solid #dcdcdc;
    border-left: none;
    border-bottom: none;
    max-width: 150px;
    &:nth-of-type(1){
        border-left: 1px solid #dcdcdc;
    }
    font-size: 15px;
    color:  ${({state}) => state ? '#fff' : '#555'};
    cursor: pointer;
    &:hover{
        background-color: ${({state}) => state ? addTheme.main : '#f4f4f4'};
    }
    background-color: ${({state}) => state ? addTheme.main : 'transparent'};
`;
const ContentWrap = styled.div`
    border-top: 1px solid #000;
`;

const ItemWrap = styled.div``;
const ItemTitleWrap = styled(Row)`
    align-items: start;
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc;
    &>p:nth-of-type(1){
        min-width: 55px;
        flex-shrink: 0;
    }
    cursor: pointer;
`;
const ItemContentWrap = styled(ItemTitleWrap)<{state:boolean}>`
    cursor: default;
    background-color: #fafafa;
    display: ${({state}) => state ? 'flex':'none'};
`;
