import { axiosConfigInstance } from "../config";

// 문의 리스트
export const apiAdmGetInquiry = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/inquiry',token).post('',{
            method: 'getAdminInquiryList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 삭제
export const apiAdmSetInquiryDel = async(token:string,admin_id:any,inquiry_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/inquiry',token).post('',{
            method: 'adminInquiryDelete',
            params:{
                admin_id,inquiry_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 디테일
export const apiAdmGetInquiryDetail = async(token:string,admin_id:any,inquiry_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/inquiry',token).post('',{
            method: 'getAdminInquiryDetailInfo',
            params:{
                admin_id,inquiry_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 답변
export const apiAdmSetInquiryAnswer = async(token:string,admin_id:any,inquiry_id:any,inquiry_answer:any,inquiry_answer_image:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/inquiry',token).post('',{
            method: 'adminInquiryAddAnswer',
            params:{
                admin_id,inquiry_id,inquiry_answer,inquiry_answer_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
