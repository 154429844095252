import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LineChartPart from '../../common/part/LineChartPart';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetChartInquiry, apiAdmGetChartOrder, apiAdmGetChartReview, apiAdmGetChartVisit } from '../../../../api/adm/adm_api_login';
import { momentFormat } from '../../../../utils/format/time';

export default function AdminHomeChartPart() {
    const adminInfo = useAdminInfo();
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getList();
    },[adminInfo.admin_user_id]);

    const getList = async() =>{
        const visit = await apiAdmGetChartVisit(adminInfo.admin_jwt_token,adminInfo.admin_user_id,30);
        const order = await apiAdmGetChartOrder(adminInfo.admin_jwt_token,adminInfo.admin_user_id,30);
        const inquiry = await apiAdmGetChartInquiry(adminInfo.admin_jwt_token,adminInfo.admin_user_id,30);
        const review = await apiAdmGetChartReview(adminInfo.admin_jwt_token,adminInfo.admin_user_id,30);
        let list1:any = [];
        let list2:any = [];
        let list3:any = [];
        let list4:any = [];
        if(visit.result){
            list1 = visit.data.map((data:any)=>({name: momentFormat('MM-DD',data.timestamp),visitor:data.count}));
        }
        if(order.result){
            list2 = order.data.map((data:any)=>({name: momentFormat('MM-DD',data.timestamp),order:data.count}))
        }
        if(inquiry.result){
            list3 = inquiry.data.map((data:any)=>({name: momentFormat('MM-DD',data.timestamp),inquiry:data.count}))
        }
        if(review.result){
            list4 = review.data.map((data:any)=>({name: momentFormat('MM-DD',data.timestamp),review:data.count}))
        }
        
        const res = mergeArrays([list1,list2,list3,list4]);
        setList(res.reverse());
    }

    const mergeArrays = (arrays:any) => {
        const merged = arrays.reduce((acc:any, arr:any) => {
            arr.forEach((item:any) => {
                const existingItem = acc.find((i:any) => i.name === item.name);
                if (existingItem) {
                    Object.assign(existingItem, item);
                } else {
                    acc.push(item);
                }
            });
            return acc;
        }, []);
        return merged;
    };
    
    return (
        <Wrap>
            <InnerWrap>
                <LineChartPart list={list} />
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const InnerWrap = styled.div`
    width: 100%;
    min-width: 600px;
`;