import React, { useEffect, useState } from 'react'
import BasicText from '../../basic/BasicText';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';

export default function StarPart({colorStar,colorText,count,size,setCount}:any) {
    const [starCount,setStarCount] = useState(0);
    useEffect(()=>{
        setStarCount(count);
    },[count]);

    const starClick = (num:number) =>{
        if(setCount){
            setCount(num);
        }
    }
    return (
        <StarWrap>
            <StarIconWrap curType={setCount === undefined ? 'default' : 'pointer'}>
                <FaStar onClick={()=>starClick(1)} color={count >= 1 ? colorStar ?? '#074ca1' : '#dcdcdc'} size={size ?? 14}/>
                <FaStar onClick={()=>starClick(2)} color={count >= 2 ? colorStar ?? '#074ca1' : '#dcdcdc'} size={size ?? 14}/>
                <FaStar onClick={()=>starClick(3)} color={count >= 3 ? colorStar ?? '#074ca1' : '#dcdcdc'} size={size ?? 14}/>
                <FaStar onClick={()=>starClick(4)} color={count >= 4 ? colorStar ?? '#074ca1' : '#dcdcdc'} size={size ?? 14}/>
                <FaStar onClick={()=>starClick(5)} color={count >= 5 ? colorStar ?? '#074ca1' : '#dcdcdc'} size={size ?? 14}/>
            </StarIconWrap>
            <BasicText lh={1.2} color={colorText ?? '#074ca1'} fontWeight={700} fontSize={size === undefined ? 14 : size-2}>{starCount}점</BasicText>
        </StarWrap>
    )
}

const StarWrap = styled(Row)`
    width: auto;
    gap: 5px;
`;
const StarIconWrap = styled(Row)<{curType:string}>`
    width: auto;
    cursor: ${({curType}) => curType ?? 'default'};
`;