import React from 'react'
import styled from 'styled-components'
import { Center } from '../style/basic/commonStyle';
import BasicText from '../components/basic/BasicText';

export default function NoPage() {
    return (
        <Wrap>
            <Center>
                <BasicText fontSize={18}>404 | 없는 페이지입니다.</BasicText>
            </Center>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100%;
`;
