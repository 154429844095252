import React, { useEffect } from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { discountPlan, numberWithComma } from '../../../utils/format/number';
import { DISCOUNT_TYPE_DESC } from '../../../data/state';

export default function SelectDiscountItem({discountItem,action}:any) {
    const discount1 = (discountItem?.discount_price_1 ?? 0) === '' ? 0 : (discountItem?.discount_price_1 ?? 0);
    const discount2 = discountPlan(discountItem?.plan_price);
    const desc = DISCOUNT_TYPE_DESC[action?.get ?? '1'];
    
    
    useEffect(()=>{
        action.setDiscount(action?.get === '1' ? discount1 : discount2)
    },[action?.get,discount1,discount2])

    const clickAction = (data:any) =>{
        action.set(data);
    }

    return (
        <SelectItem title='할인방법'>
            <Wrap>
                <ItemWrap state={action?.get === '1'} onClick={()=>clickAction('1')}>
                    {`공시지원금\n${numberWithComma(discount1)}원`}
                    {discount1 > discount2 &&
                        <TypeTitle>{'추천'}</TypeTitle>
                    }
                </ItemWrap>
                <ItemWrap state={action?.get === '2'} onClick={()=>clickAction('2')}>
                    {`선택약정할인\n${numberWithComma(discount2)}원`}
                    {discount2 > discount1 &&
                        <TypeTitle>{'추천'}</TypeTitle>
                    }
                </ItemWrap>
            </Wrap>
            <ResWrap>
                <ResTitle>{desc.title}</ResTitle>
                <ResContent>{desc.content}</ResContent>
            </ResWrap>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 20px;
    max-width: 400px;
`;

const ItemWrap = styled(Center)<{state:boolean}>`
    width: 50%;
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    background-color: ${({state}) => state ? '#fff' : '#f4f4f4'};
    color: ${({state}) => state ? '#000' : '#777'};
    border-radius: ${addTheme.round}px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    height: 68px;
    word-break: break-word;
    white-space: pre-wrap;
    position: relative;
`;

const ResWrap = styled(Row)`
    gap: 6px;
    font-size: 13px;
    margin-top: 8px;
`;

const ResTitle = styled.div`
    color: #fff;
    background-color: #323232;
    padding: 4px 6px;
    border-radius: ${addTheme.round}px;
`;

const ResContent = styled.div`
    color: #333;
`;

const TypeTitle = styled(Center)`
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 11px;
    text-align: center;
`;