import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../../common/part/LeftNavBar'
import { AdminWrap, Row, RowBetween } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { addTheme } from '../../../../style/addTheme'
import AdminOrderDetailProcessPart from '../part/AdminOrderDetailProcessPart'
import AdminOrderDetailUserPart from '../part/AdminOrderDetailUserPart'
import AdminOrderDetailBuyPart from '../part/AdminOrderDetailBuyPart'
import { apiAdmGetOrderDetail } from '../../../../api/adm/adm_api_order'
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo'

export default function AdminOrderDetailPage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    useEffect(() =>{
        if(id === undefined || id === '0'){
            alert('잘못된 접근입니다.');
            navigate(-1);
        }
    },[id]);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0 || id === undefined || id === '0') return;
        getInfo();
    },[id,adminInfo.admin_user_id])

    const getInfo = async() =>{
        const res = await apiAdmGetOrderDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setInfo(res.data[0]);
        }
    }

    return (
        <LeftNavBar idKey='2'>
            <AdminWrap marginTop={0}>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>주문관리</BasicText>
                    <BasicText color='#3f8aff' wrapText='word-break'>{info?.order_code}</BasicText>
                </TitleWrap>
                <ContentWrap>
                    <LeftWrap>
                        <AdminOrderDetailProcessPart info={info} />
                        <AdminOrderDetailUserPart info={info} />
                    </LeftWrap>
                    <RightWrap>
                        <AdminOrderDetailBuyPart info={info} />
                    </RightWrap>
                </ContentWrap>
            </AdminWrap>
        </LeftNavBar>
    )
}

const TitleWrap = styled(Row)`
    gap: 20px;
    flex-wrap: wrap;
`;

const ContentWrap = styled(RowBetween)`
    gap: 25px;
    padding: 8px;
    margin-top: 10px;
    align-items: start;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 15px;
    }
`;

const LeftWrap = styled(Row)`
    width: 51%;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;
const RightWrap = styled.div`
    width: 49%;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;