
interface TYPE_TABLE_TITLE {
    id:number;
    name:string;
    size:number;
    align:'left'|'center'|'right';
}

export const ADMIN_HOME_ORDER:TYPE_TABLE_TITLE[] = [
    {id:1,name:'주문번호',size:15,align:'left'},
    {id:2,name:'모델명',size:20,align:'left'},
    {id:3,name:'변경통신사',size:15,align:'center'},
    {id:4,name:'구매유형',size:15,align:'center'},
    {id:5,name:'주문상태',size:15,align:'center'},
    {id:6,name:'주문시간',size:20,align:'center'},
]

export const ADMIN_HOME_INQUIRY:TYPE_TABLE_TITLE[] = [
    {id:1,name:'문의유형',size:70,align:'left'},
    {id:2,name:'문의시간',size:30,align:'center'},
]

export const ADMIN_HOME_REVIEW:TYPE_TABLE_TITLE[] = [
    {id:1,name:'휴대폰 기종',size:20,align:'left'},
    {id:2,name:'후기내용',size:40,align:'left'},
    {id:3,name:'후기시간',size:20,align:'center'},
]

export const ADMIN_ORDER_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:6, align: 'center'},
    {id:2,name:'통신사',size:8, align: 'center'},
    {id:3,name:'주문유형',size:10, align: 'center'},
    {id:4,name:'모델명',size:12, align: 'center'},
    {id:5,name:'고객명',size:8, align: 'center'},
    {id:6,name:'연락처',size:12, align: 'center'},
    {id:7,name:'처리상태',size:12, align: 'center'},
    {id:8,name:'주문시간',size:10, align: 'center'},
    {id:9,name:'처리시간',size:10, align: 'center'},
    {id:10,name:'관리',size:12, align: 'center'},
]

export const ADMIN_REVIEW_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'모델명',size:14, align: 'center'},
    {id:3,name:'내용',size:30, align: 'left'},
    {id:4,name:'고객명',size:12, align: 'center'},
    {id:5,name:'작성시간',size:16, align: 'center'},
    {id:6,name:'관리',size:18, align: 'center'},
]

export const ADMIN_INQUIRY_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:8, align: 'center'},
    {id:2,name:'문의유형',size:12, align: 'center'},
    {id:3,name:'작성자',size:8, align: 'center'},
    {id:4,name:'연락처',size:12, align: 'center'},
    {id:5,name:'답변상태',size:10, align: 'center'},
    {id:6,name:'작성시간',size:14, align: 'center'},
    {id:7,name:'답변시간',size:14, align: 'center'},
    {id:8,name:'관리',size:18, align: 'center'},
]

export const ADMIN_FAQ_PAGE_CATE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'순서',size:15, align: 'center'},
    {id:2,name:'제목',size:35, align: 'center'},
    {id:3,name:'개수',size:20, align: 'center'},
    {id:4,name:'관리',size:30, align: 'center'},
]

export const ADMIN_FAQ_PAGE_FAQ:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'카테고리',size:25, align: 'left'},
    {id:3,name:'제목',size:40, align: 'left'},
    {id:4,name:'관리',size:25, align: 'center'},
]

export const ADMIN_PRODUCT_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'브랜드',size:10, align: 'center'},
    {id:3,name:'모델명',size:14, align: 'center'},
    {id:4,name:'상품제목명',size:28, align: 'left'},
    {id:5,name:'출고가',size:14, align: 'center'},
    {id:6,name:'활성화여부',size:10, align: 'center'},
    {id:7,name:'관리',size:14, align: 'center'},
]

export const ADMIN_BANNER_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'제목',size:16, align: 'left'},
    {id:3,name:'PC 이미지',size:18, align: 'center'},
    {id:4,name:'모바일 이미지',size:18, align: 'center'},
    {id:5,name:'링크관리',size:18, align: 'left'},
    {id:6,name:'관리',size:20, align: 'center'},
]

export const ADMIN_POPUP_PAGE:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:15, align: 'center'},
    {id:2,name:'제목',size:15, align: 'left'},
    {id:3,name:'이미지',size:22, align: 'center'},
    {id:4,name:'링크관리',size: 33, align: 'left'},
    {id:5,name:'관리',size:15, align: 'center'},
]