import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { Center, ContentWrap, InputWrap, RowBetween } from '../../../style/basic/commonStyle';
import { IoSearchSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router';
import { apiGetPhList } from '../../../api/api_phone';

export default function SearchPart({open,setOpen}:any) {
    const navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [resSc,setResSc] = useState('');
    const [resList,setResList] = useState<any>([]);
    const [list,setList] = useState<any>([]);
    const ref = useRef<any>(null);

    useEffect(()=>{
        if(open){
            getList();
        }
    },[open])

    useEffect(()=>{
        setResList([]);
        setSearch('');
        setResSc('');
    },[open])

    const close = () =>{
        setOpen(false);
    }

    const searchAction = (e:any) =>{
        e.preventDefault();
        const searchList = list.filter((data:any)=>data.phone_name.includes(search) || data.phone_model.includes(search) || data.phone_description.includes(search))
        setResList(searchList);
        setResSc(search);
    }

    const clickAction = (id:any) =>{
        close();
        navigate(`/detail/${id}`);
    }

    const getList = async() =>{
        const res = await apiGetPhList(1,20);
        if(res.result){
            setList(res.data);
        }
    }

    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'460px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <BasicText marginTop={1} fontSize={18} align='center' fontWeight={700}>검색</BasicText>
                    <SearchWrap onSubmit={searchAction}>
                        <SearchInputWrap>
                            <InputWrap>
                                <input value={search} onChange={(e:any)=>setSearch(e.target.value)}></input>
                            </InputWrap>
                            <SearchItem type='submit'>
                                <IoSearchSharp size={28}/>
                            </SearchItem>
                        </SearchInputWrap>
                    </SearchWrap>
                    <ContentWrap mt={15}>
                        {resList === undefined ?
                            <></> :
                            resList.length === 0 && resSc !=='' ?
                            <Center>
                                검색 결과가 없습니다.
                            </Center> :
                            resList.map((data:any,index:any)=>{
                                return(
                                    <SearchResItem onClick={()=>clickAction(data.phone_id)} key={`search-res-${index}`}>
                                        <BasicText>{data.phone_name}</BasicText>
                                    </SearchResItem>
                                )
                            })
                        }
                    </ContentWrap>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div``;

const SearchWrap = styled.form``;
const SearchInputWrap = styled(RowBetween)`
    margin-top: 20px;
    gap: 20px;
`;
const SearchItem = styled.button``;
const SearchResItem = styled.div`
    cursor: pointer;
`;
