import { Dialog, DialogContent } from '@mui/material';
import React, { useRef } from 'react'
import DaumPostcode from "react-daum-postcode";
import styled from 'styled-components';

export default function OpenPlacePart({open,setOpen,setPlace}:any) {
    const ref = useRef<any>(null);

    const close = () =>{
        setOpen(false);
    }

    const handleComplete = (data:any) => {
        setPlace(data);
        close();
    }

    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                // '.MuiDialog-container':{alignItems:mediaQuery ? 'end' :'center',},
                '.MuiPaper-root':{margin:'8px', maxWidth:'460px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <DaumPostcode
                        autoClose={true}
                        style={{backgroundColor:'transparent',background:'transparent'}}
                        onComplete={handleComplete}
                    />
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div``;
