import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { Grid, TableCell, TableRow } from '@mui/material';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { addTheme } from '../../../../style/addTheme';
import { ADMIN_FAQ_PAGE_CATE, ADMIN_FAQ_PAGE_FAQ } from '../../../../data/tableTitle';
import { useNavigate } from 'react-router';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetFaq, apiAdmGetFaqCategory, apiAdmGetFaqCount, apiAdmSetFaqCategoryDel, apiAdmSetFaqDel } from '../../../../api/adm/adm_api_faq';

const FaqCateItem = ({data,count,itemClick,delClick}:any) =>{
    return(
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_CATE[0].align}>{data.faq_category_order}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_CATE[1].align}>{data.faq_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_CATE[2].align}>{count ?? 0}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_CATE[3].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={itemClick}>수정</BtnItem>
                    <BtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={delClick}>삭제</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

const FaqItem = ({data,itemClick,delClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_FAQ[0].align}>{data.faq_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_FAQ[1].align}>{data.faq_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_FAQ[2].align}>
                <BasicText line={1}>{data.faq_question}</BasicText>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_FAQ_PAGE_FAQ[3].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={itemClick}>수정</BtnItem>
                    <BtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={delClick}>삭제</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminFaqPage() {
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();
    const [count,setCount] = useState(0);
    const [faqCateList,setFaqCateList] = useState<any>([]);
    const [cateCountList,setCateCountList] = useState<any>([]);
    const [faqList,setFaqList] = useState<any>([]);

    const goFaqUpdate = (id?:any) =>{
        navigate(`/adm/faqUpdate/${id ?? ''}`);
    }

    const goFaqCateUpdate = (id?:any) =>{
        navigate(`/adm/faqCateUpdate/${id ?? ''}`);
    }

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getCount();
        getCateList();
        getList();
    },[adminInfo.admin_user_id]);

    const getCount = async() =>{
        const res = await apiAdmGetFaqCount(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setCateCountList(res.data);
        }
    }

    const getCateList = async() =>{
        const res = await apiAdmGetFaqCategory(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,100);
        if(res.result){
            setFaqCateList(res.data);
        }
    }
    const getList = async()=>{
        const res = await apiAdmGetFaq(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,100);
        if(res.result){
            setFaqList(res.data);
        }
    }

    const cateDel = async(id:any) =>{
        if(window.confirm('카테고리를 삭제하시겠습니까?')){
            const res = await apiAdmSetFaqCategoryDel(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('카테고리가 삭제되었습니다.');
                getCateList();
            }else{
                alert(res.error);
            }
        }
    }
    
    const faqDel = async(id:any) =>{
        if(window.confirm('faq를 삭제하시겠습니까?')){
            const res = await apiAdmSetFaqDel(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('faq가 삭제되었습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <LeftNavBar idKey='5'>
            <Wrap>
                <AdminPageTotalPart title='전체 FAQ' part='faq'>
                    <BtnItem bg={addTheme.main} onClick={()=>goFaqCateUpdate()}>FAQ 카테고리 등록</BtnItem>
                    <BtnItem bg='#ff7600' onClick={()=>goFaqUpdate()}>FAQ 등록</BtnItem>
                </AdminPageTotalPart>
                <Grid container={true} spacing={2}>
                    <Grid item md={6} xs={12} >
                        <AdminWrap>
                            <TitleWrap>
                                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 카테고리 관리</BasicText>
                            </TitleWrap>
                            <TableWrap>
                                <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_FAQ_PAGE_CATE} minWidth={580}>
                                    {faqCateList === undefined || faqCateList.length === 0 ?
                                        <></> :
                                        faqCateList.map((data:any,index:any) =>{
                                            const dataArr = cateCountList.filter((item:any)=>item.faq_category_id === data.faq_category_id)
                                            return(
                                                <FaqCateItem key={`faq-cate-${index}`} data={data} count={dataArr.length === 0 ? 0 : dataArr[0].faq_count} itemClick={()=>goFaqCateUpdate(data.faq_category_id)} delClick={()=>cateDel(data.faq_category_id)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        </AdminWrap>
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <AdminWrap>
                            <TitleWrap>
                                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 관리</BasicText>
                            </TitleWrap>
                            <TableWrap>
                                <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_FAQ_PAGE_FAQ} minWidth={640}>
                                    {faqList === undefined || faqList.length === 0 ?
                                        <></> :
                                        faqList.map((data:any,index:any)=>{
                                            return(
                                                <FaqItem key={`faq-${index}`} data={data} itemClick={()=>goFaqUpdate(data.faq_id)} delClick={()=>faqDel(data.faq_id)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        </AdminWrap>
                    </Grid>
                </Grid>
            </Wrap>

        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;