import axios from 'axios';

export const axiosConfigInstance = (path: string,token?:string,type?:any) => axios.create({
    baseURL: `https://myphone.or.kr/api${path}`,
    // baseURL: `http://192.168.11.5:10124/api${path}`,
    timeout: 50000,
    headers: {
        'authorization':token,
        ...type
    }
});

// export const IMG_URL = '';
// export const ADMIN_IMG_URL = 'http://192.168.0.18:10000/';
export const IMG_URL = 'https://myphone.or.kr';
export const ADMIN_IMG_URL = 'https://myphone.or.kr/';
// export const IMG_URL = 'https://iam.nvpworld.com';
// export const ADMIN_IMG_URL = 'https://iam.nvpworld.com/';
