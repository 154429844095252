import React, { useState } from 'react'
import LeftNavBar from '../../../common/part/LeftNavBar'
import styled from 'styled-components'
import { AdminWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminProductAdd1Part from '../part/AdminProductAdd1Part';
import AdminProductAdd2Part from '../part/AdminProductAdd2Part';
import { apiAdmSetProductAdd, apiAmdSEtProductReservation } from '../../../../api/adm/adm_api_product';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { useNavigate } from 'react-router';
import AdminProductReservationItem from '../item/AdminProductReservationItem';

const DATA1_INIT = {
    phone_model: '',
    phone_name: '',
    phone_type: '1',
    phone_price: [],
    phone_description: '',
    discount_price: [ 
        { "telco_id": 1, "discount_price_1": '0', "discount_price_2": '0'}, 
        { "telco_id": 2, "discount_price_1": '0', "discount_price_2": '0'}, 
        { "telco_id": 3, "discount_price_1": '0', "discount_price_2": '0'} 
    ],
    phone_im_discount: '0',
    phone_color:[],
    phone_thumbnail: '',
    phone_desc_image: '',
}


export default function AdminProductAddPage() {
    const adminInfo = useAdminInfo();
    const [part,setPart] = useState(1);
    const [data,setData] = useState(DATA1_INIT);
    const [reservation,setReservation] = useState(0);
    const navigate = useNavigate();

    const nextBtnClick = (num:number) =>{
        setPart(num);   
    }

    const productAdd = async() =>{
        if(data.phone_price.length === 0){
            alert('용량을 선택해주세요.');
            return;
        }
        if(data.phone_thumbnail === ''){
            alert('썸네일을 선택해주세요.');
            return;
        }
        if(data.phone_desc_image === ''){
            alert('상품정보를 선택해주세요.');
            return;
        }
        if(data.phone_color.length === 0){
            alert('색상을 입력해주세요.');
            return;
        }
        const res = await apiAdmSetProductAdd(adminInfo.admin_jwt_token,adminInfo.admin_user_id,data);
        if(res.result){
            const reservationRes = await apiAmdSEtProductReservation(adminInfo.admin_jwt_token,adminInfo.admin_user_id,res.data.phone_id,reservation);
            alert('상품이 등록되었습니다.');
            navigate(-1);
        }else{
            alert(res.error);
        }
    }
    
    return (
        <LeftNavBar idKey={'6'}>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>상품 등록</BasicText>
                    <InnerWrap>
                        {part === 1 && 
                            <>
                                <AdminProductReservationItem value={reservation} setValue={setReservation} />
                                <AdminProductAdd1Part data={data} setData={setData} next={()=>nextBtnClick(2)}/>
                            </>
                        }
                        {part === 2 && <AdminProductAdd2Part data={data} setData={setData} prev={()=>nextBtnClick(1)} next={()=>productAdd()}/>}
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;
