import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../../basic/BasicText';
import { BtnItem, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { phoneNumberFormat, valueChange } from '../../../../utils/format/string';
import OpenPlacePart from './OpenPlacePart';
import { apiAdmOrderUserUpdate } from '../../../../api/adm/adm_api_order';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

export default function AdminOrderDetailUserPart({info}:any) {
    const adminInfo = useAdminInfo();
    const [userType,setUserType] = useState('1');
    const [phNumber,setPhNumber] = useState('');
    const [userName,setUserName] = useState('');
    const [userBirth,setUserBirth] = useState('');
    const [userContact,setUserContact] = useState('');
    const [userEmail,setUserEmail] = useState('');
    
    const [placeOpen,setPlaceOpen] = useState(false);
    const [place,setPlace] = useState<any>();
    const [placeAdd,setPlaceAdd] = useState('');
    const [userPlz,setUserPlz] = useState('');

    const [userParentName,setUserParentName] = useState('');
    const [userParentBirth,setUserParentBirth] = useState('');
    const [userParentType,setUserParentType] = useState('');
    const [userParentContact,setUserParentContact] = useState('');
    
    useEffect(()=>{
        if(info === undefined) return;
        setUserType(info?.order_user_type ?? '1');
        setPhNumber(info?.order_opening_phone_number ?? '');
        setUserName(info?.order_user_name ?? '');
        setUserBirth(info?.order_user_birth ?? '');
        setUserContact(info?.order_user_phone_number ?? '');
        setUserEmail(info?.order_user_email ?? '');

        setPlace({zonecode:info?.order_user_post_code ?? '',roadAddress:info?.order_user_address ?? ''});
        setPlaceAdd(info?.order_user_address_detail ?? '');

        setUserPlz(info?.order_request_comment ?? '');

        setUserParentName(info?.order_guard_name ?? '');
        setUserParentBirth(info?.order_guard_birth ?? '');
        setUserParentType(info?.order_guard_relation ?? '');
        setUserParentContact(info?.order_guard_phone_number ?? '');
    },[info]);

    const userUpdate = async() =>{
        if(info === undefined) return;
        const data = {
            order_opening_phone_number: phNumber,
            order_user_name: userName,
            order_user_birth: userBirth,
            order_user_type: userType,
            order_user_phone_number: userContact,
            order_user_email: userEmail,
            order_user_post_code: place.zonecode,
            order_user_address: place.roadAddress,
            order_user_address_detail: placeAdd,
            order_guard_name: userParentName,
            order_guard_birth: userParentBirth,
            order_guard_phone_number: userParentContact,
            order_guard_relation: userParentType,
        }
        const res = await apiAdmOrderUserUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.order_id,data);
        if(res.result){
            alert('고객정보를 수정했습니다.')
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <BasicText fontSize={18} mFontSize={16} fontWeight={700}>고객정보</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <BasicText>개통번호</BasicText>
                    <InputWrap>
                        <input value={phoneNumberFormat(phNumber)} onChange={(e:any)=>setPhNumber(valueChange(e))}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap gap={20}>
                    <ItemWrap>
                        <BasicText>고객명</BasicText>
                        <InputWrap>
                            <input value={userName} onChange={(e:any)=>setUserName(e.target.value)}></input>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap>
                        <BasicText>생년월일</BasicText>
                        <InputWrap>
                            <input value={userBirth} onChange={(e:any)=>setUserBirth(e.target.value)}></input>
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>고객유형</BasicText>
                    <Row style={{gap:'10px',flexWrap:'wrap'}}>
                        <label>
                            <input type='radio' name='user-type' value={'1'} onChange={(e:any)=>setUserType(e.target.value)} checked={userType === '1'}></input> 성인
                        </label>
                        <label>
                            <input type='radio' name='user-type' value={'2'} onChange={(e:any)=>setUserType(e.target.value)} checked={userType === '2'}></input> 미성년자
                        </label>
                    </Row>
                </ItemWrap>
                <ItemWrap gap={20}>
                    <ItemWrap>
                        <BasicText>연락처</BasicText>
                        <InputWrap>
                            <input value={phoneNumberFormat(userContact)} onChange={(e:any)=>setUserContact(valueChange(e))}></input>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap>
                        <BasicText>이메일</BasicText>
                        <InputWrap>
                            <input value={userEmail} onChange={(e:any)=>setUserEmail(e.target.value)}></input>
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>주소</BasicText>
                    <AddressWrap>
                        <AddressBtnWrap>
                            <input value={place?.zonecode ?? ''} readOnly onClick={()=>setPlaceOpen(true)}></input>
                            <BtnItem bg='#000' onClick={()=>setPlaceOpen(true)}>우편번호검색</BtnItem>
                        </AddressBtnWrap>
                        <InputWrap>
                            <input value={place?.roadAddress ?? '' } readOnly></input>
                        </InputWrap>
                        <InputWrap>
                            <input value={placeAdd} onChange={(e:any)=>setPlaceAdd(e.target.value)}></input>
                        </InputWrap>
                    </AddressWrap>
                </ItemWrap>
                <ItemWrap start={'start'}>
                    <BasicText>고객요청</BasicText>
                    <textarea rows={5} value={userPlz} onChange={(e:any)=>setUserPlz(e.target.value)}></textarea>
                </ItemWrap>
            </ContentWrap>
            {userType === '2' &&
                <ContentWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>법정대리인 정보</BasicText>
                    <ItemWrap gap={20}>
                        <ItemWrap>
                            <BasicText>이름</BasicText>
                            <InputWrap>
                                <input value={userParentName} onChange={(e:any)=>setUserParentName(e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>생년월일</BasicText>
                            <InputWrap>
                                <input value={userParentBirth} onChange={(e:any)=>setUserParentBirth(valueChange(e))}></input>
                            </InputWrap>
                        </ItemWrap>
                    </ItemWrap>
                    <ItemWrap gap={20}>
                        <ItemWrap>
                            <BasicText>관계</BasicText>
                            <InputWrap>
                                <input value={userParentType} onChange={(e:any)=>setUserParentType(e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>연락처</BasicText>
                            <InputWrap>
                                <input value={phoneNumberFormat(userParentContact)} onChange={(e:any)=>setUserParentContact(valueChange(e))}></input>
                            </InputWrap>
                        </ItemWrap>
                    </ItemWrap>
                </ContentWrap>
            }
            <Row style={{marginTop:'15px'}}>
                <BtnItem bg='#ff4b00' onClick={userUpdate}>고객정보변경</BtnItem>
            </Row>
            <OpenPlacePart open={placeOpen} setOpen={setPlaceOpen} setPlace={setPlace} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{start?:string,gap?:number}>`
    gap: ${({gap}) => gap ?? 10}px;
    align-items: ${({start}) => start ?? 'center'};
    &>p{
        width: 90px;
        flex-shrink: 0;
    }
    & textarea{
        width: 100%;
        padding: 8px;
        border: 1px solid #d4d4d4;
        border-radius: 8px;
    }
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        row-gap: 5px;
    }
`;

const InputWrap = styled.div`
    border: 1px solid #d4d4d4;
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
    & input{
        width: 100%;
    }
`;

const AddressWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

const AddressBtnWrap = styled(Row)`
    gap: 10px;
    flex-wrap: wrap;
    &>input{
        width: auto !important;
        border: 1px solid #d4d4d4;
        padding: 8px 12px;
        border-radius: 8px;
    }
`;