import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid, TableCell, TableRow } from '@mui/material';
import { addTheme } from '../../../../style/addTheme';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADMIN_ORDER_PAGE } from '../../../../data/tableTitle';
import { useNavigate, useParams } from 'react-router';
import { apiAdmGetOrder, apiAdmSetOrderDel } from '../../../../api/adm/adm_api_order';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { ORDER_STATUS, ORDER_TYPE, TEL_LIST } from '../../../../data/payment';
import { phoneNumberFormat } from '../../../../utils/format/string';
import { momentFormat } from '../../../../utils/format/time';

const Search = ({searchAction}:any) =>{
    const [sc,setSc] = useState('');
    const search = (e:any) =>{
        e.preventDefault();
        searchAction(sc);
    }
    return(
        <SearchWrap onSubmit={search}>
            <input value={sc} onChange={(e:any)=>setSc(e.target.value)} placeholder='고객명, 전화번호'></input>
            <SearchBtnItem type='submit'>검색</SearchBtnItem>
        </SearchWrap>
    )
}

const ListItem = ({data,goDetail,delClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[0].align}>{data.order_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[1].align}>{TEL_LIST[data.telco_id].name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[2].align}>{ORDER_TYPE[data.order_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[3].align}>{data.phone_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[4].align}>{data.order_user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[5].align}>{phoneNumberFormat(data.order_user_phone_number)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[6].align}>{ORDER_STATUS[data.order_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[7].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[8].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_process_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_ORDER_PAGE[9].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={goDetail}>상세</BtnItem>
                    <BtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={delClick}>삭제</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdminOrderPage() {
    const {page} = useParams();
    const adminInfo = useAdminInfo();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const navigate = useNavigate();
    const [search,setSearch] = useState('');

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getList();
    },[page,search,adminInfo.admin_user_id])

    const setPage = (p:any) =>{
        navigate(`/adm/order/${p}`);
    }

    const goDetail = (id:any) =>{
        navigate(`/adm/orderDetail/${id}`);
    }

    const getList = async() =>{
        const res = await apiAdmGetOrder(adminInfo.admin_jwt_token,adminInfo.admin_user_id, page ?? 1, 15, search);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const searchAction = (sc:string) =>{
        setSearch(sc);
        setPage(1);
    }

    const delClick = async(id:any) =>{
        if(id === undefined) return;
        if(window.confirm('삭제 하시겠습니까?')){
            const res = await apiAdmSetOrderDel(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('삭제 했습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <LeftNavBar idKey='2'>
            <Wrap>
                <AdminPageTotalPart title='전체주문' part='order' />
                <AdminWrap>
                    <TitleWrap>
                        <BasicText fontSize={18} mFontSize={16} fontWeight={700}>주문관리</BasicText>
                        <Search searchAction={searchAction}/>
                    </TitleWrap>
                    <TableWrap>
                        <AdminTablePart bg={addTheme.main} color='#fff' title={ADMIN_ORDER_PAGE} minWidth={1250} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <ListItem key={`order-${index}`} data={data} goDetail={()=>goDetail(data.order_id)} delClick={()=>delClick(data.order_id)} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    </TableWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;
const SearchWrap = styled.form`
    width: 65%;
    justify-content: end;
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    & input{
        width: 100%;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        max-width: 280px;
    }
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;
const SearchBtnItem = styled.button`
    background-color: #ff4b00;
    color: #fff;
    height: 42px;
    width: 60px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;