import React from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem';
import { Center, Row } from '../../../style/basic/commonStyle';

export default function SelectSizeItem({list,size}:any) {
    const sizeClick = (data:any,index:any) =>{
        size.setSize({...data,idx:index});
    }
    
    return (
        <SelectItem title='용량'>
            <Wrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ItemWrap key={`size-${index}`} onClick={()=>sizeClick(data,index)} state={data.volume_id === size?.size?.volume_id}>{data.volume_spec} {String(data.volume_spec).length < 3 ? 'TB' : 'GB'}</ItemWrap>
                        )
                    })
                }
            </Wrap>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 10px;
    flex-wrap: wrap;
`;

const ItemWrap = styled(Center)<{state:boolean}>`
    color: ${({state}) => state ? '#000' : '#777'};
    background-color: ${({state}) => state ? '#fff' : '#f4f4f4'};
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    height: 46px;
    border-radius: 4px;
    width: 90px;
    cursor: pointer;
`;
