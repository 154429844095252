import React from 'react'
import styled from 'styled-components';
import { Center, RowBetween } from '../../../../style/basic/commonStyle';
import RectItem from '../../../common/item/RectItem';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { addTheme } from '../../../../style/addTheme';

export default function AdminHomeVisitorsPart({data}:any) {
    return (
        <Wrap>
            <ContentItem>
                <RectItem>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(data?.yesterday_visit_count ?? 0)}</BasicText>
                        <BasicText>어제</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(data?.today_visit_count ?? 0)}</BasicText>
                        <BasicText>오늘</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(data?.total_visit_count ?? 0)}</BasicText>
                        <BasicText>전체</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    gap: 20px;
    margin-top: 20px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 20px 0;
    }
`;

const ContentItem = styled(Center)`
    border: 1px solid #e0e7ff;
    border-radius: 15px;
    width: 33.33%;
    max-width: 230px;
    margin: 0 auto;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const ContentInnerWrap = styled(Center)`
    width: 100%;
    height: 100%;
    flex-direction: column;
`;
