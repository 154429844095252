import React from 'react'
import styled from 'styled-components'
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADMIN_HOME_ORDER } from '../../../../data/tableTitle';
import { TableCell, TableRow } from '@mui/material';
import { ORDER_STATUS, ORDER_TYPE, TEL_LIST } from '../../../../data/payment';
import { momentFormat } from '../../../../utils/format/time';
import { useNavigate } from 'react-router';

const ListItem = ({data,itemClick}:any) =>{
    const tel = TEL_LIST[data.telco_id];
    return (
        <TableRow>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={ADMIN_HOME_ORDER[0].align} onClick={itemClick}>{data.order_code}</TableCell>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={ADMIN_HOME_ORDER[1].align} onClick={itemClick}>{data.phone_model}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_HOME_ORDER[2].align}>{tel?.name ?? ''}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_HOME_ORDER[3].align}>{ORDER_TYPE[data.order_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_HOME_ORDER[4].align}>{ORDER_STATUS[data.order_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADMIN_HOME_ORDER[5].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function AdminHomeOrderPart({list}:any) {
    const navigate = useNavigate();

    const itemClick = (id:any) =>{
        navigate(`/adm/orderDetail/${id}`);
    }
    return (
        <Wrap>
            <AdminTablePart title={ADMIN_HOME_ORDER}>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`order-${index}`} data={data} itemClick={()=>itemClick(data.order_id)} />
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div`
`;
