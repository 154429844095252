import React from 'react'
import styled from 'styled-components';

export default function AdminHomePart({title,children}:any) {
    return (
        <Wrap>
            <TitleWrap>
                {title}
            </TitleWrap>
            <ContentWrap>
                {children}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    min-height: 317px;
`;
const TitleWrap = styled.div``;
const ContentWrap = styled.div``;