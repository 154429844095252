export const TEL_LIST:any = {
    "1":{"name":"SKT","img":"/img/logo/skt.png","value":"skt"},
    "2":{"name":"KT","img":"/img/logo/kt.png","value":"kt"},
    "3":{"name":"LGU+","img":"/img/logo/lg.png","value":"lg"}
}

export const TEL_LIST_CATE:any = [
    {"id":"1",'telco_id':'1',"name":"SKT","img":"/img/logo/skt.png","value":"skt"},
    {"id":"2",'telco_id':'2',"name":"KT","img":"/img/logo/kt.png","value":"kt"},
    {"id":"3",'telco_id':'3',"name":"LGU+","img":"/img/logo/lg.png","value":"lg"}
]

export const ORDER_TYPE:any = {
    "1":"기기변경",
    "2":"통신사이동",
}

export const ORDER_TYPE_CATE:any = [
    {id:"1",name:"기기변경"},
    {id:"2",name:"통신사이동"},
]

export const ORDER_STATUS_CATE:any = [
    {id:0,name:'주문접수'},
    {id:1,name:'상담완료'},
    {id:2,name:'공식신청서작성완료'},
    {id:3,name:'배송중'},
    {id:4,name:'배송완료'},
    {id:10,name:'주문취소'},
]

export const ORDER_STATUS:any = {
    "0":"주문접수",
    "1":"상담완료",
    "2":"공식신청서작성완료",
    "3":"배송중",
    "4":"배송완료",
    "10":"주문취소",
}


export const PAYMENT_5G_SKT = [
    {
        "id": 1,
        "name": "5G ZEM 플랜 베스트 (만 12세이하)",
        "payment": "26,000",
        "dataType": "2G+무제한",
        "dataTypeAdd": "(소진후400kbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성100분)",
        "textType": "문자 무제한",
        "payment_num": 26000
    },
    {
        "id": 2,
        "name": "5G 0 청년 43",
        "payment": "43,000",
        "dataType": "6GB+무제한",
        "dataTypeAdd": "(소진후 400Kbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 43000
    },
    {
        "id": 3,
        "name": "0틴 5G(19세이하)",
        "payment": "45,000",
        "dataType": "9GB+무제한",
        "dataTypeAdd": "(소진후1mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성300분)",
        "textType": "문자 무제한",
        "payment_num": 45000
    },
    {
        "id": 4,
        "name": "5G베이직",
        "payment": "49,000",
        "dataType": "8GB+무제한",
        "dataTypeAdd": "(소진후 400Kbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 49000
    },
    {
        "id": 5,
        "name": "5G슬림",
        "payment": "55,000",
        "dataType": "9GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 55000
    },
    {
        "id": 6,
        "name": "5G베이직플러스",
        "payment": "59,000",
        "dataType": "24GB+무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 59000
    },
    {
        "id": 7,
        "name": "5G레귤러",
        "payment": "69,000",
        "dataType": "110GB + 무제한",
        "dataTypeAdd": "(소진후 5Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 69000
    },
    {
        "id": 8,
        "name": "5G레귤러플러스",
        "payment": "79,000",
        "dataType": "250GB + 무제한",
        "dataTypeAdd": "(소진후 5Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 79000
    },
    {
        "id": 9,
        "name": "5G프라임",
        "payment": "89,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 89000
    },
    {
        "id": 10,
        "name": "5G프라임플러스",
        "payment": "99,000",
        "dataType": "완전무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 99000
    },
    {
        "id": 11,
        "name": "5GX 프리미엄",
        "payment": "109,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 109000
    }
]

export const PAYMENT_5G_KT = [
    {
        "id": 1,
        "name": "시니어 C형(만 80세이상)",
        "payment": "41,000",
        "dataType": "8GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 400분)",
        "textType": "문자 무제한",
        "payment_num": 41000
    },
    {
        "id": 2,
        "name": "시니어 B형 (만 75세이상)",
        "payment": "42,000",
        "dataType": "9GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성400분)",
        "textType": "문자 무제한",
        "payment_num": 42000
    },
    {
        "id": 3,
        "name": "시니어 A형(만 65세이상)",
        "payment": "44,000",
        "dataType": "110GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 400분)",
        "textType": "문자 무제한",
        "payment_num": 44000
    },
    {
        "id": 4,
        "name": "시니어 베이직(만 65세이상)",
        "payment": "49,000",
        "dataType": "15GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 400분)",
        "textType": "문자 무제한",
        "payment_num": 49000
    },
    {
        "id": 5,
        "name": "5G 주니어 (만 12세이하)",
        "payment": "38,000",
        "dataType": "5GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "",
        "textType": "문자 무제한",
        "payment_num": 38000
    },
    {
        "id": 6,
        "name": "Y틴",
        "payment": "47,000",
        "dataType": "10G+무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 47000
    },
    {
        "id": 7,
        "name": "슬림",
        "payment": "55,000",
        "dataType": "10GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 55000
    },
    {
        "id": 8,
        "name": "슬림플러스",
        "payment": "61,000",
        "dataType": "30GB+무제한",
        "dataTypeAdd": "(소진후1Mbps)",
        "voiceType": "유무선무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 61000
    },
    {
        "id": 9,
        "name": "심플 110GB",
        "payment": "69,000",
        "dataType": "110GB + 무제한",
        "dataTypeAdd": "(소진후 5Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 69000
    },
    {
        "id": 10,
        "name": "베이직",
        "payment": "80,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 80000
    },
    {
        "id": 11,
        "name": "베이직 초이스",
        "payment": "90,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 90000
    },
    {
        "id": 12,
        "name": "스페셜",
        "payment": "100,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 100000
    }
]
export const PAYMENT_5G_LG = [
    {
        "id": 1,
        "name": "키즈29 (만 12세이하)",
        "payment": "29,000",
        "dataType": "3.3GB + 무제한",
        "dataTypeAdd": "(소진후 400Kbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 100분)",
        "textType": "문자 무제한",
        "payment_num": 29000
    },
    {
        "id": 2,
        "name": "라이트 청소년",
        "payment": "45,000",
        "dataType": "8G+무제한 ",
        "dataTypeAdd": "(소진후 1Mpbs)",
        "voiceType": "유무선무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 45000
    },
    {
        "id": 3,
        "name": "슬림플러스",
        "payment": "47,000",
        "dataType": "6GB + 무제한",
        "dataTypeAdd": "(소진후 400Kbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 47000
    },
    {
        "id": 4,
        "name": "라이트",
        "payment": "55,000",
        "dataType": "12GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 55000
    },
    {
        "id": 5,
        "name": "심플플러스",
        "payment": "61,000",
        "dataType": "31GB + 무제한",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 61000
    },
    {
        "id": 6,
        "name": "데이터레귤러",
        "payment": "63,000",
        "dataType": "50G+무제한 ",
        "dataTypeAdd": "(소진후 1Mbps)",
        "voiceType": "유무선무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 63000
    },
    {
        "id": 7,
        "name": "데이터 플러스",
        "payment": "66,000",
        "dataType": "80G+무제한 ",
        "dataTypeAdd": "(소진후 1Mpbs)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 66000
    },
    {
        "id": 8,
        "name": "데이터 슈퍼",
        "payment": "68,000",
        "dataType": "95G+무제한 ",
        "dataTypeAdd": "(소진후 3Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 68000
    },
    {
        "id": 9,
        "name": "스탠다드 에센셜",
        "payment": "70,000",
        "dataType": "125G+무제한 ",
        "dataTypeAdd": "(소진후 5Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 70000
    },
    {
        "id": 10,
        "name": "스탠다드",
        "payment": "75,000",
        "dataType": "150GB + 무제한",
        "dataTypeAdd": "(소진후 5Mbps)",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 75000
    },
    {
        "id": 11,
        "name": "프리미어 에센셜",
        "payment": "85,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 85000
    },
    {
        "id": 12,
        "name": "프리미어 레귤러",
        "payment": "95,000",
        "dataType": "완전무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 95000
    },
    {
        "id": 13,
        "name": "프리미어 플러스",
        "payment": "105,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 105000
    },
    {
        "id": 14,
        "name": "프리미어 슈퍼",
        "payment": "115,000",
        "dataType": "완전 무제한",
        "dataTypeAdd": "",
        "voiceType": "유무선 무제한",
        "voiceTypeAdd": "(부가 음성 300분)",
        "textType": "문자 무제한",
        "payment_num": 115000
    }
]

export const PAYMENT_5G: {[key:string]:any} = {
    "skt": PAYMENT_5G_SKT,
    "kt": PAYMENT_5G_KT,
    "lg": PAYMENT_5G_LG,
}

export const SELL_LIST = [
    {
        "id": 1,
        "name": "아이폰14 pro max",
        "price_str": "매입가 900,000원 ~",
        "price_num": 900000
    },
    {
        "id": 2,
        "name": "아이폰14 pro",
        "price_str": "매입가 760,000원 ~",
        "price_num": 760000
    },
    {
        "id": 3,
        "name": "아이폰14 +",
        "price_str": "매입가 530,000원 ~",
        "price_num": 530000
    },
    {
        "id": 4,
        "name": "아이폰14",
        "price_str": "매입가 500,000원 ~",
        "price_num": 500000
    },
    {
        "id": 5,
        "name": "아이폰13 pro max",
        "price_str": "매입가 620,000원 ~",
        "price_num": 620000
    },
    {
        "id": 6,
        "name": "아이폰13 pro",
        "price_str": "매입가 520,000원 ~",
        "price_num": 520000
    },
    {
        "id": 7,
        "name": "아이폰13",
        "price_str": "매입가 400,000원 ~",
        "price_num": 400000
    },
    {
        "id": 8,
        "name": "아이폰13미니",
        "price_str": "매입가 330,000원 ~",
        "price_num": 330000
    },
    {
        "id": 9,
        "name": "아이폰12 pro max",
        "price_str": "매입가 400,000원 ~",
        "price_num": 400000
    },
    {
        "id": 10,
        "name": "아이폰12 pro",
        "price_str": "매입가 320,000원 ~",
        "price_num": 320000
    },
    {
        "id": 11,
        "name": "아이폰12",
        "price_str": "매입가 220,000원 ~",
        "price_num": 220000
    },
    {
        "id": 12,
        "name": "아이폰12미니",
        "price_str": "매입가 150,000원 ~",
        "price_num": 150000
    },
    {
        "id": 13,
        "name": "아이폰 11 pro max",
        "price_str": "매입가 330,000원 ~",
        "price_num": 330000
    },
    {
        "id": 14,
        "name": "아이폰11 pro",
        "price_str": "매입가 240,000원 ~",
        "price_num": 240000
    },
    {
        "id": 15,
        "name": "아이폰11",
        "price_str": "매입가 200,000원 ~",
        "price_num": 200000
    },
    {
        "id": 16,
        "name": "아이폰xs max",
        "price_str": "매입가 200,000원 ~",
        "price_num": 200000
    },
    {
        "id": 17,
        "name": "아이폰xs",
        "price_str": "매입가 140,000원 ~",
        "price_num": 140000
    },
    {
        "id": 18,
        "name": "아이폰xr",
        "price_str": "매입가 150,000원 ~",
        "price_num": 150000
    },
    {
        "id": 19,
        "name": "갤럭시 노트9",
        "price_str": "매입가 80,000원 ~",
        "price_num": 80000
    },
    {
        "id": 20,
        "name": "갤럭시 노트10",
        "price_str": "매입가 120,000원 ~",
        "price_num": 120000
    },
    {
        "id": 21,
        "name": "갤럭시 노트20",
        "price_str": "매입가 150,000원 ~",
        "price_num": 150000
    },
    {
        "id": 22,
        "name": "갤럭시 노트20 울트라",
        "price_str": "매입가 300,000원 ~",
        "price_num": 300000
    },
    {
        "id": 23,
        "name": "갤럭시 s20",
        "price_str": "매입가 130,000원 ~",
        "price_num": 130000
    },
    {
        "id": 24,
        "name": "갤럭시 s20+",
        "price_str": "매입가 150,000원 ~",
        "price_num": 150000
    },
    {
        "id": 25,
        "name": "갤럭시 s20울트라",
        "price_str": "매입가 210,000원 ~",
        "price_num": 210000
    },
    {
        "id": 26,
        "name": "갤럭시 s21",
        "price_str": "매입가 180,000원 ~",
        "price_num": 180000
    },
    {
        "id": 27,
        "name": "갤럭시 s21+",
        "price_str": "매입가 200,000원 ~",
        "price_num": 200000
    },
    {
        "id": 28,
        "name": "갤럭시 s21울트라",
        "price_str": "매입가 300,000원 ~",
        "price_num": 300000
    },
    {
        "id": 29,
        "name": "갤럭시 s22",
        "price_str": "매입가 250,000원 ~",
        "price_num": 250000
    },
    {
        "id": 30,
        "name": "갤럭시 s22+",
        "price_str": "매입가 300,000원 ~",
        "price_num": 300000
    },
    {
        "id": 31,
        "name": "갤럭시 s22울트라",
        "price_str": "매입가 450,000원 ~",
        "price_num": 450000
    },
    {
        "id": 32,
        "name": "갤럭시 Z플립5G",
        "price_str": "매입가 40,000원 ~",
        "price_num": 40000
    },
    {
        "id": 33,
        "name": "갤럭시 Z플립3",
        "price_str": "매입가 130,000원 ~",
        "price_num": 130000
    },
    {
        "id": 34,
        "name": "갤럭시 Z플립4",
        "price_str": "매입가 170,000원 ~",
        "price_num": 170000
    },
    {
        "id": 35,
        "name": "갤럭시 Z폴드2",
        "price_str": "매입가 230,000원 ~",
        "price_num": 230000
    },
    {
        "id": 36,
        "name": "갤럭시 Z폴드3",
        "price_str": "매입가 320,000원 ~",
        "price_num": 320000
    },
    {
        "id": 37,
        "name": "갤럭시 Z폴드4",
        "price_str": "매입가 470,000원 ~",
        "price_num": 470000
    },
    {
        "id": 38,
        "name": "갤럭시노트10+",
        "price_str": "매입가 190,000원 ~",
        "price_num": 190000
    },
    {
        "id": 39,
        "name": "갤럭시Z플립2",
        "price_str": "매입가 50,000원 ~",
        "price_num": 50000
    },
    {
        "id": 40,
        "name": "갤럭시Z폴드",
        "price_str": "매입가 150,000원 ~",
        "price_num": 150000
    },
    {
        "id": 41,
        "name": "아이폰se2",
        "price_str": "매입가 80,000원 ~",
        "price_num": 80000
    },
    {
        "id": 42,
        "name": "아이폰X",
        "price_str": "매입가 120,000원 ~",
        "price_num": 120000
    },
    {
        "id": 43,
        "name": "아이폰8",
        "price_str": "매입가 60,000원 ~",
        "price_num": 60000
    },
    {
        "id": 44,
        "name": "아이폰8+",
        "price_str": "매입가 80,000원 ~",
        "price_num": 80000
    },
    {
        "id": 45,
        "name": "아이폰7",
        "price_str": "매입가 20,000원 ~",
        "price_num": 20000
    },
    {
        "id": 46,
        "name": "아이폰7+",
        "price_str": "매입가 40,000원 ~",
        "price_num": 40000
    },
    {
        "id": 47,
        "name": "아이폰6S",
        "price_str": "매입가 40,000원 ~",
        "price_num": 40000
    },
    {
        "id": 48,
        "name": "아이폰6S+",
        "price_str": "매입가 30,000원 ~",
        "price_num": 30000
    },
    {
        "id": 49,
        "name": "아이폰6",
        "price_str": "매입가 20,000원 ~",
        "price_num": 20000
    },
    {
        "id": 50,
        "name": "갤럭시S10 5G",
        "price_str": "매입가 140,000원 ~",
        "price_num": 140000
    },
    {
        "id": 51,
        "name": "갤럭시S10+",
        "price_str": "매입가 140,000원 ~",
        "price_num": 140000
    },
    {
        "id": 52,
        "name": "갤럭시 S10",
        "price_str": "매입가 110,000원 ~",
        "price_num": 110000
    },
    {
        "id": 53,
        "name": "갤럭시 S10E",
        "price_str": "매입가 60,000원 ~",
        "price_num": 60000
    },
    {
        "id": 54,
        "name": "갤럭시S9+",
        "price_str": "매입가 80,000원 ~",
        "price_num": 80000
    },
    {
        "id": 55,
        "name": "갤럭시 S9",
        "price_str": "매입가 60,000원 ~",
        "price_num": 60000
    },
    {
        "id": 56,
        "name": "갤럭시S8+",
        "price_str": "매입가 60,000원 ~",
        "price_num": 60000
    },
    {
        "id": 57,
        "name": "갤럭시 S8",
        "price_str": "매입가 50,000원 ~",
        "price_num": 50000
    },
    {
        "id": 58,
        "name": "갤럭시S7",
        "price_str": "매입가 10,000원 ~",
        "price_num": 10000
    },
    {
        "id": 59,
        "name": "갤럭시 S7엣지",
        "price_str": "매입가 10,000원 ~",
        "price_num": 10000
    },
    {
        "id": 60,
        "name": "아이폰se3",
        "price_str": "매입가 120,000원 ~",
        "price_num": 120000
    },
    {
        "id": 61,
        "name": "아이폰se2",
        "price_str": "매입가 110,000원 ~",
        "price_num": 110000
    },
    {
        "id": 62,
        "name": "갤럭시 s23",
        "price_str": "매입가 400,000원 ~",
        "price_num": 400000
    },
    {
        "id": 63,
        "name": "갤럭시 s23울트라",
        "price_str": "매입가 690,000원 ~",
        "price_num": 690000
    },
    {
        "id": 64,
        "name": "갤럭시 s23+",
        "price_str": "매입가 500,000원 ~",
        "price_num": 500000
    },
    {
        "id": 65,
        "name": "갤럭시 s20fe",
        "price_str": "매입가 80,000원 ~",
        "price_num": 80000
    },
    {
        "id": 66,
        "name": "갤럭시 노트8",
        "price_str": "매입가 60,000원 ~",
        "price_num": 60000
    },
    {
        "id": 67,
        "name": "갤럭시 노트7",
        "price_str": "매입가 10,000원 ~",
        "price_num": 10000
    },
    {
        "id": 68,
        "name": "갤럭시 노트5",
        "price_str": "매입가 10,000원 ~",
        "price_num": 10000
    }
]