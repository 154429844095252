import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { IoSearchSharp } from "react-icons/io5";
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { IMG_URL } from '../../../api/config';
import SearchPart from './SearchPart';

export default function HeadPart({gClick,iClick}:any) {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);

    const phoneMenuClick = (type:string) =>{
        if(type === 'galaxy'){
            gClick === undefined ?  navigate('/#galaxy') : gClick();
            return;
        }
        if(type === 'iphone'){
            iClick === undefined ? navigate('/#iphone') : iClick();
            return
        }
    }

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <LeftWrap>
                        <LogoWrap onClick={()=>navigate('/')}>
                            <img alt='img' src={IMG_URL+'/img/asset/logo.png'} />
                        </LogoWrap>
                        <MenuWrap>
                            <MenuItem onClick={()=>phoneMenuClick('galaxy')}>갤럭시</MenuItem>
                            <MenuItem onClick={()=>phoneMenuClick('iphone')}>아이폰</MenuItem>
                            <MenuItem>|</MenuItem>
                            <MenuItem onClick={()=>navigate('/review/1')}>구매후기</MenuItem>
                            <MenuItem onClick={()=>navigate('/qna/1')}>1:1 문의</MenuItem>
                        </MenuWrap>
                    </LeftWrap>
                    <RightWrap>
                        <InquiryItem onClick={()=>navigate('/myPage')}>마이페이지</InquiryItem>
                        <SearchItem onClick={()=>setOpen(true)}>
                            <IoSearchSharp size={28}/>
                        </SearchItem>
                    </RightWrap>
                </InnerWrap>
                <MoMenuWrap>
                    <MenuItem onClick={()=>phoneMenuClick('galaxy')}>갤럭시</MenuItem>
                    <MenuItem onClick={()=>phoneMenuClick('iphone')}>아이폰</MenuItem>
                    <MenuItem>|</MenuItem>
                    <MenuItem onClick={()=>navigate('/review/1')}>구매후기</MenuItem>
                    <MenuItem onClick={()=>navigate('/qna/1')}>1:1 문의</MenuItem>
                </MoMenuWrap>
            </MaxWidthWrap>
            <SearchPart open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(RowBetween)`
    min-height: 80px;
    gap: 5px;
    flex-wrap: wrap;
`;
const LeftWrap = styled(Row)`
    width: auto;
    height: 100%;
    gap: 20px;
    flex-wrap: wrap;
`;
const RightWrap = styled(Row)`
    height: 100%;
    width: auto;
    gap: 15px;
    flex-shrink: 0;
`;

const LogoWrap = styled(Center)`
    height: 100%;
    & img{
        height: 40px;
        cursor: pointer;
        @media ${addTheme.media.m}{
            height: 40px;
        }
        @media ${addTheme.media.s}{
            height: 35px;
        }
    }
`;

const MenuWrap = styled(Row)`
    gap: 15px;
    width: auto;
    @media ${addTheme.media.m}{
        display: none;
    }
`;
const MenuItem = styled(Center)`
    font-size: 18px;
    cursor: pointer;
`;

const InquiryItem = styled(Center)`
    border-radius: 30px;
    background-color: #0063ff;
    color: #fff;
    width: 115px;
    height: 42px;
    cursor: pointer;
    @media ${addTheme.media.m}{
        width: 80px;
        height: 36px;
        font-size: 15px;
    }
`;
const SearchItem = styled.div`
    cursor: pointer;
`;

const MoMenuWrap = styled(Row)`
    display: none;
    @media ${addTheme.media.m}{
        display: flex;
        gap: 20px;
        padding: 10px 0;
        &>div{
            font-size: 17px;
        }
    }
`;