import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import UserItem from '../item/UserItem';
import { IMG_URL } from '../../../api/config';

export default function SubBannerPart() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <TextWrap>
                    <BasicText color='#fff' align='center' fontSize={26} mFontSize={20} fontWeight={700}>최저가! 초대박 헤택!</BasicText>
                    <BasicText color='#fff' align='center' fontSize={26} mFontSize={20} fontWeight={700}>실시간 문의 폭주</BasicText>
                </TextWrap>
                <UserWrap>
                    <UserItem type='sub'/>
                </UserWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background: ${`url(${IMG_URL}/img/asset/banner-02.png)`};
    padding: 50px 0;
`;

const TextWrap = styled.div``;
const UserWrap = styled(Center)`
    margin-top: 25px;
`;