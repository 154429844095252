import { axiosConfigInstance } from "../config";

// 주문 리스트
export const apiAdmGetOrder = async(token:string,admin_id:any,page:any,count:any,search_query?:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'getAdminOrderList',
            params:{
                admin_id,page,count,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 삭제
export const apiAdmSetOrderDel = async(token:string,admin_id:any,order_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'adminOrderDelete',
            params:{
                admin_id,order_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 디테일
export const apiAdmGetOrderDetail = async(token:string,admin_id:any,order_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'getAdminOrderDetailInfo',
            params:{
                admin_id,order_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 상태 변경
export const apiAdmSetOrderStateUpdate = async(token:string,admin_id:any,order_id:any,order_status:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'adminOrderChangeStatus',
            params:{
                admin_id,order_id,order_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
// 주문 상태 메모 변경
export const apiAdmSetOrderMemoUpdate = async(token:string,admin_id:any,order_id:any,order_process_comment:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'adminOrderCommentEdit',
            params:{
                admin_id,order_id,order_process_comment
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 유저 정보 변경
export const apiAdmOrderUserUpdate = async(token:string,admin_id:any,order_id:any,data:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'adminOrderUserInfoEdit',
            params:{
                admin_id,order_id,...data,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 요금제
export const apiAdmGetPlanList = async(token:string,admin_id:any,telco_id?:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/config',token).post('',{
            method: 'getAdminPlanList',
            params:{
                admin_id,telco_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 카드 종류
export const apiAdmGetCardList = async(token:string,admin_id:any,telco_id?:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/config',token).post('',{
            method: 'getAdminDiscountCardList',
            params:{
                admin_id,telco_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 구매정보 변경
export const apiAdmSetOrderBuyInfo = async(token:string,admin_id:any,data:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/order',token).post('',{
            method: 'adminOrderBasicInfoEdit',
            params:{
                admin_id,...data,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}