import { ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';

export default function MenuPart({data,idKey,GoHistory}:any) {
    const [openDrop,setOpenDrop] = useState(false);
    const idKeyArr = idKey.split('-');
    
    const handleClick = (data:any) => {
        GoHistory(data.router);
        setOpenDrop(!openDrop);
    };
    return (
        <div>
            <ListItemButton onClick={()=>handleClick(data)}>
                <MenuWrap fontSize={18}>
                    <ListItemText primary={data.name} sx={{color:data.id === idKeyArr[0] ? '#fff':'#ffff'}} />
                </MenuWrap>
                <BgWrap bgShow={data.id === idKeyArr[0]}></BgWrap>
            </ListItemButton>
            {/* <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data?.sub?.map((subData:any)=>{
                        let select = `${data.id}-${subData.id}`;
                        let chk = idKey===select;
                        return(
                            <ListItemButton key={`sub${select}`} sx={{color:chk? '#0085ff' : '#111', bgcolor:'#fff',}} onClick={()=>GoHistory(subData.router,subData.type)}>
                                <MenuWrap fontSize={14}>
                                    <MenuIcon></MenuIcon>
                                    <ListItemText primary={subData.name} />
                                </MenuWrap>
                                <BgWrap bgShow={chk}></BgWrap>
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse> */}
        </div>
    )
}

const MenuWrap = styled(Row)<{fontSize?:number}>`
    position: relative;
    z-index: 2;
    padding: 6px 0 6px 15px;
    & .MuiTypography-root{
        font-size: ${props=>props.fontSize === undefined ? 15 : props.fontSize}px;
    }
`;

const MenuIcon = styled(Center)`
    width: 40px;
`;

const BgWrap = styled.div<{bgShow:boolean}>`
    position: absolute;
    top:0;
    left:0;
    width: ${props => props.bgShow ? '5px' : '0'};
    height: 100%;
    background-color: #fff;
    transition: 0.2s ease-in-out;
    z-index: 1;
`;