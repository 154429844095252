import React, { SetStateAction, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, LineChart, Line } from 'recharts';
import styled from 'styled-components';
import { Row } from '../../../../style/basic/commonStyle';
import { numberWithComma } from '../../../../utils/format/number';

export default function LineChartPart({list}:any) {
    const [data,setData] = useState<any>([]);

    useEffect(()=>{
        setData(list);
    },[list]);

    const CustomAxis = (props:any) =>{
        const { x, y, payload } = props;
        return (
            <text className='x-text' x={x} y={y} dy={16} textAnchor="middle" fill="#666">
                { payload.value}
            </text>
        );
    }
    const LABEL:any = {
        "visitor": '방문자수',
        "order": '상품주문수',
        "inquiry": '문의수',
        "review": '후기수',
    } 
    const CustomTooltip = (data:any) => {
        if(data.active){
            return(
                <TooltipWrap>
                    <p>{data.label}</p>
                    <TooltipItemWrap>
                        {data.payload.map((item:any,index:any)=>{
                            return(
                                <p key={`labe-${index}`}><span style={{color:item.stroke}}>{LABEL[item.dataKey]}:</span> {numberWithComma(item.value)}</p>
                            )
                        })}
                    </TooltipItemWrap>
                </TooltipWrap>
            )
        }
        return null;
    }
    
    return (
        <Wrap>
            <ChartWrap>
                <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart
                        data={data}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={CustomAxis} />
                        <YAxis />
                        <Tooltip 
                            labelStyle={{ color: '#5b5b5b', marginBottom:'6px' }}
                            content={<CustomTooltip></CustomTooltip>}
                        />
                        {/* <Legend /> */}
                        <Line type="monotone" dataKey="visitor" stroke="#ff6b00" dot={{r:4}} activeDot={{ r: 6 }} strokeWidth={2}/>
                        <Line type="monotone" dataKey="order" stroke="#0063ff" dot={{r:4}} activeDot={{ r: 6 }} strokeWidth={2}/>
                        <Line type="monotone" dataKey="inquiry" stroke="#00bb31" dot={{r:4}} activeDot={{ r: 6 }} strokeWidth={2}/>
                        <Line type="monotone" dataKey="review" stroke="#c900ff" dot={{r:4}} activeDot={{ r: 6 }} strokeWidth={2}/>
                    </LineChart>
                </ResponsiveContainer>
            </ChartWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    border-radius: 5px;
    padding: 10px;
`;

const ChartWrap = styled.div`
    & .recharts-default-tooltip{
        background-color: #28364d;
    }
    & .x-text{
        font-size: 12px;
    }
`;

const TooltipWrap = styled.div`
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ddd;
`;

const TooltipItemWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;