import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import {USER,PHONE,PHONE_SIZE} from '../../../api/dummyUser';

export default function UserItem({type}:any) {
    const [listOrder,setListOrder] = useState<any>([]);
    const [orderCount,setOrderCount] = useState(0);
    const [listAnswer,setListAnswer] = useState<any>([]);
    const [answerCount,setAnswerCount] = useState(0);
    const [visibleState,setVisibleState] = useState('visible');
    // 현재 화면을 보고 있는지
    const handleVisibilityChange = useCallback(() => {
        setVisibleState(document.visibilityState);
    }, []);

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        }
    }, []);
    
    useEffect(()=>{
        if(visibleState === 'hidden') return;
        if(listOrder === 0) return;
        const timer = setInterval(()=>{
            if(orderCount === listOrder.length-1){
                setOrderCount(0);
            }else{
                setOrderCount((num:number)=>num+1);
            }
        },3400);
        return () => clearInterval(timer);
    },[listOrder,orderCount,visibleState]);

    useEffect(()=>{
        if(visibleState === 'hidden') return;
        if(listAnswer === 0) return;
        const timer = setInterval(()=>{
            if(answerCount === listAnswer.length-1){
                setAnswerCount(0);
            }else{
                setAnswerCount((num:number)=>num+1);
            }
        },3400);
        return () => clearInterval(timer);
    },[listAnswer,answerCount,visibleState])

    const userRandom = () => {
        const num = Math.floor(Math.random() * USER.length);
        return USER[num];
    };
    const phoneRandom = () =>{
        const num = Math.floor(Math.random() * PHONE.length);
        return PHONE[num];
    }

    const phoneSize = () =>{
        const num = Math.floor(Math.random() * PHONE_SIZE.length);
        return PHONE_SIZE[num];
    }
    useEffect(()=>{
        if(answerCount === 0){
            const newOrder = USER.map((data)=>{
                return {title: phoneRandom(), name: userRandom(), size: phoneSize()}
            })
            setListOrder(newOrder);
        }
    },[answerCount]);

    useEffect(()=>{
        if(answerCount === 0){
            const newAnswer = USER.map((data)=>{
                return {title: phoneRandom(), name: userRandom(),}
            })
            setListAnswer(newAnswer);
        }
    },[answerCount]);
    
    return (
        <ViewWrap>
            {type === 'main' &&
                <BigWrap count={orderCount}>
                { listOrder.length === 0 ?
                        <></> :
                        listOrder.map((data:any,index:any)=>{
                            return(
                                <Wrap key={`banner-order-${index}`}>
                                    <StateItem>주문완료</StateItem>
                                    <BasicText fontSize={20} mFontSize={16}>{data.title} {data.size} | {data.name}</BasicText>
                                </Wrap>
                            )
                        })}
                </BigWrap>
            }
            {type !== 'main' &&
                <BigWrap count={answerCount}>
                    { listAnswer.length === 0 ?
                        <></> :
                        listAnswer.map((data:any,index:any)=>{
                            return(
                                <Wrap key={`banner-order-${index}`}>
                                    <BasicText fontSize={20} mFontSize={16}>{data.title} | {data.name}</BasicText>
                                    <StateItem>상담진행중</StateItem>
                                </Wrap>
                            )
                        })}
                </BigWrap>
            }
                {/* <Wrap>
                    {type === 'main' ? 
                        <>
                            <StateItem>주문완료</StateItem>
                            <BasicText fontSize={20} mFontSize={16}>갤럭시 S24 울트라 바이올렛 256G | 김O정</BasicText>
                        </> :
                        <>
                            <BasicText fontSize={20} mFontSize={16}>갤럭시 S24 울트라 | 김O정</BasicText>
                            <StateItem>상담진행중</StateItem>
                        </>
                    }
                </Wrap> */}
            
        </ViewWrap>
    )
}

const ViewWrap = styled.div`
    max-width: 480px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 60px;
`;

const BigWrap = styled(Row)<{count:number}>`
    width: auto;
    transform: translateX(${({count})=>count*-100}%);
    transition: ${({count}) => count === 0 ? 0 : 0.3}s;
`;

const Wrap = styled(RowBetween)`
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    flex-shrink: 0;
    padding: 14px 20px;
    @media ${addTheme.media.sx}{
        justify-content: center;
        /* border-radius: 15px; */
    }
`;

const StateItem = styled(Center)`
    background-color: #0063ff;
    color: #fff;
    padding: 6px 10px;
    border-radius: 30px;
    font-size: 15px;
`;