import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../../basic/BasicText';
import { BtnItem, Center, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { MenuItem, Select } from '@mui/material';
import { ORDER_STATUS_CATE } from '../../../../data/payment';
import { apiAdmSetOrderMemoUpdate, apiAdmSetOrderStateUpdate } from '../../../../api/adm/adm_api_order';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

export default function AdminOrderDetailProcessPart({info}:any) {
    const adminInfo = useAdminInfo();
    const [value,setValue] = useState('0');
    const [content,setContent] = useState('');

    useEffect(()=>{
        if(info === undefined) return;
        setValue(info?.order_status ?? '0');
        setContent(info?.order_process_comment ?? '');
    },[info])
    
    const handleChange = (e:any) =>{
        setValue(e.target.value);
    }

    const stateUpdate = async() =>{
        if(info === undefined) return;
        const res = await apiAdmSetOrderStateUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.order_id,value);
        if(res.result){
            alert('상태를 변경했습니다.');
        }else{
            alert(res.error);
        }
    }

    const contentUpdate = async() =>{
        if(info === undefined) return;
        const res = await apiAdmSetOrderMemoUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.order_id,content);
        if(res.result){
            alert('메모를 변경했습니다.');
        }else{
            alert(res.error);
        }
    }


    return (
        <Wrap>
            <BasicText fontSize={18} mFontSize={16} fontWeight={700}>처리정보</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <BasicText>처리상태</BasicText>
                    <Select
                        value={value}
                        onChange={handleChange}
                        displayEmpty
                        size='small'
                        sx={{maxWidth:'100%'}}
                    >
                        {ORDER_STATUS_CATE.map((item:any,index:any)=>{
                            return(
                                <MenuItem value={item.id} key={`state-${index}`}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <BtnItem bg='#00ac31' onClick={stateUpdate}>처리상태저장</BtnItem>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>처리메모</BasicText>
                    <Row style={{flexWrap:'wrap',gap:'8px'}}>
                        <textarea value={content} onChange={(e:any)=>setContent(e.target.value)} rows={5} maxLength={2000}></textarea>
                        <BtnItem bg='#00ac31' onClick={contentUpdate}>처리메모저장</BtnItem>
                    </Row>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    &>p{
        width: 90px;
        flex-shrink: 0;
    }
    & textarea{
        width: 100%;
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        padding: 10px;
    }
    &:nth-last-of-type(1){
        align-items: start;
    }
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        row-gap: 5px;
    }
`;
