import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface userInfo {
  "jwt_token": string;
  "user_address":string;
  "user_code":string;
  "user_id":number;
  "user_membership":string;
  "user_nickname":string;
  "user_profile":string;
  "user_token":string;
}

export const userState: userInfo = {
  "jwt_token":'',
  "user_address":"",
  "user_code":"",
  "user_id":0,
  "user_membership":'0',
  "user_nickname":"",
  "user_profile":"",
  "user_token":"",
}

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
    jwt_token: action.payload.data.jwt_token ?? state.jwt_token,
    user_address: action.payload.data.user_address ?? state.user_address,
    user_code: action.payload.data.user_code ?? state.user_code,
    user_id: action.payload.data.user_id ?? state.user_id,
    user_membership: action.payload.data.user_membership ?? state.user_membership,
    user_nickname: action.payload.data.user_nickname ?? state.user_nickname,
    user_profile: action.payload.data.user_profile ?? state.user_profile,
    user_token: action.payload.data.user_token ?? state.user_token,
  })
,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (userState),
}, userState);
