import { axiosConfigInstance } from "./config"

// 유저 방문
export const apiUser = async() =>{
    try {
        const res = await await axiosConfigInstance('/user/log').post('',{
            method: 'userVisitLog',
            params: {}
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 마이페이지 로그인
export const apiMyPageLogin = async(user_name:any,user_phone_number:any,user_password:any) =>{
    try {
        const res = await axiosConfigInstance('/account').post('',{
            method: 'getAccountOrderToken',
            params:{
                user_name,user_phone_number,user_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 마이페이지 리스트
export const apiGetMyList = async(order_token:string) =>{
    try {
        const res = await axiosConfigInstance('/order/info').post('',{
            method: 'getOrderInfoListFromOrderToken',
            params:{
                order_token
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}