import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { FaLock } from 'react-icons/fa';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import ContentPageItem from '../../detail/item/ContentPageItem';
import { useNavigate, useParams } from 'react-router';
import { apiGetInquiry } from '../../../api/api_faq';
import { momentFormat } from '../../../utils/format/time';
import { INQUIRY_STATE } from '../../../data/state';

const ListItem = ({itemClick,data}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px 12px'}} align='center'>{data.inquiry_id}</TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='center'>
                <CategoryItem state={data.inquiry_status === '1'}>{INQUIRY_STATE[data.inquiry_status]}</CategoryItem>
            </TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='left'>
                <ContentItem style={{cursor:'pointer'}} onClick={itemClick}>
                    <BasicText line={1} fontSize={12} color='#555'>{data.i_category_title}</BasicText>
                    <FaLock color='#c8c8c8' />
                </ContentItem>
            </TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='center'>
                <BasicText fontSize={13} align='center'>{data.inquiry_user_name}</BasicText>
            </TableCell>
            <TableCell sx={{padding:'10px 12px',color:'#555'}} align='center'>{momentFormat('YYYY/MM/DD',data.inquiry_question_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function QnaInquiryPart() {
    const navigate = useNavigate();
    const {page} = useParams();
    const [total,setTotal] = useState(10);
    const [list,setList] = useState([]);

    useEffect(()=>{
        getList()
    },[page])

    const getList = async() =>{
        const res = await apiGetInquiry(page??1,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 페이지변경
    const setPage = (p:any) =>{
        navigate(`/qna/1/${p}`);
    }

    // 작성버튼
    const writeBtnClick = () =>{
        navigate('/inquiryCreate');
    }

    // 디테일페이지로 이동
    const itemClick = (id:any) =>{
        if(id === undefined) return;
        navigate(`/inquiryDetail/${id}`);
    }

    return (
        <Wrap>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:'8%',padding:'10px 12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">번호</TableCell>
                            <TableCell sx={{width:'20%',padding:'10px 12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">답변상태</TableCell>
                            <TableCell sx={{width:'43%',padding:'10px 12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">제목</TableCell>
                            <TableCell sx={{width:'12%',padding:'10px 12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">작성자</TableCell>
                            <TableCell sx={{width:'17%',padding:'10px 12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">작성일</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`inquiry-${index}`} itemClick={()=>itemClick(data.inquiry_id)} data={data} />
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <PageWrap>
                <Btn></Btn>
                <ContentPageItem page={page ?? 1} setPage={setPage} total={total} btnState='false' />
                <BtnItem onClick={writeBtnClick}>문의하기</BtnItem>
            </PageWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const CategoryItem = styled(Center)<{state:boolean}>`
    width: 75%;
    background-color: ${({state}) => state ? addTheme.main : '#f4f4f4'};
    color: ${({state}) => state ? '#fff' : '#232323'};
    height: 38px;
    font-size: 13px;
    margin: 0 auto;
    border-radius: 6px;
`;

const ContentItem = styled(Row)`
    gap: 6px;
`;

const PageWrap = styled(RowBetween)`
    flex-wrap: wrap;
    margin-top: 15px;
    width: 100%;
    @media ${addTheme.media.s}{
        flex-direction: column;
        row-gap: 15px;
    }
`;

const Btn = styled(Center)`
    width: 145px;
    height: 44px;
    @media ${addTheme.media.s}{
        display: none;
    }
`;
const BtnItem = styled(Btn)`
    background-color: ${addTheme.main};
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    @media ${addTheme.media.s}{
        display: flex;
        order: -1;
        align-self: end;
    }
`;