export const USER = [
    "김O연", "이O윤", "박O민", "최O유", "정O원", "강O윤", "조O아", "윤O은", "장O은", "임O아",
    "한O은", "오O서", "유O은", "나O린", "고O서", "문O아", "송O원", "배O원", "류O윤", "황O빈",
    "안O윤", "손O아", "정O빈", "권O린", "홍O온", "유O우", "나O인", "신O윤", "유O안", "유O호",
    "황O연", "나O하", "백O율", "진O은", "권O현", "황O지", "박O은", "임O영", "한O은", "박O민",
    "나O영", "고O연", "문O윤", "송O민", "윤O유", "장O원", "신O윤", "황O아", "조O은", "이O은",
    "이O수", "손O서", "김O혁", "김O수", "이O현", "장O영", "박O원", "박O현", "오O수", "한O영"
];

export const PHONE = [
    "갤럭시 S24 울트라",
    "갤럭시 S24 플러스",
    "갤럭시 S24",
    "갤럭시 Z 플립5",
    "갤럭시 Z 폴드5",
    "아이폰 15",
    "아이폰 15 Plus",
    "아이폰 15 프로",
    "아이폰 15 프로맥스",
    "갤럭시 Z 플립6",
    "갤럭시 Z 폴드6",
    "갤럭시 Z 플립6",
    "갤럭시 Z 폴드6",
];

export const PHONE_SIZE = [
    "256G", "512G"
]