import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import BasicText from '../../../basic/BasicText';
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { useParams } from 'react-router';
import { apiAdmGetFaqCategory, apiAdmGetFaqDetail, apiAdmSetFaq, apiAdmSetFaqUpdate } from '../../../../api/adm/adm_api_faq';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

export default function AdminFaqUpdatePage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const [cateList,setCateList] = useState<any>([]);
    const [cate,setCate] = useState('');
    const [question,setQuestion] = useState('');
    const [answer,setAnswer] = useState('');
    const [type,setType] = useState('등록');

    useEffect(()=>{
        getCate();
    },[])

    useEffect(()=>{
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[id]);

    const getCate = async() =>{
        const res = await apiAdmGetFaqCategory(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,100);
        if(res.result){
            setCateList(res.data);
            setCate(res.data[0].faq_category_id);
        }
    }

    const getInfo = async() =>{
        const res = await apiAdmGetFaqDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setCate(res.data[0].faq_category_id);
            setQuestion(res.data[0].faq_question);
            setAnswer(res.data[0].faq_answer);
        }
    }

    const btnAction = async() =>{
        if(type === '등록'){
            const res = await apiAdmSetFaq(adminInfo.admin_jwt_token,adminInfo.admin_user_id,cate,question,answer);
            if(res.result){
                alert('faq가 등록되었습니다.');
                reset();
            }else{
                alert(res.error);
            }
        }
        if(type === '수정'){
            const res = await apiAdmSetFaqUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,cate,question,answer);
            if(res.result){
                alert('faq가 수정되었습니다.');
            }else{
                alert(res.error);
            }
        }
    }

    const reset = () =>{
        setCate('');
        setQuestion('');
        setAnswer('');
    }

    return (
        <LeftNavBar idKey='5'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ {type}</BasicText>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>카테고리</BasicText>
                                <InputWrap style={{padding:0}}>
                                    <SelectItem value={cate} setValue={setCate} none='none'>
                                        {cateList === undefined || cateList.length === 0 ? 
                                            <MenuItem value={''}>{''}</MenuItem> :
                                            cateList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`faq-cate-${index}`} value={data.faq_category_id}>{data.faq_category_title}</MenuItem>
                                                )
                                            })
                                        }
                                    </SelectItem>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120} align='start'>
                                <BasicText>질문내용</BasicText>
                                <InputWrap>
                                    <textarea value={question} onChange={(e:any)=>setQuestion(e.target.value)} rows={6}></textarea>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120} align='start'>
                                <BasicText>답변내용</BasicText>
                                <InputWrap>
                                    <textarea value={answer} onChange={(e:any)=>setAnswer(e.target.value)} rows={9}></textarea>
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                        <Row style={{marginTop:'20px'}}>
                            <BtnItem bg={addTheme.main} padding='14px 25px' onClick={btnAction}>FAQ {type}</BtnItem>
                        </Row>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;