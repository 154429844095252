import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import LeftNavBar from '../../../common/part/LeftNavBar';
import styled from 'styled-components';
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { valueChange } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetFaqCateDetail, apiAdmSetFaqCategory, apiAdmSetFaqCategoryUpdate } from '../../../../api/adm/adm_api_faq';

export default function AdminFaqCateUpdatePage() {
    const adminInfo = useAdminInfo();
    const {id} = useParams();
    const [type,setType] = useState('등록');
    const [title,setTitle] = useState('');
    const [count,setCount] = useState('');

    useEffect(()=>{
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[id]);

    const getInfo = async() =>{
        const res = await apiAdmGetFaqCateDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setTitle(res.data.faq_category_title);
            setCount(res.data.faq_category_order);
        }
    }

    const btnAction = async() =>{
        if(adminInfo.admin_user_id === 0) return;
        if(type === '등록'){
            const res = await apiAdmSetFaqCategory(adminInfo.admin_jwt_token,adminInfo.admin_user_id,title,count === '' ? 100 : count);
            if(res.result){
                alert('카테고리가 등록되었습니다.');
                reset();
            }else{
                alert(res.error);
            }
            return;
        }
        if(type === '수정'){
            const res = await apiAdmSetFaqCategoryUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,title,count === '' ? 100 : count);
            if(res.result){
                alert('카테고리가 수정되었습니다.');
            }else{
                alert(res.error);
            }
            return;
        }
    }

    const reset = () =>{
        setTitle('');
        setCount('');
    }

    return (
        <LeftNavBar idKey='5'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 카테고리 {type}</BasicText>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>카테고리명</BasicText>
                                <InputWrap>
                                    <input value={title} onChange={(e:any)=>setTitle(e.target.value)} maxLength={150} />
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>순서</BasicText>
                                <InputWrap>
                                    <input value={count} onChange={(e:any)=>setCount(valueChange(e))} maxLength={1000} />
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                        <Row style={{marginTop:'20px'}}>
                            <BtnItem onClick={btnAction} bg={addTheme.main} padding='14px 25px'>카테고리 {type}</BtnItem>
                        </Row>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;