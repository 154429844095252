import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface RectItemType {
    paddingBottom?: number;
    children?: any;
    imgUrl?: any;
    bgSizeType?: 'contain';
    type?:'video';
}

export default function RectItem({paddingBottom,children,imgUrl,bgSizeType,type}:RectItemType) {
    const [img,setImg] = useState('');
    useEffect(()=>{
        checkImageExists(imgUrl);
    },[type,imgUrl])

    const checkImageExists = (imageUrl?:string) => {
        if(type === 'video'){
            if(imageUrl === undefined || imageUrl === '') return;
            let src = `https://img.youtube.com/vi/${imageUrl}/maxresdefault.jpg`
            const img = new Image();
            img.src = src;
            if(img.width > 1000){
                setImg(src);
            }else{
                src = `https://img.youtube.com/vi/${imageUrl}/0.jpg`;
                setImg(`https://img.youtube.com/vi/${imageUrl}/0.jpg`)
            }
            return src;
        }else{
            setImg(imageUrl ?? '');
            return imageUrl;
        }
    };
    
    return (
        <Wrap paddingBottom={paddingBottom}>
            <InnerWrap>
                {imgUrl ?
                    img !== '' &&
                        <Img url={img} bgSizeType={bgSizeType} />
                    : children
                }
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{paddingBottom?:number}>`
    width: 100%;
    position: relative;
    ::after{
        content: '';
        padding-bottom: ${({paddingBottom}) => paddingBottom ?? 100}%;
        display: block;
    }
`;

const InnerWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`;

const Img = styled.div.attrs(({url,bgSizeType}:{url?:string,bgSizeType?:string}) =>({
    style:{
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: bgSizeType ?? 'cover',
    }
}))<{url?:string,bgSizeType?:string}>`
    width: 100%;
    height: 100%;
`;

// const Img = styled.div<{url?:string,bgSizeType?:string}>`
//     width: 100%;
//     height: 100%;
//     background-image: url(${({url}) => url});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: ${({bgSizeType}) => bgSizeType ?? 'cover'};
// `;