import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle'
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';

export default function BtnPart() {
    const navigate = useNavigate();
    const company = useGlobalCompany();

    const itemClick = (url:string) =>{
        navigate(url);
    }
    const kakaoClick = () =>{
        if(company.link['config_social_kakao_link'] === undefined) return;
        window.open(company.link['config_social_kakao_link']);
    }

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BtnItem>
                        <BasicText fontWeight={700} fontSize={20} color='#fff' onClick={()=>itemClick('/review/1')}>실시간 후기 보기</BasicText>
                    </BtnItem>
                    <BtnItem>
                        <BasicText fontWeight={700} fontSize={20} color='#fff' onClick={()=>itemClick('/inquiryCreate')}>1:1 문의하기</BasicText>
                    </BtnItem>
                    <BtnItem>
                        <BasicText fontWeight={700} fontSize={20} color='#fff' onClick={kakaoClick}>카톡 문의하기</BasicText>
                    </BtnItem>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(Center)`
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 18px;
`;
const BtnItem = styled(Center)`
    background-color: #003fd8;
    width: 100%;
    max-width: 230px;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
`;