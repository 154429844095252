import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle'
import SelectItem from './SelectItem';
import { addTheme } from '../../../style/addTheme';

export default function SelectNowUseItem({list,action,use}:any) {
    const clickAction = (data:any) =>{
        action.set(data);        
    }
    
    return (
        <SelectItem title='사용하실 통신사'>
            <Wrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return (
                            <ItemWrap key={`nowUse-${index}`} onClick={()=>clickAction(data)} state={data.telco_id == action?.get?.telco_id}>
                                {data.img === '' ?
                                    <>{data.name}</> :
                                    <img src={data.img} alt={data.name} />
                                }
                            </ItemWrap>
                        )
                    })
                }
            </Wrap>
            <ResWrap>
                <ResTitle>{use.id == action?.get?.telco_id ? '기기변경' : '통신사이동'}</ResTitle>
                <ResContent>{use.id == action?.get?.telco_id ? '통신사는 그대로, 휴대폰만 바꾸고 싶어요.' : `쓰던번호 그대로 ${action?.get?.name}로 통신사만 바꿀래요.`}</ResContent>
            </ResWrap>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 12px;
    max-width: 400px;
`;
const ItemWrap = styled(Center)<{state:boolean}>`
    width: 33.33%;
    height: 54px;
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    background-color: ${({state}) => state ? '#fff' : '#f4f4f4'};
    border-radius: ${addTheme.round}px;
    color: ${({state}) => state ? '#000' : '#777'};
    cursor: pointer;
`;

const ResWrap = styled(Row)`
    gap: 6px;
    font-size: 13px;
    margin-top: 8px;
`;

const ResTitle = styled.div`
    color: #fff;
    background-color: #323232;
    padding: 4px 6px;
    border-radius: ${addTheme.round}px;
`;

const ResContent = styled.div`
    color: #333;
`;