import React from 'react'
import styled from 'styled-components'
import RectItem from '../../common/item/RectItem';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import StarPart from '../../common/part/StarPart';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { momentFormat } from '../../../utils/format/time';

export default function ReviewItem({data,itemClick,type}:any) {
    
    return (
        <Wrap onClick={itemClick} type={type}>
            <RectItem paddingBottom={110}>
                <InnerWrap>
                    <ImgWrap url={data?.review_image === undefined || data?.review_image === '' ? '/img/no.jpg' : data?.review_image}></ImgWrap>
                    <TextWrap>
                        <TextInfoWrap>
                            <BasicText fontSize={22} lh={1}>{data?.phone_model}</BasicText>
                            <StarWrap>
                                <StarPart colorStar='#fcd53f' size={18} count={data?.review_rate} colorText={addTheme.main}/>
                            </StarWrap>
                            <BasicText line={type === 'detail' ? undefined : 1} lh={1.2} wh={type === 'detail' ? 'pre-wrap' : 'normal'}>{data?.review_content}</BasicText>
                        </TextInfoWrap>
                        <BasicText color='#797979'>{data?.review_user_name} 고객님 | {momentFormat('YYYY.MM.DD',data?.review_timestamp)}</BasicText>
                    </TextWrap>
                </InnerWrap>
            </RectItem>
        </Wrap>
    )
}

const Wrap = styled.div<{type?:string}>`
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 8px rgba(0,0,0,0.15);
    cursor: ${({type}) => type === 'detail' ? 'default' : 'pointer'};
    background-color: #fff;
`;

const InnerWrap = styled.div`
    width: 100%;
    height: 100%;
`;

const ImgWrap = styled.div<{url:string}>`
    height: 58%;
    background: ${({url}) =>`url(${url})`};
    background-position: center;
    background-size: cover;
`;
const TextWrap = styled(RowBetween)`
    height: 42%;
    padding: 18px 15px;
    flex-direction: column;
    align-items: start;
`;

const TextInfoWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    align-items: start;
`;

const StarWrap = styled.div``;