import { axiosConfigInstance } from "./config"

// faq 리스트 가져오기
export const apiGetFaq = async(faq_category_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/faq/info').post('',{
            method: 'getFaqList',
            params:{
                faq_category_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


// faq 카테고리 리스트 가져오기
export const apiGetFaqCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/faq/info').post('',{
            method: 'getFaqCategoryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 카테고리 리스트 가져오기
export const apiGetInquiryCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/inquiry/info').post('',{
            method: 'getInquiryCategoryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 리스트 가져오기
export const apiGetInquiry = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/inquiry/info').post('',{
            method: 'getInquiryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 디테일 가져오기
export const apiGetInquiryDetail = async(inquiry_id:any,inquiry_password:any) =>{
    try {
        const res = await axiosConfigInstance('/inquiry/info').post('',{
            method: 'getInquiryDetailInfo',
            params:{
                inquiry_id,inquiry_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 작성
export const apiSetInquiry = async(user_name:any,user_phone_number:any,user_email:any,inquiry_category_id:any,inquiry_question:any,inquiry_password:any,inquiry_question_image:any) =>{
    try {
        const res = await axiosConfigInstance('/inquiry/info').post('',{
            method: 'submitInquiry',
            params:{
                user_name,user_phone_number,user_email,inquiry_category_id,inquiry_question,inquiry_password,inquiry_question_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


