import React, { useRef, useState } from 'react'
import { Center, InputWrap, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import styled from 'styled-components';
import BasicText from '../../../basic/BasicText';
import { fileTypeRes } from '../../../../utils/format/string';

const FILE_INIT = {file:null,preview:'',fileData:''};

export default function AdminProductAddColorItem({item,setColorList}:any) {
    const imgRef = useRef<any>(null);
    const [colorImg,setColorImg] = useState<any>(FILE_INIT);

    const colorChange = (e:any)=>{
        const val = e.target.value;
        const newItem = {...item, color_hex: val};
        setColorList((arr:any)=>arr.map((data:any)=>data.color_name === item.color_name ? newItem : data));
    }

    const imgInputClick = () =>{
        if(imgRef.current){
            imgRef.current.click();
        }
    }

    const imgChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();
                setColorImg((obj:any)=>({...obj,file:file,preview:preImg}))
                reader.onload = () => {
                    const result = reader.result;
                    setColorImg((obj:any)=>({...obj,fileData:result}))
                    setColorList((arr:any)=>arr.map((data:any)=>data.color_name === item.color_name ? {...item,color_image:result} : data));
                };
                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                imgRef.current.value = null;
            }
        }
    }

    return (
        <Wrap>
            <InnerWrap>
                <InputWrap>
                    <BasicText>{item.color_name}</BasicText>
                </InputWrap>
                <ColorItem>
                    <input type='color' value={item.color_hex} onChange={colorChange}></input>
                </ColorItem>
                <ImgShowBtn onClick={imgInputClick}>
                    이미지 등록하기
                    <input ref={imgRef} type='file' onChange={imgChange} accept='.png,.jpg,.jpeg'></input>
                </ImgShowBtn>
            </InnerWrap>
            {colorImg?.preview ?
                <PreImgWrap>
                    <img src={colorImg?.preview}></img>
                </PreImgWrap> :
                <></>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;

const InnerWrap = styled(Row)`
    align-items: stretch;
    gap: 10px;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
    & .file-input{
        display: none;
    }
`;

const ColorItem = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    & input{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 130%;
        height: 130%;
        border: none;
    }
`;

const ImgShowBtn = styled(Center)`
    flex-shrink: 0;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 18px;
    cursor: pointer;
    & input{
        display: none;
    }
`;

const PreImgWrap = styled.div`
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
    &>img{
        width: 100%;
    }
`;