import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { apiGetFaq } from '../../../api/api_faq';
import { addTheme } from '../../../style/addTheme';

const ListItem = ({data}:any) =>{
    const [state,setState] = useState(false);
    const clickAction = () =>{
        setState(!state);
    }
    return (
        <ItemWrap>
            <ItemTitleWrap onClick={clickAction}>
                <BasicText fontWeight={700} fontSize={24}>Q</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15}>{data.faq_question}</BasicText>
            </ItemTitleWrap>
            <ItemContentWrap state={state}>
                <BasicText fontWeight={700} fontSize={24}>A</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15} wh='pre-wrap'>{data.faq_answer}</BasicText>
            </ItemContentWrap>
        </ItemWrap>
    )
}

export default function QnaListPart({faqCateList}:any) {
    const [titleIdx,setTitleIdx] = useState(0);
    const [list,setList] = useState([]);

    useEffect(()=>{
        if(faqCateList === undefined || faqCateList.length === 0) return;
        setTitleIdx(faqCateList[0].faq_category_id);
    },[faqCateList])

    useEffect(()=>{
        getList();
    },[titleIdx])

    const titleClick = (idx:number) =>{
        if(titleIdx === idx) return;
        setTitleIdx(idx);
    }

    const getList = async() =>{
        const res = await apiGetFaq(titleIdx,1,50);
        if(res.result){
            setList(res.data);
        }
    }

    return (
        <Wrap>
            <TitleWrap>
                {faqCateList === undefined || faqCateList.length === 0 ?
                    <></> :
                    faqCateList.map((data:any,index:any)=>{
                        return(
                            <TitleItem key={`faq-cate-${index}`} onClick={()=>titleClick(data.faq_category_id)} state={data.faq_category_id === titleIdx}><BasicText line={1}>{data.faq_category_title}</BasicText></TitleItem>
                        )
                    })
                }
            </TitleWrap>
            <ContentWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`faq-list-${index}`} data={data}/>
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Row)``;
const TitleItem = styled(Center)<{state:boolean}>`
    padding: 12px 15px;
    border: 1px solid #dcdcdc;
    border-left: none;
    border-bottom: none;
    max-width: 160px;
    &:nth-of-type(1){
        border-left: 1px solid #dcdcdc;
    }
    font-size: 15px;
    color:  ${({state}) => state ? '#fff' : '#555'};
    cursor: pointer;
    &:hover{
        background-color: ${({state}) => state ? addTheme.main : '#f4f4f4'};
    }
    background-color: ${({state}) => state ? addTheme.main : 'transparent'};
`;
const ContentWrap = styled.div`
    border-top: 1px solid #000;
`;

const ItemWrap = styled.div``;
const ItemTitleWrap = styled(Row)`
    align-items: start;
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc;
    &>p:nth-of-type(1){
        min-width: 55px;
        flex-shrink: 0;
    }
    cursor: pointer;
`;
const ItemContentWrap = styled(ItemTitleWrap)<{state:boolean}>`
    cursor: default;
    background-color: #fafafa;
    display: ${({state}) => state ? 'flex':'none'};
`;
