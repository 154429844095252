import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ItemWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';

export default function AdminProductReservationItem({margin,value,setValue}:any) {
    const [chk,setChk] = useState(false);

    useEffect(()=>{
        if(value == 0){
            setChk(false);
        }else if(value == 1){
            setChk(true);
        }
    },[value]);

    const chkChange = (checked:boolean) =>{
        if(checked === true){
            setValue(1);
        }else{
            setValue(0);
        }
    }

    return (
        <Wrap margin={margin}>
            <ItemWrap pWidth={120}> 
                <BasicText>사전예약</BasicText>
                <input type='checkbox' checked={chk} onChange={(e:any)=>chkChange(e.target.checked)}></input>
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{margin?:any}>`
    margin: ${({margin}) => margin ?? '15px 0'}
`;