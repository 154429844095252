import { axiosConfigInstance } from "../config";

// 리뷰 리스트
export const apiAdmGetReview = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/review',token).post('',{
            method: 'getAdminReviewList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 삭제
export const apiAdmSetReviewDel = async(token:string,admin_id:any,review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/review',token).post('',{
            method: 'adminReviewDelete',
            params:{
                admin_id,review_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 수정
export const apiAdmSetReviewUpdate = async(token:string,admin_id:any,review_id:any,phone_id:any,user_name:any,review_rate:any,review_content:any,review_image:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/review',token).post('',{
            method: 'adminReviewEdit',
            params:{
                admin_id,review_id,phone_id,user_name,review_rate,review_content,review_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 디테일
export const apiAdmGetReviewDetail = async(token:string,admin_id:any,review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/review',token).post('',{
            method: 'getAdminReviewDetailInfo',
            params:{
                admin_id,review_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

