import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import MainBannerPart from '../part/MainBannerPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import SubBannerPart from '../part/SubBannerPart';
import ListPart from '../part/ListPart';
import { apiGetPhList } from '../../../api/api_phone';
import { useLocation } from 'react-router';
import SliderBannerPart from '../part/SliderBannerPart';
import QnaContactItem from '../../Qna/item/QnaContactItem';
import BtnPart from '../part/BtnPart';

export default function HomePage() {
    const location = useLocation();
    const gRef = useRef<any>(null);
    const iRef = useRef<any>(null);
    const [listG,setListG] = useState([]);
    const [listI,setListI] = useState([]);
    
    useEffect(()=>{
        if(location.hash === '') {
            const topTime = setTimeout(()=>{
                window.scrollTo(0,0);
            }, 180)
            return () => clearTimeout(topTime)
        };
        const timer = setTimeout(() => {
            if(location.hash.includes('galaxy')){
                gClick();
            }
            if(location.hash.includes('iphone')){
                iClick();
            }
        }, 180);
        return () => clearTimeout(timer);
    },[location.hash])

    useEffect(()=>{
        getList();
    },[])

    const gClick = () =>{
        if(gRef === null) return;
        gRef.current.scrollIntoView({ behavior: 'auto',block: 'start',inline: 'nearest' });
    }

    const iClick = () =>{
        if(iRef === null) return;
        iRef.current.scrollIntoView({ behavior: 'auto',block: 'start',inline: 'nearest' });
    }

    const getList = async() =>{
        const res = await apiGetPhList(1,150);
        if(res.result){
            const g = res.data.filter((data:any)=>data.phone_type === '1');
            const i = res.data.filter((data:any)=>data.phone_type === '2');
            setListG(g);
            setListI(i);
        }
    }

    return (
        <LayoutPage gClick={gClick} iClick={iClick}>
            <Wrap>
                <SliderBannerPart />
                <MaxWidthWrap>
                    <div ref={gRef}>
                        <ListPart title='갤럭시' list={listG}/>
                    </div>
                </MaxWidthWrap>
                <MainBannerPart />
                <MaxWidthWrap>
                    <div ref={iRef}>
                        <ListPart title='아이폰' list={listI}/>
                    </div>
                </MaxWidthWrap>
                <SubBannerPart />
                <BtnPart />
                <QnaContactItem />
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
