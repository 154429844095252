import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import RegisterPhoneInfoPart from '../part/RegisterPhoneInfoPart';
import { addTheme } from '../../../style/addTheme';
import RegisterInputPart from '../part/RegisterInputPart';
import { useNavigate, useParams } from 'react-router';
import { useGlobalRegister } from '../../../utils/custom-hooks/common/useGlobalRegister';
import { apiGetPhDetail } from '../../../api/api_phone';
import { calculateMonthlyPayment, discountPlanRes } from '../../../utils/format/number';

export default function RegisterPage() {
    const {id} = useParams();
    const register = useGlobalRegister();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [monthResult,setMonthResult] = useState(0);
    const [monthPrice,setMonthPrice] = useState<any>();

    useEffect(()=>{
        if(id === undefined || register.phone_id !== Number(id)){
            alert('만료된 페이지입니다.');
            navigate('/');
            return;
        }
        getDetail();
        const buyMonth = register?.buyType ?? 0
        const buyMonthRes = buyMonth === 0 ? 1 : buyMonth;
        const monthTotalPay = buyMonth === 0 ? 0 : calculateMonthlyPayment(register.result,buyMonthRes);
        const monthPay = Math.floor(register.result/buyMonthRes);
        const planPrice = register.discount_type === '1' ? (register.plan?.plan_price ?? 0) : discountPlanRes(register.plan?.plan_price ?? 0)
        setMonthPrice({monthPay,planPrice,monthTotalPay});
        setMonthResult(monthTotalPay + (planPrice));
    },[register.phone_id,id]);

    const getDetail = async() =>{
        const res = await apiGetPhDetail('1',id);
        if(res.result && (res.data.length !== 0)){
            setInfo(res.data[0]);
        }
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24}  fontWeight={700} >가입신청서</BasicText>
                </TitleWrap>
                <Wrap>
                    <LeftWrap>
                        <RegisterPhoneInfoPart info={info} data={register} monthResult={monthResult} />
                    </LeftWrap>
                    <RightWrap>
                        <RegisterInputPart data={register} monthResult={monthResult} monthPrice={monthPrice} />
                    </RightWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}


const TitleWrap = styled(Center)`
    margin-top: 100px;
`;
const Wrap = styled(Row)`
    align-items: start;
    margin-top: 35px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 40px 0 ;
    }
`;

const LeftWrap = styled.div`
    position: sticky;
    top: 0;
    width: 35%;
    padding: 10px 20px;
    @media ${addTheme.media.m}{
        width: 100%;
        position: static;
        padding: 0;
    }
`;
const RightWrap = styled.div`
    width: 65%;
    text-align: center;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

