import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { MenuItem, Select } from '@mui/material';
import SelectItem from '../../common/item/SelectItem';
import validator from 'validator';
import { fileTypeRes, phoneNumberFormat, valueChange } from '../../../utils/format/string';
import { apiGetInquiryCate, apiSetInquiry } from '../../../api/api_faq';

const CATE = [
    {id:1,name:'구매문의'},
    {id:2,name:'개통문의'},
    {id:3,name:'카드문의'},
    {id:4,name:'기타문의'},
]

export default function InquiryCreatePage() {
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [pw,setPw] = useState('');
    const [cate,setCate] = useState('');
    const [content,setContent] = useState('');
    const [chk,setChk] = useState(false);
    const chkRef = useRef<any>(null);
    const fileRef = useRef<any>(null);
    const [file,setFile] = useState<any>(null);
    const [filePreview,setFilePreview] = useState('');
    const [cateList,setCateList] = useState<any>([]);

    useEffect(()=>{
        getCate();
    },[]);

    const getCate = async() =>{
        const res = await apiGetInquiryCate(1,50);
        if(res.result){
            setCateList(res.data);
            setCate(res.data[0].inquiry_category_id);
        }
    }

    const reset = () =>{
        setName('');
        setPhone('');
        setEmail('');
        setPw('');
        setCate(cateList[0].inquiry_category_id);
        setContent('');
        setChk(false);
        chkRef.current.checked = false;
        fileRef.current.value = null;
        setFile(null);
        setFilePreview('');
    }

    const createAction = async(e:any) =>{
        e.preventDefault();
        if(name.includes('"') || name.includes("'") || email.includes('"') || email.includes("'") || pw.includes('"') || pw.includes("'") || content.includes('"') || content.includes("'")){
            alert(`' 와 "은 입력할 수 없습니다.`);
        }
        if(!chk){
            alert('개인정보 수집 및 이용을 동의해주세요.');
            return;
        }
        const emailChk = validator.isEmail(email);
        if(!emailChk){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiSetInquiry(name,phone,email,cate,content,pw,file);
        if(res.result){
            alert('문의가 작성되었습니다.');
            reset();
        }else{
            alert(res.error);
        }
    }

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>1:1 문의</BasicText>
                    <BasicText fontSize={20} mFontSize={15}  align='center' marginTop={1}>궁금하신것이 있나요? 언제든지 문의해주세요! (카톡으로 문의 주시면 보다 더 빠르게 답변 가능합니다.)</BasicText>
                </TitleWrap>
                <Wrap>
                    <form onSubmit={createAction}>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>작성자 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={name} onChange={(e:any) => setName(e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>연락처 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={phoneNumberFormat(phone)} onChange={(e:any) => setPhone(valueChange(e))} required placeholder='연락처를 입력해주세요'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>이메일 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={email} onChange={(e:any)=> setEmail(e.target.value)} required placeholder='이메일을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>비밀번호 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={pw} onChange={(e:any)=> setPw(e.target.value)} required placeholder='비밀번호를 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>카테고리 <span>*</span></BasicText>
                                <ItemInputWrap style={{padding:0}}>
                                    <SelectItem value={cate} setValue={setCate} none={'none'} width='100%'>
                                        {cateList === undefined || cateList.length === 0 ?
                                            <MenuItem value=''>none</MenuItem> :
                                            cateList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`cate-${index}`} value={data.inquiry_category_id}>{data.inquiry_title}</MenuItem>

                                                )
                                            })
                                        }
                                    </SelectItem>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap align='start'>
                                <BasicText spColor='red'>문의내용</BasicText>
                                <div style={{width:'100%'}}>
                                    <ItemInputWrap>
                                        <textarea value={content} onChange={(e:any) => setContent(e.target.value)} rows={4}></textarea>
                                    </ItemInputWrap>
                                    <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                    <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                    {filePreview === '' ?
                                        <></> :
                                        <FilePreviewWrap>
                                            <img src={filePreview} alt='file-img'></img>
                                        </FilePreviewWrap>
                                    }
                                </div>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>개인정보 수집 및 이용 <span>*</span></BasicText>
                                <label>
                                    <input ref={chkRef} type='checkbox' onChange={(e:any)=>setChk(e.target.checked)} required defaultChecked={chk}></input> 동의
                                </label>
                            </ItemWrap>
                        </ContentWrap>
                        <BtnWrap type='submit'>작성완료</BtnWrap>
                    </form>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 100px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;