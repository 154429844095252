import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import PhoneItem from '../item/PhoneItem';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function ListPart({title,list}:any) {
    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={26} mFontSize={20} fontWeight={700} color='#000' spColor='#0063ff'>{title} 시리즈 <span>할인특가</span></BasicText>
                <BasicText fontSize={17} mFontSize={15} marginTop={0.4} color='#333'>이 보다 더 쌀순없다 마이폰이 선정한 {title} 시리즈 할인 특가</BasicText>
            </TitleWrap>
            <ContentWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <PhoneItem key={`ph-${index}`} data={data}/>
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 60px 0;
`;
const TitleWrap = styled.div`
    border-left: 6px solid #0063ff;
    padding: 10px 20px;
`;
const ContentWrap = styled(Row)`
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
    &>div{
        width: calc(50% - 20px);
    }
    @media (max-width: 1318px){
        padding: 0 15px;
    }
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
    @media ${addTheme.media.s}{
        padding: 0 5px;
    }
`;