import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { useParams } from 'react-router';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { fileTypeRes } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import StarPart from '../../../common/part/StarPart';
import { apiAdmGetProduct } from '../../../../api/adm/adm_api_product';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetReviewDetail, apiAdmSetReviewUpdate } from '../../../../api/adm/adm_api_review';
import { apiSetReview } from '../../../../api/api_review';

const ITEM_LIST = [
    {id:1,name:'갤럭시 S25'},
    {id:2,name:'갤럭시 S26'},
]

export default function AdminReviewUpdatePage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const [type,setType] = useState('등록');
    const [name,setName] = useState('');
    const [itemList,setItemList] = useState<any>([]);
    const [product,setProduct] = useState('');
    const [starCount,setStarCount] = useState(0);
    const [content,setContent] = useState('');
    const fileRef = useRef<any>(null);
    const [file,setFile] = useState<any>(null);
    const [filePreview,setFilePreview] = useState('');

    useEffect(()=>{
        getItemList();
    },[])

    useEffect(()=>{
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[id]);

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const getInfo = async() =>{
        const res = await apiAdmGetReviewDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setName(res.data.review_user_name);
            setProduct(res.data.phone_id);
            setStarCount(res.data.review_rate);
            setContent(res.data.review_content);
            setFilePreview(res.data.review_image)
        }
    }

    const getItemList = async() =>{
        const res = await apiAdmGetProduct(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,50);
        if(res.result){
            setItemList(res.data);
            setProduct(res.data[0].phone_id);
        }
    }

    const btnAction = async() =>{
        if(product === '') return;
        if(type === '등록'){
            const res = await apiSetReview(product,name,starCount,content,file);
            if(res.result){
                alert('등록 했습니다.');
                reset();
            }else{
                alert(res.error);
            }
            return;
        }
        if(type === '수정'){
            const res = await apiAdmSetReviewUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,product,name,starCount,content,file);
            if(res.result){
                alert('수정 했습니다.');
            }else{
                alert(res.error);
            }
            return;
        }
    }

    const reset = () =>{
        setName('');
        setProduct(itemList[0].phone_id);
        setStarCount(0);
        setContent('');
        fileRef.current.value = null;
        setFile(null);
        setFilePreview('');
    }
    return (
        <LeftNavBar idKey='3'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>후기{type}</BasicText>
                    <InnerWrap>
                        <ContentWrap gap={'15px'}>
                            <ItemWrap pWidth={120}>
                                <BasicText>작성자</BasicText>
                                <InputWrap>
                                    <input value={name} onChange={(e:any)=>setName(e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>제품명</BasicText>
                                <InputWrap style={{padding:0}}>
                                    <SelectItem value={product} setValue={setProduct} none={'none'} width='100%'>
                                        {itemList === undefined || itemList.length === 0 ?
                                            <MenuItem value={''}></MenuItem> : 
                                            itemList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`cate-${index}`} value={data.phone_id}>{data.phone_model}</MenuItem>
                                                )
                                            })
                                        }
                                    </SelectItem>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>별점</BasicText>
                                <StarPart size={20} count={starCount} setCount={setStarCount}></StarPart>
                            </ItemWrap>
                            <ItemWrap pWidth={120} align='start'>
                                <BasicText>후기내용</BasicText>
                                <div style={{width:'100%'}}>
                                    <InputWrap>
                                        <textarea value={content} onChange={(e:any) => setContent(e.target.value)} rows={6} maxLength={1000}></textarea>
                                    </InputWrap>
                                    <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                    <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                    {filePreview === '' ?
                                        <></> :
                                        <FilePreviewWrap>
                                            <img src={filePreview} alt='file-img'></img>
                                        </FilePreviewWrap>
                                    }
                                </div>
                            </ItemWrap>
                        </ContentWrap>
                        <Row style={{marginTop:'20px'}}>
                            <BtnItem onClick={btnAction} bg={addTheme.main} padding='14px 25px'>후기 {type}</BtnItem>
                        </Row>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;
