import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid, MenuItem } from '@mui/material';
import { fileTypeRes, valueChange } from '../../../../utils/format/string';
import { numberWithComma } from '../../../../utils/format/number';
import { useNavigate, useParams } from 'react-router';
import { apiAdmGetProductDetail, apiAdmGetProductSize, apiAdmSetProductColorImgUpdate, apiAdmSetProductContentImgUpdate, apiAdmSetProductImgUpdate, apiAdmSetProductState, apiAdmSetProductUpdate, apiAmdSEtProductReservation } from '../../../../api/adm/adm_api_product';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { PRODUCT_STATE, PRODUCT_STATE_R } from '../../../../data/state';
import SelectItem from '../../../common/item/SelectItem';
import { TEL_LIST } from '../../../../data/payment';
import { ADMIN_IMG_URL } from '../../../../api/config';
import AdminProductReservationItem from '../item/AdminProductReservationItem';

export default function AdminProductDetailPage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [reservation,setReservation] = useState(0);
    const [colorImgList,setColorImgList] = useState<any>([]);
    const colorImgRef = useRef<any>(null);
    const [colorItem,setColorItem] = useState<any>();

    const [sizeList,setSizeList] = useState<any>([]);

    const thumbnailRef = useRef<any>(null);
    const [thumbnail,setThumbnail] = useState<any>(null);
    const [thumbnailName,setThumbnailName] = useState('');
    const [thumbnailPreview,setThumbnailPreview] = useState('');

    const contentRef = useRef<any>(null);
    const [content,setContent] = useState<any>(null);
    const [contentName,setContentName] = useState<any>('');
    const [contentPreview,setContentPreview] = useState('');
    
    useEffect(()=>{
        getSize();
    },[adminInfo.admin_jwt_token])

    useEffect(()=>{
        if(id === undefined) {
            navigate(-1);
            return;
        };
        getInfo();
    },[id,adminInfo.admin_jwt_token])

    const getInfo = async() =>{
        const res = await apiAdmGetProductDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setInfo(res.data[0]);
            setReservation(res.data[0].phone_is_revervation);
            setContentPreview(res.data[0].phone_desc_image);
            setThumbnailPreview(res.data[0].phone_thumbnail);
            setColorImgList(res.data[0].phone_colors.map((data:any)=>({color_id:data.color_id,file:'',filePreview:`${ADMIN_IMG_URL}product_image/product_phone_${id}_${data.color_id}.jpg`,})))
        }
    }

    // 활성 / 비활성
    const productStateClick = async(state:any) =>{
        const res = await apiAdmSetProductState(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,state);
        if(res.result){
            alert(`${PRODUCT_STATE[state]} 했습니다.`);
            getInfo();
        }else{
            alert(res.error);
        }
    }

    const itemChange = (name:string,val:any,idx?:any,idxName?:string) =>{
        if(info === undefined) return;
        if(idx === undefined || idxName === undefined){
            setInfo({
                ...info,
                [name]:val,
            });
        }else{
            const newList = info[name];
            newList[idx][idxName] = val;
            setInfo({
                ...info,
                [name]:newList,
            });
        }
    }

    const getSize = async() =>{
        const res = await apiAdmGetProductSize(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setSizeList(res.data);
        }
    }

    const sizeChange = (id:any,idx:any) =>{
        if(info === undefined) return;
        const filterItem = sizeList.filter((data:any) => data.volume_id === id);
        if(filterItem.length === 0) return;
        const newList = info?.phone_volume.map((data:any,index:any)=>(index === idx ? filterItem[0] : data))
        setInfo({
            ...info,
            ['phone_volume']: newList,
        });
    }
    // 썸네일, 상품정보 이미지 선택
    const fileChange = (e:any,ref:any,setFile:any,setFilePreview:any,setFileName:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'gif'){
                const preImg = URL.createObjectURL(file);
                setFileName(file.name);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg, gif인 이미지 파일만 가능합니다.');
                ref.current.value = null;
            }
        }
    }
    
    const handleImageError = (event:any) => {
        event.target.onerror = null; 
        event.target.src = '';
    };

    // 컬러 이미지 클릭
    const colorImgClick = (data:any) =>{
        colorImgRef?.current?.click();
        setColorItem(data);
    }

    // 컬러 이미지 변경
    const colorImgChange = (e:any) =>{
        const file = e.target.files[0];
        
        if(file){
            const fileFormat = fileTypeRes(file.name);
            
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setColorImgList((arr:any)=>arr.map((data:any)=>(data.color_id === colorItem.color_id ? {...data,file:result,filePreview:preImg} : data)))
                };

                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg 이미지 파일만 가능합니다.');
                colorImgRef.current.value = null;
            }
        }
    }

    // 수정
    const btnAction = async() =>{
        const newSizeInfo = info.phone_volume.map((data:any,index:any)=>({...data,price:info[`phone_upgrade_price_${index+1}`]}))
        const newDiscount = info.discount.map((data:any)=>({...data,discount_price_2:0}));
        const res = await apiAdmSetProductUpdate({token:adminInfo.admin_jwt_token,admin_id:adminInfo.admin_user_id,phone_price:newSizeInfo,discount_price:newDiscount,...info});
        
        if(res.result){
            alert('수정 했습니다.');
        }else{
            alert(res.error);
        }
    }

    // 색상 이미지 변경
    const colorImgUpdate = async(color_id:any) =>{
        const img = colorImgList.filter((data:any)=>data.color_id === color_id);
        
        if(img.length === 0) return;
        if(img[0].file === ''){
            alert('이미지를 선택해주세요');
            return;
        }
        const res = await apiAdmSetProductColorImgUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.phone_id,color_id,img[0].file);
        if(res.result){
            alert('색상 이미지를 수정했습니다.');
        }else{
            alert(res.error);
        }
    }

    // 썸네일 수정
    const thumbnailUpdate = async() =>{
        const res = await apiAdmSetProductImgUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.phone_id,thumbnail)
        if(res.result){
            alert('썸네일을 수정했습니다.')
        }else{
            alert(res.error);
        }
    }

    // 상품 이미지 수정
    const ContentUpdate = async() =>{
        const res = await apiAdmSetProductContentImgUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,info.phone_id,content);
        if(res.result){
            alert('상품정보를 수정했습니다.')
        }else{
            alert(res.error);
        }
    }

    // 사전예약 변경
    const reservationChange = async(val:any) =>{
        const reservationRes = await apiAmdSEtProductReservation(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,val);
        if(reservationRes.result){
            alert(`사전예약이 ${val == 0 ? '취소 ':''}되었습니다.`)
            setReservation(val);
        }
    }

    return (
        <LeftNavBar idKey={'6'}>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>상품 관리</BasicText>
                    <InnerWrap>
                        <Grid container={true} spacing={2}>
                            <Grid item md={6} xs={12}>
                                <ContentWrap>
                                    <AdminProductReservationItem margin='0' value={reservation} setValue={reservationChange} />
                                    <ItemWrap pWidth={120}>
                                        <BasicText>모델명</BasicText>
                                        <InputWrap>
                                            <input value={info?.phone_model ?? ''} onChange={(e:any)=>itemChange('phone_model',e.target.value)}></input>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>상품제목명</BasicText>
                                        <InputWrap>
                                            <input value={info?.phone_name ?? ''} onChange={(e:any)=>itemChange('phone_name',e.target.value)}></input>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>용량</BasicText>
                                        <ContentWrap>
                                            {info?.phone_volume === undefined || info?.phone_volume.length === 0 ?
                                                <></> :
                                                info?.phone_volume.map((data:any,index:any)=>{
                                                    return(
                                                        <ItemWrap gap='15px' key={`size-${index}`}>
                                                            <InputWrap style={{padding:0}}>
                                                                <SelectItem value={data.volume_id} setValue={(val:any)=>sizeChange(val,index)} none='none'>
                                                                    {sizeList === undefined || sizeList.length === 0 ?
                                                                        <MenuItem value=''></MenuItem> :
                                                                        sizeList.map((data:any,index:any)=>{
                                                                            return(
                                                                                <MenuItem key={`size-menu-${index}`} value={data.volume_id}>{data.volume_spec}{String(data.volume_spec).length < 3 ? 'TB' : "GB"}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </SelectItem>
                                                            </InputWrap>
                                                            <InputWrap>
                                                                <input value={numberWithComma(info[`phone_upgrade_price_${index+1}`] ?? 0)} onChange={(e:any)=>itemChange(`phone_upgrade_price_${index+1}`,valueChange(e))}/>
                                                            </InputWrap>
                                                        </ItemWrap>
                                                    )
                                                })
                                            }
                                        </ContentWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>색상</BasicText>
                                        <ContentWrap>
                                            {info?.phone_colors === undefined || info?.phone_colors.length === 0 ?
                                                <></> :
                                                info?.phone_colors.map((data:any,index:any)=>{
                                                    const colorImg = colorImgList.filter((item:any)=>item.color_id === data.color_id);
                                                    const {filePreview} = colorImg[0];
                                                    return(
                                                        <div style={{width:'100%'}} key={`color-${index}`}>
                                                            <ItemWrap gap='15px'>
                                                                <ColorItem bg={data.color_hex}></ColorItem>
                                                                <InputWrap>
                                                                    <BasicText style={{cursor:'pointer'}} onClick={()=>colorImgClick(colorImg[0])}>{data.color_name}</BasicText>
                                                                </InputWrap>
                                                                <ImgBtnWrap onClick={()=>colorImgUpdate(data.color_id)}>이미지 등록하기</ImgBtnWrap>
                                                              
                                                            </ItemWrap>
                                                            <div style={{width:'100%'}}>
                                                                <ColorImgWrap>
                                                                    <img src={filePreview} onError={handleImageError}></img>
                                                                </ColorImgWrap>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <input style={{display:'none'}} ref={colorImgRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>colorImgChange(e)}></input>
                                        </ContentWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>가입통신사</BasicText>
                                        <CheckWrap>
                                            {info?.phone_support_telco === undefined || info?.phone_support_telco.length === 0 ?
                                                <></> :
                                                info?.phone_support_telco.map((data:any,index:any)=>{
                                                    return(
                                                        <CheckItemWrap key={`tel-${index}`}>
                                                            <input type='radio' defaultChecked={true} readOnly></input> {TEL_LIST[data.telco_id].name}
                                                        </CheckItemWrap>
                                                    )
                                                })
                                            }
                                        </CheckWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>구매방법</BasicText>
                                        <CheckWrap>
                                            <CheckItemWrap>
                                                <input type='radio' readOnly defaultChecked={true}></input> 일시불
                                            </CheckItemWrap>
                                            <CheckItemWrap>
                                                <input type='radio' readOnly defaultChecked={true}></input> 24개월
                                            </CheckItemWrap>
                                            <CheckItemWrap>
                                                <input type='radio' readOnly defaultChecked={true}></input> 30개월
                                            </CheckItemWrap>
                                            <CheckItemWrap>
                                                <input type='radio' readOnly defaultChecked={true}></input> 36개월
                                            </CheckItemWrap>
                                        </CheckWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>썸네일</BasicText>
                                        <div style={{width:'100%'}}>
                                            <ItemWrap gap='15px'>
                                                <InputWrap>
                                                    <input style={{cursor:'pointer'}} value={thumbnailName ?? ''} onClick={()=>thumbnailRef?.current?.click()} placeholder='이미지 선택' readOnly/>
                                                </InputWrap>
                                                <ImgBtnWrap onClick={thumbnailUpdate}>이미지 등록하기</ImgBtnWrap>
                                            </ItemWrap>
                                            <input style={{display:'none'}} ref={thumbnailRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>fileChange(e,thumbnailRef,setThumbnail,setThumbnailPreview,setThumbnailName)}></input>
                                            <ImgWrap>
                                                {thumbnailPreview !== '' &&
                                                    <img style={{maxWidth:'100%',maxHeight:'280px'}} src={thumbnailPreview}></img>
                                                }
                                            </ImgWrap>
                                        </div>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>상품정보</BasicText>
                                        <div style={{width:'100%'}}>
                                            <ItemWrap gap='15px'>
                                                <InputWrap>
                                                    <input style={{cursor:'pointer'}} value={contentName ?? ''} onClick={()=>contentRef?.current?.click()} placeholder='이미지 선택' readOnly/>
                                                </InputWrap>
                                                <ImgBtnWrap onClick={ContentUpdate}>이미지 등록하기</ImgBtnWrap>
                                            </ItemWrap>
                                            <input style={{display:'none'}} ref={contentRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>fileChange(e,contentRef,setContent,setContentPreview,setContentName)}></input>
                                            <ImgWrap>
                                                {contentPreview !== '' &&
                                                    <img src={contentPreview}></img>
                                                }
                                            </ImgWrap>
                                        </div>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>기본 텍스트</BasicText>
                                        <InputWrap>
                                            <input value={info?.phone_description ?? ''} onChange={(e:any)=>itemChange('phone_description',e.target.value)}></input>
                                        </InputWrap>
                                    </ItemWrap>
                                </ContentWrap>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ContentWrap>
                                    <ItemWrap align='start'>
                                        <BasicText>공시지원 할인</BasicText>
                                        <ContentWrap>
                                            {info?.discount === undefined || info?.discount.length === 0 ?
                                                <></> :
                                                info?.discount.map((data:any,index:any)=>{
                                                    return(
                                                        <ItemWrap gap='15px' key={`discount-${index}`}>
                                                            <InputWrap max='120px'>{TEL_LIST[data.telco_id].name}</InputWrap>
                                                            <InputWrap>
                                                                <input value={numberWithComma(data?.discount_price_1 ?? 0) } onChange={(e:any)=>itemChange('discount',valueChange(e),index,'discount_price_1')}></input>
                                                            </InputWrap>
                                                        </ItemWrap>
                                                    )
                                                })
                                            }
                                        </ContentWrap>
                                    </ItemWrap>
                                    <ItemWrap>
                                        <BasicText>선택약정 할인</BasicText>
                                        <InputWrap>
                                            <BasicText>요금제 기준 25%</BasicText>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap>
                                        <BasicText>마이폰할인</BasicText>
                                        <InputWrap>
                                            <input value={numberWithComma(info?.phone_im_discount ?? 0)} onChange={(e:any)=>itemChange('phone_im_discount',valueChange(e))}></input>
                                        </InputWrap>
                                    </ItemWrap>
                                </ContentWrap>
                            </Grid>
                            <Grid item xs={12}>
                                <BtnWrap>
                                    <BtnItem bg='#ad63ff' onClick={()=>btnAction()}>상품정보변경</BtnItem>
                                    <BtnItem bg='#ff9f00' onClick={()=>productStateClick(info?.phone_visible === '1' ? '0' : '1')}>판매 {PRODUCT_STATE_R[info?.phone_visible ?? '1']  }</BtnItem>
                                </BtnWrap>
                            </Grid>
                        </Grid>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;

const BtnWrap = styled(Row)`
    gap: 15px;
    margin-top: 35px;
    flex-wrap: wrap;
`;

const ColorItem = styled.div<{bg:string}>`
    width: 30px;
    height: 30px;
    background-color: ${({bg}) => bg ?? '#fff'};
    flex-shrink: 0;
    border-radius: 6px;
`;

const ColorImgWrap = styled.div`
    margin-top: 5px;
    width: 80px;
    max-height: 80px;
    overflow: hidden;
    & img{
        max-width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    flex-shrink: 0;
    max-width: 100%;
`;

const ImgWrap = styled.div`
    max-width: 340px;
    max-height: 280px;
    overflow-y: auto;
    & img{
        max-width: 100%;
    }
`;
const CheckWrap = styled(Row)`
    gap: 5px 15px;
    flex-wrap: wrap;
`;

const CheckItemWrap = styled.label`
    display: flex;
    align-items: center;
    gap: 4px;
`;