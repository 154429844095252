import { IMG_URL } from "../api/config";


export const USE_CARRIER = [
    {"id":"1","name":"sk","img":IMG_URL+"/img/logo/skt.png"},
    {"id":"2","name":"kt","img":IMG_URL+"/img/logo/kt.png"},
    {"id":"3","name":"lg","img":IMG_URL+"/img/logo/lg.png"},
    {"id":"4","name":"알뜰폰","img":""}
];

export const TERMS_LIST = [
    {id:1,name:'1. 개통 후 185일간은 반드시 회선을 유지 해주셔야 합니다.', content:
`*개통 후 회선유지 185일 (기간내 회선 해지, 타통신사 재이동, 일시정지시 약정 위약금 100% 부과및 단말기 할부금이 청구되오니 유의하시기 바랍니다)
*선택약정할인시 요금제 유지 125일 / 공시지원금 약정시 요금제 유지185일 유지 후 요금하향 하실 수 있습니다.
*가족결합, 제휴카드, 휴대폰 보험은 고객센터를 통해 정확한 내용을 확인하시고 신청하셔야 하며, 개통이 완료된 후에 114 고객센터를 통해 신청하셔야 합니다. 가족결합, 제휴카드, 휴대폰 보험에 대한 누락에 대한 책임은 자사에 없음을 미리 고지 드립니다.`, state: false,},
    {id:2,name:'2. 위약금은 신청 전 미리 확인 해주세요.', content:
`* 통신사 이동, 기기변경, 요금 변경이나 해지등으로 인한 위약금이 발생될 수 있습니다.
* 사전에 통신사 고객센터(114)에 확인 후 신청 부탁드립니다.`, state: false,},
    {id:3,name:'3. 휴대폰 개통을 위한 개인정보 수집 동의', content:
`본인은 본사이트에서 구매 및 이용하기 위해서, 다음과 '회사'가 본인의 개인정보를 수집, 이용하고 서비스의 제공을 위해 개인정보의 취급을 위탁하는 것에 동의합니다.
·수집항복 : 이름, 생년월일, 휴대폰 번호, 이메일, 배송지 주소
·이용목적 : 통신 서비스 가입 및 휴대폰 전산개통 및 고객 상담 및 MMS광고 발송
·개인정보의 보유기간 및 이용기간 : 개인 정보 획득일로부터 6개월간 보유 및 이용
위 개인 정보 수집, 이용 및 취급위탁에 동의하지 않으실 경우, 이벤트에 참여하실 수 없습니다.
회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된 사항은, 회사가 운영하는 개인정보취급방침에 따릅니다. 본인은 위 내용을 숙지하였으며 이에 동의합니다.`, state: false,},
    {id:4,name:'4. 공식신청서 할인 에 관한 안내', content:
`※ 공식 신청서에는 사이트상에 기재된 할인금액 표기가 불가 합니다.
작성하실 공식신청서는 본인이 신청 했다는것을 확인하는 인증 용도로 작성 되기에 가격할인이 안나와있어도 안심하셔도됩니다.
개통시 고객님께 안내된 금액 대로 개통 됨을 안내드립니다.`, state: false,},
]



// const PHONE_LIST = [갤럭시 S24울트라, 
//     갤럭시S24 플러스, 
//     갤럭시 S24, 
//     갤럭시 Z플립5, 
//     갤럭시 Z 폴드5,
 
//     아이폰15, 
//     아이폰15 Plus, 
//     아이폰15 프로, 
//     아이폰15 프로맥스]

export const G_S24 = {
    model: '갤럭시 24S',
    title: '갤럭시 24S',
    network: '5G',
    size: [{"id":"1","value":"256","name":"GB"},{"id":"2","value":"512","name":"GB"}],
    color: [
        {"id":"1","color":"#4641D9","name":"바이올렛"},
        {"id":"2","color":"#8C8C8C","name":"그레이"},
        {"id":"3","color":"#121212","name":"블랙"},
        {"id":"4","color":"#E8DB6B","name":"옐로우"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 1,
    ph_price: 1155000,
}
export const G_S24_PLUS = {
    model: '갤럭시 24S 플러스',
    title: '갤럭시 24S 플러스',
    network: '5G',
    size: [{"id":"1","value":"256","name":"GB"},{"id":"2","value":"512","name":"GB"}],
    color: [
        {"id":"1","color":"#4641D9","name":"바이올렛"},
        {"id":"2","color":"#8C8C8C","name":"그레이"},
        {"id":"3","color":"#121212","name":"블랙"},
        {"id":"4","color":"#E8DB6B","name":"옐로우"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 1,
    ph_price: 1353000,
}
export const G_S24_ULTRA = {
    model: '갤럭시 24S 울트라',
    title: '갤럭시 24S 울트라',
    network: '5G',
    size: [{"id":"1","value":"256","name":"GB"},{"id":"2","value":"512","name":"GB"}],
    color: [
        {"id":"1","color":"#4641D9","name":"바이올렛"},
        {"id":"2","color":"#8C8C8C","name":"그레이"},
        {"id":"3","color":"#121212","name":"블랙"},
        {"id":"4","color":"#E8DB6B","name":"옐로우"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 1,
    ph_price: 1698400,
}

export const G_FLIP5 = {
    model: '갤럭시 Z플립5',
    title: '갤럭시 Z플립5 [특가]',
    network: '5G',
    size: [{"id":"1","value":"256","name":"GB"},{"id":"2","value":"512","name":"GB"}],
    color: [
        {"id":"1","color":"#4C4C4C","name":"그라파이트"},
        {"id":"2","color":"#B7F0B1","name":"민트"},
        {"id":"3","color":"#FAF4C0","name":"크림"},
        {"id":"4","color":"#FFB2F5","name":"라벤더"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"},
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''},
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 1,
    ph_price: 1399200,
}

export const G_FOLD5 = {
    model: '갤럭시 Z폴드5',
    title: '갤럭시 Z폴드5',
    network: '5G',
    size: [{"id":"1","value":"256","name":"GB"},{"id":"2","value":"512","name":"GB"}],
    color: [
        {"id":"1","color":"#121212","name":"블랙"},
        {"id":"2","color":"#FAF4C0","name":"크림"},
        {"id":"3","color":"#6799FF","name":"블루"},
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"},
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 1,
    ph_price: 2097700,
}



export const i_15 = {
    model: '아이폰 15',
    title: '아이폰 15',
    network: '5G',
    size: [
        {"id":"1","value":"128","name":"GB"},
        {"id":"2","value":"256","name":"GB"},
        {"id":"3","value":"512","name":"GB"}
    ],
    color: [
        {"id":"1","color":"#D6F0FF","name":"블루"},
        {"id":"2","color":"#FFD8D8","name":"핑크"},
        {"id":"3","color":"#F7EA6E","name":"옐로"},
        {"id":"4","color":"#B1DB4E","name":"그린"},
        {"id":"5","color":"#484848","name":"블랙"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 2,
    ph_price: 1243000,
}


export const i_15PlUS = {
    model: '아이폰 15 플러스',
    title: '아이폰 15 플러스',
    network: '5G',
    size: [
        {"id":"1","value":"128","name":"GB"},
        {"id":"2","value":"256","name":"GB"},
        {"id":"3","value":"512","name":"GB"}
    ],
    color: [
        {"id":"1","color":"#D6F0FF","name":"블루"},
        {"id":"2","color":"#FFD8D8","name":"핑크"},
        {"id":"3","color":"#F7EA6E","name":"옐로"},
        {"id":"4","color":"#B1DB4E","name":"그린"},
        {"id":"5","color":"#484848","name":"블랙"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 2,
    ph_price: 1342000,
}

export const i_15PRO = {
    model: '아이폰 15 프로',
    title: '아이폰 15 프로',
    network: '5G',
    size: [
        {"id":"1","value":"128","name":"GB"},
        {"id":"2","value":"256","name":"GB"},
        {"id":"3","value":"512","name":"GB"}
    ],
    color: [
        {"id":"1","color":"#BDBDBD","name":"내추럴티타늄"},
        {"id":"2","color":"#002266","name":"블루티타늄"},
        {"id":"3","color":"#EAEAEA","name":"화이트티타늄"},
        {"id":"4","color":"#363636","name":"블랙티타늄"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 2,
    ph_price: 1540000,
}

export const i_15PRO_MAX = {
    model: '아이폰 15 프로맥스',
    title: '아이폰 15 프로맥스',
    network: '5G',
    size: [
        {"id":"1","value":"256","name":"GB"},
        {"id":"2","value":"512","name":"GB"},
        {"id":"3","value":"1","name":"TB"}
    ],
    color: [
        {"id":"1","color":"#BDBDBD","name":"내추럴티타늄"},
        {"id":"2","color":"#002266","name":"블루티타늄"},
        {"id":"3","color":"#EAEAEA","name":"화이트티타늄"},
        {"id":"4","color":"#363636","name":"블랙티타늄"}
    ],
    nowUse: [
        {"id":"1","name":"skt","img":"/img/logo/skt.png"},
        {"id":"2","name":"kt","img":"/img/logo/kt.png"},
        {"id":"3","name":"lg","img":"/img/logo/lg.png"}
    ],
    discount: [
        {name:'공시지원할인\n 총 600,000원',title:'단말기 할인',content:'개통당시 1회 단말기 구매 비용 할인',type:'추천'},
        {name:'선택약정할인\n 총 600,000원',title:'25% 요금할인',content:'약정기간동안 매월 기본료 25%할인',type:''}
    ],
    buyType: [
        {"id":"1","name":"일시불","value":"0" ,"type":""},
        {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
        {"id":"3","name":"30개월","value":"30" ,"type":""},
        {"id":"4","name":"36개월","value":"36" ,"type":""}
    ],
    ph_type: 2,
    ph_price: 1892000,
}