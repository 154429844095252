import React from 'react'
import styled from 'styled-components'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { addTheme } from '../../../style/addTheme';

export default function SelectItem({width,value,setValue,children,margin,none,disabled}:any) {

    const handleChange = (e:SelectChangeEvent) =>{
        setValue(e.target.value);
    }
    
    return (
        <Wrap margin={margin} width={width}>
            <Select
                value={value ?? ''}
                onChange={handleChange}
                displayEmpty
                size='small'
                sx={{
                    border:'none',
                    '.MuiSelect-select':{color:'#000',border:'none',textAlign:'left'}
                }}
                fullWidth
                disabled={disabled}
            >
                {value === undefined || value === '' || none === undefined ? 
                    <MenuItem value="" disabled>
                        <em>None</em>
                    </MenuItem>:
                    none
                }
                {children !== undefined && children.length !== 0 &&
                    children
                }
            </Select>
        </Wrap>
    )
}

const Wrap = styled.div<{margin?:string,width?:string}>`
    & svg{
        color: #000;
    }
    margin: ${props => props.margin ?? 0};
    width: ${({width}) => width ?? 'auto'};
    & fieldset{
        border: none !important;
    }
    /* max-width: 280px;
    @media ${addTheme.media.m}{
        max-width: 100%;
    } */
`;
