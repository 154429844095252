import React from 'react'
import styled from 'styled-components'
import { Center, ContentWrap, Row } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { ORDER_STATUS } from '../../../data/payment';

export default function MyPageStepItem({info}:any) {
    const step = Number(info?.order_status ?? 0)+1;
    
    return (
        <Wrap>
            <StepItemWrap>
                {step === 11 ?
                    <BasicText color='#fff' fontSize={13}>주문취소</BasicText>:
                    <>
                        <BasicText color='#fff' fontSize={13}>STEP</BasicText>
                        <BasicText color='#fff' fontSize={18}>0{step}</BasicText>
                    </>
                }
            </StepItemWrap>
            <StepTextWrap gap={'4px 0'}>
                <BasicText fontSize={17} lh={1.2}>{ORDER_STATUS[step-1]}</BasicText>
                <BasicText fontSize={17} lh={1.2} wh='pre-wrap'>{info?.order_process_comment}</BasicText>
                {/* {step === 0 && 
                    <BasicText fontSize={17} lh={1.2}>{ORDER_STATUS[step]}</BasicText>
                }
                {step === 2 &&
                    <>
                        <BasicText fontSize={17} lh={1.2}>{'김여명'} 고객님 담당자를 통한 해피콜 진행된 이후</BasicText>
                        <BasicText fontSize={17} lh={1.2} spColor={addTheme.main}><span>{'test@email.com'}</span> 이메일로 통신사 공식 신청서를 전송해드리겠습니다.</BasicText>
                        <BasicText fontSize={17} lh={1.2}>마이폰에서 진행하는 해피콜 잘 받아주시길 부탁드립니다.</BasicText>
                    </>
                }
                {step === 3 &&
                    <>
                        <BasicText fontSize={17} lh={1.2}>{'김여명'} 고객님 휴대폰을 배송중에 있습니다.</BasicText>
                        <BasicText fontSize={17} lh={1.2} spColor={addTheme.main}><span>{'CJ대한통운'} {'100000000'}</span> 을 확인해주세요.</BasicText>
                    </>
                }
                {step === 10 &&
                    <BasicText fontSize={17} lh={1.2}>{ORDER_STATUS[step]}</BasicText>
                } */}
            </StepTextWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    background-color: #fafafa;
    padding: 20px 35px;
    border-radius: 8px;
    gap: 20px;
`;

const StepItemWrap = styled(Center)`
    flex-direction: column;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    background-color: ${ addTheme.main};
    flex-shrink: 0;
    @media ${addTheme.media.sx}{
        width: 70px;
        height: 70px;
    }
`;

const StepTextWrap = styled(ContentWrap)``;
