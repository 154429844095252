import React from 'react'
import styled from 'styled-components'
import HeadPart from '../part/HeadPart';
import FooterPart from '../part/FooterPart';

export default function LayoutPage({gClick,iClick,children}:any) {
    return (
        <Wrap>
            <HeadPart gClick={gClick} iClick={iClick} />
            <ContentWrap>
                {children}
            </ContentWrap>
            <FooterPart />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    min-height: calc(100vh - 117px - 169px - 65px);
    padding-bottom: 80px;
`;
