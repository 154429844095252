import { axiosConfigInstance } from "../config";

// 관리자 상품 리스트
export const apiAdmGetProduct = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'getAdminProductList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 디테일
export const apiAdmGetProductDetail = async(token:string,admin_id:any,phone_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'getAdminProductDetailInfo',
            params:{
                admin_id,phone_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 활성 / 비활성
// status : 1 활성 / 0 비활성
export const apiAdmSetProductState = async(token:string,admin_id:any,phone_id:any,status:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductChangeStatus',
            params:{
                admin_id,phone_id,status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 용량 리스트
export const apiAdmGetProductSize = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'getAdminProductVolumeList',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 색별 이미지 등록/수정하기
export const apiAdmSetProductColorImgUpdate = async(token:string,admin_id:any,phone_id:any,color_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductImageChange',
            params:{
                admin_id,phone_id,color_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 썸네일 등록/수정하기
export const apiAdmSetProductImgUpdate = async(token:string,admin_id:any,phone_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductThumbnailImageChange',
            params:{
                admin_id,phone_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 설명 이미지 등록/수정
export const apiAdmSetProductContentImgUpdate = async(token:string,admin_id:any,phone_id:any,image_base64:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductDescriptionImageChange',
            params:{
                admin_id,phone_id,image_base64
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 수정
// export const apiAdmSetProductUpdate = async(token:string,admin_id:any,phone_id:any,phone_name:any,phone_model:any,phone_price:any,phone_description:any,discount_price:any,phone_im_discount:any) =>{
export const apiAdmSetProductUpdate = async({token,admin_id,phone_id,phone_name,phone_model,phone_price,phone_description,discount_price,phone_im_discount}:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductInfoEdit',
            params:{
                admin_id,phone_id,phone_name,phone_model,phone_price,phone_description,discount_price,phone_im_discount
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 상품 추가
export const apiAdmSetProductAdd = async(token:string,admin_id:any,data:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product',token).post('',{
            method: 'adminProductAdd',
            params: {
                admin_id,
                ...data,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 사전예약 - 0 사전예약x, 1 사전예약
export const apiAmdSEtProductReservation = async(token:string,admin_id:any,phone_id:any,reverse_status:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/product/',token).post('',{
            method: 'adminProductReverseStatusChange',
            params: {
                admin_id,phone_id,reverse_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}