import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_COMPANY= 'IM/SET_COMPANY';

export const globalCompanyCreator = createAction(SET_COMPANY);

export function setGlobalCompany(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalCompanyCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalCompanyCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface CompanyState {
  list:Array<any>;
  link:any;
}

const initialState: CompanyState = {
  list:[],
  link:{},
};

export const globalCompany = handleActions<CompanyState, any>({
  [SET_COMPANY]: (state, action: ReturnType<typeof globalCompanyCreator>) => ({
    list: action.payload.data.list ?? state.list,
    link: action.payload.data.link ?? state.link,
  }),
}, initialState);
