import React from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';

export default function PartTitleItem({border,title,type,goTotal}:any) {
    const totalClick = () =>{
        if(goTotal){
            goTotal();
        }
    }
    return (
        <Wrap>
            <TitleWrap border={border}>
                <LeftWrap>
                    <BasicText color='#000' fontSize={18} mFontSize={16} fontWeight={700}>{title}</BasicText>
                    {type === 'chart' &&
                        <ChartTitleWrap>
                            <ChartTitleItem>
                                <ChartTitleBg bg='#ff6b00' />
                                <BasicText>방문자수</BasicText>
                            </ChartTitleItem>
                            <ChartTitleItem>
                                <ChartTitleBg bg='#0063ff' />
                                <BasicText>상품주문수</BasicText>
                            </ChartTitleItem>
                            <ChartTitleItem>
                                <ChartTitleBg bg='#00bb31' />
                                <BasicText>문의수</BasicText>
                            </ChartTitleItem>
                            <ChartTitleItem>
                                <ChartTitleBg bg='#c900ff' />
                                <BasicText>후기수</BasicText>
                            </ChartTitleItem>
                        </ChartTitleWrap>
                    }
                </LeftWrap>
                <RightWrap>
                    {goTotal &&
                        <BasicText style={{cursor:'pointer'}} color='#989898' onClick={()=>totalClick()}>전체 보기</BasicText>
                    }
                </RightWrap>
            </TitleWrap>
        </Wrap>
    )
}


const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)<{border:boolean}>`
    flex-wrap: wrap;
    padding: 4px 0 6px;
    border-bottom: ${({border}) => border ? '2px solid #ebebeb' : 'none'};
`;
const LeftWrap = styled(Row)`
    gap: 20px;
    width: auto;
    &>p{
        flex-shrink: 0;
    }
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;

const ChartTitleWrap = styled(Row)`
    width: auto;
    column-gap: 15px;
    flex-wrap: wrap;
`;
const ChartTitleItem = styled(Center)`
    gap: 8px;
`;
const ChartTitleBg = styled.div<{bg:string}>`
    width: 10px;
    height: 10px;
    background-color: ${({bg}) => bg ?? '#fff'};
`;
const RightWrap = styled.div`
    flex-shrink: 0;
`;