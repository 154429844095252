import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from './../store';
import {theme} from '../../style/extends/themeColor';

const SET_CUSTOM_THEME = 'mesh-dash/theme/SET_CUSTOM_THEME';

export const themeActionCreator = createAction(SET_CUSTOM_THEME);

export function thunkTheme(themecolor: string): ThunkAction<void, RootState, null, ReturnType<typeof themeActionCreator>> {
    return async function (dispatch) {
    try {
      dispatch(themeActionCreator({
        themecolor: themecolor
      }));
    } catch (error) {
      
    }
  };
}

export interface ThemeState {
    themecolor?: any;
}

const initialState: ThemeState = {
  themecolor: {
    fixblack: '#000000',
    fixwhite: '#ffffff',
    black: '#000000',
    white: '#ffffff',
    red: 'red',
    signalRed: '#eb493d',
    blue: '#0071BC',
    main: '#00ff00',
    main2: '#00cf00',
    submain: '#ebd0f5',
    subtext: '#afb3c4',
    decrease: '#faad14',
    lightBlack: '#111',
    btnBg: '#3e3e3e',
    timeBg: '#616161',
    cancel: '#cd373c',
    dots:'#a8a8a8',
    searchBorder: '#eee',
    myPageBox:'#222226',
    myPageSc:'#3d3d44',
  }
};

export const themeColor = handleActions<ThemeState, any>({
  [SET_CUSTOM_THEME]: (state, action: ReturnType<typeof themeActionCreator>) => ({
    themecolor: theme(action.payload.themecolor)
  }),
}, initialState);
