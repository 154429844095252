import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../../basic/BasicText';
import { BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import { discountPlan, discountRes, numberWithComma } from '../../../../utils/format/number';
import { valueChange } from '../../../../utils/format/string';
import { apiGetPhList } from '../../../../api/api_phone';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { apiAdmGetProductDetail } from '../../../../api/adm/adm_api_product';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { TEL_LIST_CATE } from '../../../../data/payment';
import { apiAdmGetCardList, apiAdmGetPlanList, apiAdmSetOrderBuyInfo } from '../../../../api/adm/adm_api_order';

const DISCOUNT_STR = ['공시지원금','선택약정'];

export default function AdminOrderDetailBuyPart({info}:any) {
    const adminInfo = useAdminInfo();
    const [productList,setProductList] = useState<any>([]);
    const [phoneId,setPhoneId] = useState('');
    const [colorList,setColorList] = useState<any>([]);
    const [colorId,setColorId] = useState('');
    const [sizeList,setSizeList] = useState<any>([]);
    const [sizeId,setSizeId] = useState('');
    const [telId,setTelId] = useState('');
    const [joinType,setJoinType] = useState('1');
    const [planList,setPlanList] = useState<any>([]);
    const [telPlanList,setTelPlanList] = useState<any>([]);
    const [telPlan,setTelPlan] = useState('');
    const [plan,setPlan] = useState<any>();
    const [discountType,setDiscountType] = useState('1');
    const [discount,setDiscount] = useState('');
    const [price,setPrice] = useState('');
    const [imDiscount,setImDiscount] = useState('');
    const [month,setMonth] = useState('0');
    const [cardList,setCardList] = useState<any>([]);
    const [telCardList,setTelCardList] = useState<any>([]);
    const [telCard,setTelCard] = useState('');
    const [card,setCard] = useState<any>();

    useEffect(()=>{
        getProductList();
    },[])

    useEffect(()=>{
        if(info === undefined) return;
        setPhoneId(info?.phone_id ?? '');
        setColorId(String(info?.color_id ?? ''));
        setSizeId(String(info?.volume_id ?? ''));
        setTelId(String(info?.telco_id ?? ''));
        setTelPlan(String(info?.plan_id ?? ''));
        setDiscountType(info?.discount_type ?? '1');
        setDiscount(String(info?.order_basic_discount_price ?? '0'));
        setPrice(info?.order_device_price ?? '');
        setImDiscount(info?.order_im_discount_price ?? '');
        setMonth(String(info?.payment_month ?? 0));
        setTelCard(String(info?.dis_card_id ?? 0));
        setJoinType(info?.order_type ?? '1');
    },[info]);

    useEffect(()=>{
        if(telCard === '') return;
        const item = telCardList.filter((data:any)=>data.dis_card_id === Number(telCard));
        setCard(item.length === 0 ? undefined : item[0]);
    },[telCard,telCardList,])

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getPlan();
        getCard();
    },[adminInfo.admin_user_id]);

    // 통신사 요금제
    useEffect(()=>{
        if(planList === undefined || planList.length === 0) return;
        const newList = planList.filter((data:any)=>data.telco_id == telId);
        setTelPlanList(newList);
    },[telId,planList])

    // 통신사 제휴 카드
    useEffect(()=>{
        if(cardList === undefined || cardList.length === 0) return;
        const newList = cardList.filter((data:any)=>data.telco_id == telId);
        setTelCardList(newList);
    },[telId,cardList])

    useEffect(()=>{
        if(phoneId === '' || adminInfo.admin_user_id === 0) return;
        getDetail();
    },[phoneId,adminInfo.admin_user_id])

    // 폰 종류
    const getProductList = async() =>{
        const res = await apiGetPhList(1,50);
        if(res.result){
            setProductList(res.data);
        }
    }

    // 디테일
    const getDetail = async() =>{
        const res = await apiAdmGetProductDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,phoneId);
        if(res.result){
            setColorList(res.data[0].phone_colors);
            setSizeList(res.data[0].phone_volume);
        }
    }

    // 통신사 변경
    const telcoChange = (val:any) =>{
        setTelId(val);
        setTelPlan('');
        setTelPlanList([])
    }

    // 요금제
    const getPlan = async() =>{
        const res = await apiAdmGetPlanList(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setPlanList(res.data);
        }
    }
    
    // 카드 종류
    const getCard = async() =>{
        const res = await apiAdmGetCardList(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setCardList(res.data);
        }
    }

    // 요금제 선택
    const planSelect = (val:any) =>{
        setTelPlan(val);
        // const item = telPlanList.filter((data:any)=>data.plan_id == val);
        // setPlan(item.length === 0 ? undefined : item[0]);
    }

    const updateClick = async() =>{
        const data = {
            order_id: info?.order_id,
            phone_id: phoneId,
            volume_id: sizeId,
            color_id: colorId,
            order_type: joinType,
            plan_id: telPlan,
            telco_id: telId,
            discount_type: discountType,
            order_device_price: price,
            order_basic_discount_price: discount,
            order_im_discount_price: imDiscount,
            order_installment_month: month,
            dis_card_id: telCard,
        }
        const res = await apiAdmSetOrderBuyInfo(adminInfo.admin_jwt_token,adminInfo.admin_user_id,data);
        if(res.result){
            alert('구매정보를 수정했습니다.');
        }else{
            alert(res.error)
        }
    }

    // 카드선택
    const cardChange = (val:any) =>{
        setTelCard(val);
        const item = telCardList.filter((data:any)=>data.dis_card_id === val);
        setCard(item.length === 0 ? undefined : item[0]);
    }
    
    return (
        <Wrap>
            <BasicText fontSize={18} mFontSize={16} fontWeight={700}>구매정보</BasicText>
            <ContentWrap mt={10}>
                <ItemWrap pWidth={100}>
                    <BasicText>모델명</BasicText>
                    <InputWrap style={{padding:0}}>
                        <SelectItem value={phoneId} setValue={setPhoneId}>
                            {productList === undefined || productList.length === 0 ?
                                <MenuItem value=''></MenuItem> :
                                productList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`product-item-${index}`} value={data.phone_id}>{data.phone_model}</MenuItem>
                                    )
                                })
                            }
                        </SelectItem>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>용량</BasicText>
                    <InputWrap style={{padding:0}}>
                        {info === undefined || sizeList === undefined || sizeList.length === 0 ?
                            <></>:
                            <SelectItem value={sizeId ?? ''} setValue={setSizeId}>
                                {sizeList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`size-item-${index}`} value={data.volume_id}>{data.volume_spec} {String(data.volume_spec).length < 3 ? 'TB' : 'GB'}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>색상</BasicText>
                    <InputWrap style={{padding:0}}>
                        {info === undefined || colorList === undefined || colorList.length === 0 ?
                            <></> :
                            <SelectItem value={colorId} setValue={setColorId}>
                                {colorList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`color-item-${index}`} value={data.color_id}>{data.color_name}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>가입통신사</BasicText>
                    <InputWrap style={{padding:0}}>
                        <SelectItem value={telId} setValue={telcoChange} none={telId}>
                            {TEL_LIST_CATE === undefined || TEL_LIST_CATE.length === 0 ?
                                <MenuItem value=''></MenuItem> :
                                TEL_LIST_CATE.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`tel-item-${index}`} value={data.id}>{data.name}</MenuItem>
                                    )
                                })
                            }
                        </SelectItem>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>가입유형</BasicText>
                    <Row style={{gap:'10px',flexWrap:'wrap'}}>
                        <label>
                            <input type='radio' name='join-type' value={'1'} onChange={(e:any)=>setJoinType(e.target.value)} checked={joinType === '1'}></input> 기기변경
                        </label>
                        <label>
                            <input type='radio' name='join-type' value={'2'} onChange={(e:any)=>setJoinType(e.target.value)} checked={joinType === '2'}></input> 통신사변경
                        </label>
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>가입요금제</BasicText>
                    <InputWrap style={{padding:0}}>
                        {telPlanList === undefined || telPlanList.length === 0 ?
                            <></> :
                            <SelectItem value={telPlan} setValue={planSelect} none={telPlan}>
                                {telPlanList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`plan-item-${index}`} value={data.plan_id}>{data.plan_name} (월 {numberWithComma(data.plan_price)}원)</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>할인유형</BasicText>
                    <Row style={{gap:'10px',flexWrap:'wrap'}}>
                        <label>
                            <input type='radio' name='discount-type' value={'1'} onChange={(e:any)=>setDiscountType(e.target.value)} checked={discountType === '1'} ></input> 공시지원
                        </label>
                        <label>
                            <input type='radio' name='discount-type' value={'2'} onChange={(e:any)=>setDiscountType(e.target.value)} checked={discountType === '2'}></input> 선택약정
                        </label>
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>출고가</BasicText>
                    <InputWrap>
                        <input value={numberWithComma(price)} onChange={(e:any)=>setPrice(valueChange(e))}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>{DISCOUNT_STR[Number(discountType)-1]}</BasicText>
                    <InputWrap>
                        {discountType === '1' ?
                            <input value={numberWithComma(discount)} onChange={(e:any)=>setDiscount(valueChange(e))}></input> :
                            <BasicText>요금제 기준 25%</BasicText>
                        }
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>마이폰지원금</BasicText>
                    <InputWrap>
                        <input value={numberWithComma(imDiscount)} onChange={(e:any)=>setImDiscount(valueChange(e))}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>할부개월</BasicText>
                    <InputWrap>
                        <input value={month} onChange={(e:any)=>setMonth(valueChange(e))}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>할부원금</BasicText>
                    <InputWrap>
                        <BasicText>{numberWithComma(discountRes(price,imDiscount,discountType,discountType === '1' ? discount : plan?.plan_price,card?.dis_card_month_price))}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={100}>
                    <BasicText>제휴카드</BasicText>
                    <InputWrap style={{padding:0}}>
                        {telCardList === undefined || telCardList.length === 0 ?
                            <></> :
                            <SelectItem value={telCard} setValue={cardChange}>
                                {telCardList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`card-${index}`} value={data.dis_card_id}>{data.card_name} {numberWithComma(data.dis_card_month_price)}원 x 24개월</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
            <Row style={{marginTop:'15px'}}>
                <BtnItem bg='#ad63ff' onClick={updateClick}>구매정보변경</BtnItem>
            </Row>
        </Wrap>
    )
}

const Wrap = styled.div``;