import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import LeftNavBar from '../../../common/part/LeftNavBar';
import styled from 'styled-components';
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { fileTypeRes, phoneNumberFormat } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import { Grid } from '@mui/material';
import { apiAdmGetInquiryDetail, apiAdmSetInquiryAnswer } from '../../../../api/adm/adm_api_inquiry';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

export default function AdminInquiryAnswerPage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [cate,setCate] = useState('');
    const [content,setContent] = useState('');
    const [questionImg,setQuestionImg] = useState('');
    const [questionImgState,setQuestionImgState] = useState(false);
    const [state,setState] = useState('0');

    const [answer,setAnswer] = useState('');
    const fileRef = useRef<any>(null);
    const [file,setFile] = useState<any>(null);
    const [filePreview,setFilePreview] = useState('');
    const [fileState,setFileState] = useState(false);

    useEffect(()=>{
        if(id === undefined) return;
        getInfo();
    },[id])

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();
                setFileState(true);
                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const getInfo = async() =>{
        const res = await apiAdmGetInquiryDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            setName(res.data.inquiry_user_name);
            setPhone(res.data.inquiry_user_phone_number);
            setEmail(res.data.inquiry_user_email);
            setContent(res.data.inquiry_question);
            setCate(res.data.i_category_title);
            setQuestionImg(res.data.inquiry_question_image);
            setAnswer(res.data.inquiry_answer);
            setState(res.data.inquiry_status);
            setFilePreview(res.data.inquiry_answer_image);
        }
    }

    const questionImgChk = () =>{
        setQuestionImgState(!questionImgState);
    }

    const btnAction = async() =>{
        if(content === '') {
            alert('답변을 입력해주세요.');
            return;
        }
        const res = await apiAdmSetInquiryAnswer(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,answer,file);
        if(res.result){
            alert('답변을 했습니다.');
            getInfo();
        }else{
            alert(res.error);
        }
    }

    return (
        <LeftNavBar idKey='4'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>문의 답변</BasicText>
                    <InnerWrap>
                        <Grid container={true} spacing={3}>
                            <Grid item md={6} xs={12}>
                                <ContentWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>작성자</BasicText>
                                        <InputWrap>
                                            <BasicText>{name}</BasicText>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>연락처</BasicText>
                                        <InputWrap>
                                            <BasicText>{phoneNumberFormat(phone)}</BasicText>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>이메일</BasicText>
                                        <InputWrap>
                                            <BasicText>{email}</BasicText>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120}>
                                        <BasicText>카테고리</BasicText>
                                        <InputWrap>
                                            <BasicText>{cate}</BasicText>
                                        </InputWrap>
                                    </ItemWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>문의내용</BasicText>
                                        <div style={{width:'100%'}}>
                                            <InputWrap>
                                                <textarea value={content} rows={6} readOnly></textarea>
                                            </InputWrap>
                                            {questionImg !== '' &&
                                                <ImgBtnWrap onClick={questionImgChk}>이미지 확인</ImgBtnWrap>
                                            }
                                            {questionImgState === false ?
                                                <></> :
                                                <FilePreviewWrap>
                                                    <img src={questionImg} alt='file-img'></img>
                                                </FilePreviewWrap>
                                            }
                                        </div>
                                    </ItemWrap>
                                </ContentWrap>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ContentWrap>
                                    <ItemWrap pWidth={120} align='start'>
                                        <BasicText>문의답변</BasicText>
                                        <div style={{width:'100%'}}>
                                            <InputWrap>
                                                <textarea value={answer} onChange={(e:any) => setAnswer(e.target.value)} rows={10} maxLength={2000} readOnly={state === '1'}></textarea>
                                            </InputWrap>
                                            {state === '0' ?
                                                <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap> :
                                                filePreview !== '' &&
                                                    <ImgBtnWrap onClick={()=>setFileState(!fileState)}>이미지 확인</ImgBtnWrap>
                                            }
                                            <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                            {filePreview === '' || fileState === false ?
                                                <></> :
                                                <FilePreviewWrap>
                                                    <img src={filePreview} alt='file-img'></img>
                                                </FilePreviewWrap>
                                            }
                                        </div>
                                    </ItemWrap>
                                </ContentWrap>
                                <Row style={{marginTop:'30px',justifyContent:'end'}}>
                                    {state === '0' &&
                                        <BtnItem onClick={btnAction} bg={addTheme.main} padding='14px 25px'>답변 작성</BtnItem>
                                    }
                                </Row>
                            </Grid>
                        </Grid>
                    </InnerWrap>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;
