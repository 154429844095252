import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { useTheme } from '../../utils/custom-hooks/common/useTheme';
import { numberWithComma } from '../../utils/format/number';
import { addTheme } from '../../style/addTheme';

interface BasicTextProps {
    noWrap?: boolean;
    fontFamily?: string;
    fontSize?: number;
    mFontSize?: number;
    children?: any;
    align?: 'left'|'right'|'center';
    fontWeight?: string | number;
    theme?: any;
    color?: any;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    xs?: boolean;
    md?: boolean;
    comma?: boolean;
    hover?: boolean;
    tooltip?: boolean;
    tooltipText?: string;
    wrapText?:any;
    style?:any;
    onClick?:any;
    op?: number;
    ls?: number;
    lh?: number;
    wh?: any;
    pd?: string;
    deco?: string;
    spColor?:string;
    line?:number;
    cr?:string;
    minHeight?:string;
}

export default function BasicText({noWrap, fontFamily, fontSize, mFontSize, theme, color, children, align, fontWeight, marginTop, marginBottom, marginLeft, marginRight, xs, md, comma, hover, tooltip, tooltipText,wrapText,ls,wh,lh,pd,deco,spColor,line,cr,minHeight, ...rest}: BasicTextProps){
    const themeColor = useTheme();

    return (
        <>
        <Text
            noWrap={noWrap ?? false}
            fontSize={fontSize}
            mfontsize={mFontSize}
            fontFamily={fontFamily ?? 'unset'}
            color={color ?? themeColor[theme]}
            fontWeight={fontWeight}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            hover={hover}
            wraptext={wrapText}
            align={align}
            ls={ls}
            wh={wh}
            lh={lh}
            pd={pd}
            deco={deco}
            spcolor={spColor}
            line={line}
            minHeight={minHeight}
            {...rest}
            >
            {comma ? numberWithComma(children) : children}
        </Text>
        </>
    )
}

const Text = styled(Typography)<{
    marginTop?: number,
    marginBottom?: number,
    marginLeft?: number,
    marginRight?: number,
    hover?: boolean,
    wraptext?:any,
    fontSize?:number,
    mfontsize?:number,
    op?:number,
    align?:string,
    ls?:number,
    lh?:number,
    wh?:any,
    pd?: string,
    deco?: string,
    spcolor?: string,
    line?:number,
    cr?:string,
    minHeight?:string,
}>`
    margin-top: ${props => props.marginTop ?? 0}px;
    margin-bottom: ${props => props.marginBottom ?? 0}px;
    margin-left: ${props => props.marginLeft ?? 0}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    word-break: ${props => props.wraptext ? 'break-word' : 'normal'};
    opacity: ${props => props.op ?? 1};
    text-align: ${props => props.align ?? 'left'};
    letter-spacing: ${props => props.ls ?? 0}px;
    white-space: ${props => props.wh ?? 'normal'};
    line-height: ${props => props.lh ?? 1.5} !important;
    padding: ${props => props.pd ?? 0};
    text-decoration: ${props => props.deco ?? 'none'};
    min-height: ${props => props.minHeight ?? 'auto'};
    &:hover {
        ${props => props.hover ? 
            `
            color: #00b3ff;
            text-shadow: 
                0 0 10px #00b3ff,
                0 0 20px #00b3ff,
                0 0 40px #00b3ff,
                0 0 80px #00b3ff,
                0 0 120px #00b3ff;
            ` : ``
        }
    }
    &>strong{
        font-weight:700;
    }
    &>span{
        color: ${({spcolor}) => spcolor ?? '#000'};
    }
    @media ${addTheme.media.s}{
        font-size: ${props => props.mfontsize ?? props.fontSize}px !important;
    }
    transition: 0.2s linear;
    ${props => props.line !== undefined && css<{line?:number, fontSize?:number,mFontSize?:number, lh?:number,}>`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${(props:any) => props.line ?? 1};
        -webkit-box-orient: vertical;
        word-break: break-word;
        height: ${(props:any)=> `calc(${props.lh ?? 1.5} * ${props.line}em)`};
        /* @media ${addTheme.media.m}{
            height: ${(props:any)=>(props.line ?? 1) * (props.mFontSize === undefined ? props.fontSize === undefined ? 19 : props.fontSize+3 : props.mFontSize+3)}px;
        } */
    `}
`;
