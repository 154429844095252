import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { IMG_URL } from '../../../api/config';
import { apiGetCompany } from '../../../api/api_home';
import { useGlobalCompany } from '../../../utils/custom-hooks/common/useGlobalCompany';
import { useDispatch } from 'react-redux';
import { setGlobalCompany } from '../../../store/common/globalCompany';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';

const COMPANY_INFO_NAME:any = {
    "config_company_name": "회사명",
    "config_company_repre_name": "대표자",
    "config_business_number": "사업자등록번호",
    "config_company_address": "회사 주소",
    "config_private_info_name": "개인정보책임자",
    "config_private_info_email": "개인정보관리 책임자 이메일",
    "config_company_email": "이메일",
    "config_partner_company": "판매제휴업체",
    "config_online_prev_approve_code": "온라인 사전승낙서 코드",
    "config_company_call_number": "전화번호",
    "config_social_kakao_link": "카톡링크"
}

export default function FooterPart() {
    const company = useGlobalCompany();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(company.list.length === 0 ){
            getInfo();
        }
    },[company.list,]);

    const getInfo = async() =>{
        const res = await apiGetCompany();
        if(res.result){
            const newArr = Object.keys(res.data).map((key:any)=>({name:key,value:res.data[key],title:COMPANY_INFO_NAME[key]}));
            const newList = newArr.filter((data:any) => (data.name !== "config_company_call_number" && data.name !== "config_social_kakao_link"));
            
            // const newLinkList = newArr.filter((data:any) => (data.name === "config_company_call_number" || data.name === "config_social_kakao_link"));
            const newLinkList = res.data;

            dispatch(setGlobalCompany({
                list: newList,
                link: newLinkList,
            }))
        }
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <LogoWrap>
                    <img src={'/img/asset/logo.png'}></img>
                </LogoWrap>
                <TermsWrap>
                    <BasicText onClick={()=>navigate('/terms')}>이용약관</BasicText>
                    <BasicText onClick={()=>navigate('/termsInfo')}>개인정보처리방침</BasicText>
                    <BasicText onClick={()=>navigate('/termsShopping')}>쇼핑몰 이용약관</BasicText>
                </TermsWrap>
                <ContentWrap>
                    <LeftWrap>
                        <TextWrap>
                            {company.list === undefined || company.list.length === 0 ?
                                <></> :
                                company.list.map((data:any,index:any)=>{
                                    if(data.name === 'config_online_prev_approve_code') return
                                    return(
                                        <BasicText color='#333' fontSize={13} key={`company-info-${index}`}>{data.title}: {data.value}</BasicText>    
                                    )
                                })
                            }
                            <BasicText color='#333' fontSize={13}>통신판매업 신고: 제 2022-고양일산동-2341</BasicText>
                            {/* {COMPANY_INFO.map((data:any,index:any)=>{
                                return(
                                    <BasicText style={{flexShrink:0}} color='#333' fontSize={13} key={`company-info-${index}`}>{data.name}: {data.val}</BasicText>
                                )
                            })} */}
                        </TextWrap>
                        <IconWrap>
                            <a href="https://www.ictmarket.or.kr:8443/precon/pop_CertIcon.do?PRECON_REQ_ID=PRE0000172399"target="_blank"> 
                                <img style={{cursor:"pointer",height:'120px'}} src="https://www.ictmarket.or.kr:8443/getCertIcon.do?cert_icon=KP23051165034V001" />
                            </a>
                        </IconWrap>
                    </LeftWrap>
                    <RightWrap>

                    </RightWrap>
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #e5e7eb;
    padding: 20px 0;
`;

const LogoWrap = styled.div`
    height: 50px;
    &>img{
        height: 100%;
    }
`;

const TermsWrap = styled(Row)`
    gap: 8px 15px;
    margin-top: 10px;
    flex-wrap: wrap;
    & p{
        cursor: pointer;
    }
    @media ${addTheme.media.sx}{
        flex-direction: column;
        align-items: start;
    }
`;

const ContentWrap = styled(RowBetween)`
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    @media ${addTheme.media.sx}{
        margin-top: 30px;
    }
`;
const LeftWrap = styled(Row)`
    gap: 20px;
    width: auto;
    max-width: 50%;
    @media ${addTheme.media.m}{
        max-width: 100%;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const TextWrap = styled(Row)`
    width: auto;
    flex-wrap: wrap;
    column-gap: 20px;
`;
const IconWrap = styled(Row)`
    width: auto;
`;
const RightWrap = styled(Row)`
    width: auto;
    max-width: 50%;
`;