import React, { useRef, useState } from 'react'
import { Swiper, SwiperClass } from 'swiper/react';
import 'swiper/css';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { FaAngleLeft,FaAngleRight } from "react-icons/fa";

interface SwiperPartType {
    space?: number;
    width?: number;
    view?: number | 'auto',
    pagination?: boolean;
    navigation?: boolean;
    style?: any;
    children: any;
    setIndex?: any;
}

export default function SwiperPart({space,width,view,pagination,navigation,style,children,setIndex}:SwiperPartType) {
    const swiperRef = useRef<any>(null);
    const [idx,setIdx] = useState(0);
    const [swiper, setSwiper] = useState<SwiperClass>();
    const handlePrev = () => {
        swiper?.slidePrev()
    }
    const handleNext = () => {
      swiper?.slideNext()
    }
    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            if(setIndex === undefined) {
                setIdx(swiperRef.current.swiper.realIndex)
                return
            };
            setIndex(swiperRef.current.swiper.realIndex);
        }
    };

    return (
        <Wrap width={width}>
            {navigation &&
                <>
                    <button className='swiper-btn swiper-btn-left' onClick={handlePrev}><FaAngleLeft color='#000' size={26}/></button>
                    <button className='swiper-btn swiper-btn-right' onClick={handleNext}><FaAngleRight color='#000' size={26}/></button>
                </>
            }
            <Swiper
                ref={swiperRef}
                slidesPerView={view ?? 'auto'}
                spaceBetween={space ?? 10}
                modules={pagination ? [Pagination] : []}
                pagination={pagination}
                style={style}
                onSlideChange={handleSlideChange}
                onSwiper={(e:any) => {setSwiper(e)}}
            >
                {children}
            </Swiper>
        </Wrap>
    )
}

const Wrap = styled.div<{width?:number}>`
    width: 100%;
    position: relative;
    & .swiper-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    & .swiper-btn-left{
        left: -26px;
    }
    & .swiper-btn-right{
        right: -26px;
    }

    & .swiper-slide{
        width: ${({width}) => width ?? 100}%;
    }
`;