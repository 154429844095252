import Decimal from 'decimal.js';

export function decimalRoundOff(value: number, decimalLength: number) {
    return new Decimal(value).mul((10 ** decimalLength)).floor().div(10 ** decimalLength).toNumber();
}

export function numberWithComma(value: number | string) {
    if(value === undefined) return '0';
    if(value === null) return '0'
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function discountPlanRes(value:any){
    if(value === undefined || value === '') return 0;
    const val = Number(value);
    const discount = val * 0.25;
    const plan = val - discount;
    return plan;
}

export function discountPlan(value:any){
    if(value === undefined || value === '') return 0;
    const plan = Number(value) * 0.25;
    return plan * 24;
}

export function discountRes(price:any,imDiscount:any,type:string,discount:any,cardDiscount:any,result?:boolean){
    const priceNum = Number(price ?? 0);
    const imDisNum = Number(imDiscount ?? 0);
    const disNum = Number(discount ?? 0);
    const cardNum = Number(cardDiscount ?? 0);
    
    if(type === '1'){
        // 공시
        const res = priceNum - imDisNum - disNum - (cardNum * 24);
        return res < 0 ? 0 :res
    }else {
        // 약정
        const res = priceNum - imDisNum - discountPlan(cardNum) - (cardNum * 24);
        return res < 0 ? 0 :res
    }
}

export function numberPrice(value: number | string){
    const comma = numberWithComma(value);
    return comma + '원';
}

export function valueFormat(value: number, decimalLength: number) {
    const result = decimalRoundOff(value, decimalLength);
    return numberWithComma(result);
};

export function balanceFormat(amount: any, decimalLength?: number) {
    if(typeof amount === 'string'){
        amount = parseInt(amount);
    }
    const balance = parseFloat((amount / 1000000000000000000).toFixed(4));
    return balance;
};

export function kFormatter(num: any) {
    return numberWithComma(Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000)) + 'K' : Math.sign(num)*Math.abs(num) ?? '0K')
}

// 대기
export function delay(milliseconds:number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

// 배열 자르기
export function ChunkArray(array:Array<any>, chunkSize:number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    return chunkedArray;
}
// 노래 길이
export const durationFormat = (sec:any) =>{
    const minutes = Math.floor(sec / 60);
    const remainingSeconds = Math.floor(sec % 60);
    const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    return formattedTime;
}

/**
 // 공식: 대출원금 × (월이자율 × (1 + 월이자율)^상환기간) ÷ ((1 + 월이자율)^상환기간 - 1)
 * 두 숫자를 더합니다.
 * @param {number} principal 할부 원금 (출고가 - 할인가 등)
 * @param {number} annualInterestRate 연이자율 (5.9%) default 5.9
 * @param {number} repaymentPeriod 할부개월 default 24
 * @returns {number} 총 월 납부금액
 */
export function calculateMonthlyPayment(principal:number, repaymentPeriod = 24, annualInterestRate = 5.9) {
    const monthlyInterestRate = annualInterestRate / 100 / 12;
    const numerator = principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, repaymentPeriod);
    const denominator = Math.pow(1 + monthlyInterestRate, repaymentPeriod) - 1;
    const monthlyPayment = numerator / denominator;
    
    return Math.floor(monthlyPayment);
}
