import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_AlERT= 'SUBOX_AUDIO/SET_ALERT';

export const globalAlertCreator = createAction(SET_AlERT);

export function setGlobalAlert(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalAlertCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalAlertCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface AlertState {
  open:boolean;
  txt:string;
  bottom: number;
}

const initialState: AlertState = {
  open:false,
  txt:'',
  bottom: 0,
};

export const globalAlert = handleActions<AlertState, any>({
  [SET_AlERT]: (state, action: ReturnType<typeof globalAlertCreator>) => ({
    open: action.payload.data.open ?? false,
    txt: action.payload.data.txt ?? state.txt,
    bottom: action.payload.data.bottom ?? initialState.bottom,
  }),
}, initialState);
