import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_REGISTER= 'IM/SET_REGISTER';
const SET_REGISTER_INIT= 'IM/SET_REGISTER_INIT';

export const globalRegisterCreator = createAction(SET_REGISTER);
export const globalRegisterInit = createAction(SET_REGISTER_INIT);

export function setGlobalRegister(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalRegisterCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalRegisterCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

// telco_type 1 - 기기변경 / 2 - 통신사이동
// discount_type 1 - 공시지원금 / 2 - 선택약정할인
export interface RegisterState {
  phone_id: number;
  model:string;
  size: any;
  color: any;
  telco: any;
  telco_type: "1" | "2";
  discount_type: "1" | "2";
  plan: any;
  buyType: number;
  price: number;
  imDiscount: number;
  discount1: number;
  discount2: number;
  cardDiscount: number;
  result: number;
  card: any;
}

const initialState: RegisterState = {
  phone_id: 0,
  model: '',
  size: undefined,
  color: undefined,
  telco: undefined,
  telco_type: "1",
  discount_type: "1",
  plan: undefined,
  buyType: 24,
  price: 0,
  imDiscount: 0,
  discount1: 0,
  discount2: 0,
  cardDiscount: 0,
  result: 0,
  card: undefined,
};

export const globalRegister = handleActions<RegisterState, any>({
  [SET_REGISTER]: (state, action: ReturnType<typeof globalRegisterCreator>) => ({
    phone_id: action.payload.data.phone_id ?? state.phone_id,
    model: action.payload.data.model ?? state.model,
    size: action.payload.data.size ?? state.size,
    color: action.payload.data.color ?? state.color,
    telco: action.payload.data.telco ?? state.telco,
    telco_type: action.payload.data.telco_type ?? state.telco_type,
    discount_type: action.payload.data.discount_type ?? state.discount_type,
    plan: action.payload.data.plan ?? state.plan,
    buyType: action.payload.data.buyType ?? state.buyType,
    price: action.payload.data.price ?? state.price,
    imDiscount: action.payload.data.imDiscount ?? state.imDiscount,
    discount1: action.payload.data.discount1 ?? state.discount1,
    discount2: action.payload.data.discount2 ?? state.discount2,
    cardDiscount: action.payload.data.cardDiscount ?? state.cardDiscount,
    result: action.payload.data.result ?? state.result,
    card: action.payload.data.card ?? state.card,
  }),
  [SET_REGISTER_INIT]: (state, action: ReturnType<typeof globalRegisterInit>) => (initialState),
}, initialState);
