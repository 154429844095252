import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LeftNavBar from '../../../common/part/LeftNavBar';
import { useNavigate, useParams } from 'react-router';
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { valueChange } from '../../../../utils/format/string';
import { apiAdmGetBannerDetail, apiAdmSetBanner, apiAdmSetBannerUpdate, apiAdmSetDelBanner } from '../../../../api/adm/adm_api_banner';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';

const FILE_INIT = {file:null,preview:'',fileData:''};

export default function AdminPopupUpdatePage() {
    const {id} = useParams();
    const adminInfo = useAdminInfo();
    const navigate = useNavigate();
    const [type,setType] = useState('등록');
    const [title,setTitle] = useState('');
    const [count,setCount] = useState('');
    const moRef = useRef<any>(null);
    const [moFile,setMoFile] = useState<any>(FILE_INIT);
    const [link,setLink] = useState('');
    
    useEffect(()=>{
        if(id === undefined){
            setType('등록')
        }else{
            setType('수정')
        }
    },[id]);

    useEffect(()=>{
        if(id === undefined) return
        if(adminInfo.admin_jwt_token === '') return;
        getDetail();
    },[adminInfo.admin_jwt_token,id])

    const getDetail = async() =>{
        const res = await apiAdmGetBannerDetail(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
        if(res.result){
            const {data} = res;
            setTitle(data.banner_title);
            setCount(data.banner_order);
            setLink(data.banner_link_url);
            setMoFile((obj:any)=>({...obj,preview:data.banner_img_mo}))
        }
    }

    const moInputClick = () =>{
        if(moRef.current){
            moRef.current.click();
        }
    }
    // 모바일 파일 선택
    const moFileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const preImg = URL.createObjectURL(file);
            const reader = new FileReader();
            setMoFile((obj:any)=>({...obj,file:file,preview:preImg}))
            reader.onload = () => {
                const result = reader.result;
                setMoFile((obj:any)=>({...obj,fileData:result}))
            };

            reader.readAsDataURL(file);
        }
    }

    const reset = () =>{
        setTitle('');
        setCount('');
        setLink('');
        setMoFile(FILE_INIT);
    }
    
    const btnAction = async() =>{
        if(type === '등록'){
            const res = await apiAdmSetBanner(adminInfo.admin_jwt_token,adminInfo.admin_user_id,title,'2',link,count,moFile?.fileData ?? '', moFile?.fileData ?? '');
            if(res.result){
                alert('팝업을 등록했습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
        }else{
            const res = await apiAdmSetBannerUpdate(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id,title,'2',link,count,moFile?.fileData ?? '', moFile?.fileData ?? '')
            if(res.result){
                alert('팝업을 수정했습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
        }
    }

    const btnDelAction = async() =>{
        if(window.confirm('팝업를 삭제하시겠습니까?')){
            const res = await apiAdmSetDelBanner(adminInfo.admin_jwt_token,adminInfo.admin_user_id,id);
            if(res.result){
                alert('팝업를 삭제했습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <LeftNavBar idKey='9'>
            <Wrap>
                <AdminWrap marginTop={0}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>팝업 {type === '등록' ? type : '관리'}</BasicText>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>제목</BasicText>
                                <InputWrap>
                                    <input onChange={(e:any)=>setTitle(e.target.value)} value={title} placeholder='팝업 제목을 입력해주세요'/>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>노출 순서</BasicText>
                                <InputWrap>
                                    <input onChange={(e:any)=>setCount(valueChange(e))} value={count} placeholder='노출 순서를 입력해주세요. 미입력시 자동으로 뒷순서로 배치됩니다.'/>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={120} align='start'>
                                <BasicText marginTop={1}>이미지</BasicText>
                                <div style={{width:'100%'}}>
                                    <FileInputWrap>
                                        <InputWrap>
                                            <BasicText>{moFile?.file !== null ? moFile?.file.name : '이미지 파일명'}</BasicText>
                                            <input ref={moRef} type='file' accept='image' onChange={moFileChange}/>
                                        </InputWrap>
                                        <ImgShowBtn onClick={moInputClick}>
                                            이미지 보기
                                        </ImgShowBtn>
                                    </FileInputWrap>
                                    {moFile?.preview ?
                                        <PreImgWrap>
                                            <img src={moFile?.preview}></img>
                                        </PreImgWrap> :
                                        <></>
                                    }
                                </div>
                            </ItemWrap>
                            <ItemWrap pWidth={120}>
                                <BasicText>링크 이동</BasicText>
                                <InputWrap>
                                    <input onChange={(e:any)=>setLink(e.target.value)} value={link} placeholder='팝업 클릭시, 이동할 링크를 입력해주세요.'/>
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                    </InnerWrap>
                    <Row style={{marginTop:'20px',gap:'15px'}}>
                        {type === '등록' ?
                            <BtnItem bg={'#ad63ff'} padding='10px 25px' onClick={btnAction}>팝업정보등록</BtnItem> :
                            <>
                                <BtnItem bg={'#ad63ff'} padding='10px 25px' onClick={btnAction}>팝업정보변경</BtnItem>
                                <BtnItem bg={'#ff7600'} padding='10px 25px' onClick={btnDelAction}>팝업 삭제</BtnItem> 
                            </>
                        }
                    </Row>
                </AdminWrap>
            </Wrap>
        </LeftNavBar>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;

const FileInputWrap = styled(Row)`
    align-items: stretch;
    gap: 10px;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
    & input{
        display: none;
    }
`;
const ImgShowBtn = styled(Center)`
    flex-shrink: 0;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 18px;
    cursor: pointer;
`;

const PreImgWrap = styled.div`
    width: 100%;
    max-width: 280px;
    margin-top: 10px;
    &>img{
        width: 100%;
    }
`;
