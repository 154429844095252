import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_POPUP= 'PHONE_POPUP/SET_POPUP';

export const globalPopupCreator = createAction(SET_POPUP);

export function setGlobalPopup(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalPopupCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalPopupCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface PopupState {
  open: boolean;
  time: number;
}

const initialState: PopupState = {
  open: false,
  time: 0,
};

export const globalPopup = handleActions<PopupState, any>({
  [SET_POPUP]: (state, action: ReturnType<typeof globalPopupCreator>) => ({
    open: action.payload.data.open ?? false,
    time: action.payload.data.open ?? state.time,
  }),
}, initialState);
