import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import ReviewItem from '../item/ReviewItem';
import { IoClose } from "react-icons/io5";
import { FaChevronLeft,FaChevronRight } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme';
import { apiGetReview } from '../../../api/api_review';

export default function ReviewDetailPart({open,setOpen,item,setItem,page,count}:any) {
    const [nowPage,setNowPage] = useState(1);
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [selectIdx,setSelectIdx] = useState(0);
    const [viewItem,setViewItem] = useState();
    const [leftState,setLeftState] = useState(false);

    useEffect(()=>{
        setNowPage(page);
    },[page])

    useEffect(()=>{
        getList();
    },[nowPage,leftState,count]);

    useEffect(()=>{
        setSelectIdx(item);
    },[item]);

    useEffect(()=>{
        if(selectIdx === undefined) return;
        getView();
    },[selectIdx,list]);

    const getList = async() =>{
        const res = await apiGetReview(nowPage ?? 1,count ?? 9);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            if(leftState){
                setSelectIdx(res.data.length-1);
                setLeftState(false);
            }
        }
    }

    const getView = () =>{
        const arr = list.filter((data:any,index:any) => index === selectIdx);
        if(arr.length !== 0) {
            setViewItem(arr[0]);
        }
    }

    const leftClick = () =>{
        if(selectIdx === 0){
            if(nowPage <= 1) return;
            setNowPage((p:number)=>p-1);
            setLeftState(true);
        }else{
            setSelectIdx((idx:number) => idx - 1);
        }
    }

    const rightClick = () =>{
        if(selectIdx >= list.length-1){
            if(nowPage >= total) return;
            setNowPage((p:number)=>Number(p)+1);
            setSelectIdx(0);
        }else{
            setSelectIdx((idx:number) => idx + 1);
        }
    }

    const close = () =>{
        setItem(undefined);
        setOpen(false)
    }
    return (
        <Wrap open={open}>
            <BgWrap onClick={close}/>
            <ContentWrap>
                <ArrowLeftItem onClick={leftClick}>
                    <FaChevronLeft color='#fff' size={36}/>
                </ArrowLeftItem>
                <ReviewItem type='detail' data={viewItem}/>
                <ArrowRightItem onClick={rightClick}>
                    <FaChevronRight color='#fff' size={36}/>
                </ArrowRightItem>
                <CloseWrap>
                    <IoClose color='#fff' onClick={close} size={20}></IoClose>
                </CloseWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)<{open:boolean}>`
    display: ${({open}) => open ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 99;
`;

const BgWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
`;

const ContentWrap = styled.div`
    width: 100%;
    max-width: 580px;
    position: relative;
    z-index: 5;
`;

const ArrowItem = styled.div`
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 6;
`;

const ArrowLeftItem = styled(ArrowItem)`
    left: -45px;
    @media ${addTheme.media.s}{
        left: 5px;
    }
`;
const ArrowRightItem = styled(ArrowItem)`
    right: -45px;
    @media ${addTheme.media.s}{
        right: 5px;
    }
`;

const CloseWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;