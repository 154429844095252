import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../../common/part/LeftNavBar'
import styled from 'styled-components'
import { Grid } from '@mui/material';
import AdminHomeOrderPart from '../part/AdminHomeOrderPart';
import PartTitleItem from '../item/PartTitleItem';
import AdminHomeVisitorsPart from '../part/AdminHomeVisitorsPart';
import AdminHomePart from '../part/AdminHomePart';
import { useNavigate } from 'react-router';
import AdminHomeInquiryPart from '../part/AdminHomeInquiryPart';
import AdminHomeReviewPart from '../part/AdminHomeReviewPart';
import AdminHomeChartPart from '../part/AdminHomeChartPart';
import { useAdminInfo } from '../../../../utils/custom-hooks/common/useAdminUserInfo';
import { apiAdmGetOrder } from '../../../../api/adm/adm_api_order';
import { apiAdmGetVisit } from '../../../../api/adm/adm_api_login';
import { apiAdmGetInquiry } from '../../../../api/adm/adm_api_inquiry';
import { apiAdmGetReview } from '../../../../api/adm/adm_api_review';

export default function AdminHomePage() {
    const navigate = useNavigate();
    const adminInfo = useAdminInfo();
    const [orderList,setOrderList] = useState<any>([]);
    const [visit,setVisit] = useState<any>();
    const [inquiryList,setInquiryList] = useState<any>([]);
    const [reviewList,setReviewList] = useState<any>([]);

    useEffect(()=>{
        if(adminInfo.admin_user_id === 0) return;
        getOrderList();
        getVisit();
        getInquiry();
        getReview();
    },[adminInfo.admin_user_id])

    const goTotal = (router:string) =>{
        navigate(router);
    }

    const getOrderList = async() =>{
        const res = await apiAdmGetOrder(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,5);
        if(res.result){
            setOrderList(res.data);
        }
    }

    const getVisit = async() =>{
        const res = await apiAdmGetVisit(adminInfo.admin_jwt_token,adminInfo.admin_user_id);
        if(res.result){
            setVisit(res.data);
        }
    }

    const getInquiry = async() =>{
        const res = await apiAdmGetInquiry(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,5);
        if(res.result){
            setInquiryList(res.data);
        }
    }

    const getReview= async() =>{
        const res = await apiAdmGetReview(adminInfo.admin_jwt_token,adminInfo.admin_user_id,1,5);
        if(res.result){
            setReviewList(res.data);
        }
    }

    return (
        <LeftNavBar idKey={'1'}>
            <Grid container={true} spacing={1}>
                <Grid item md={6} xs={12}>
                    <AdminHomePart title={<PartTitleItem title='주문현황' border={true} goTotal={()=>goTotal('/adm/order/1')}/>}>
                        <AdminHomeOrderPart list={orderList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='방문자' border={true} />}>
                        <AdminHomeVisitorsPart data={visit} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='상품문의' border={true} goTotal={()=>goTotal('/adm/inquiry/1')}/>} >
                        <AdminHomeInquiryPart list={inquiryList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='구매후기' border={true} goTotal={()=>goTotal('/adm/review/1')} />} >
                        <AdminHomeReviewPart list={reviewList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={12}>
                    <AdminHomePart title={<PartTitleItem title='주문현황' type='chart'/> } >
                        <AdminHomeChartPart />
                    </AdminHomePart>
                </Grid>
            </Grid> 
        </LeftNavBar>
    )
}

const Wrap = styled.div``;