import React from 'react'
import styled,{keyframes} from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import UserItem from '../item/UserItem';
import RectItem from '../../common/item/RectItem';
import { IMG_URL } from '../../../api/config';

export default function MainBannerPart() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <ImgWrap>
                    <img alt='banner-img' src={IMG_URL+'/img/asset/banner-01-1.png'} />
                </ImgWrap>
                <UserWrap>
                    <UserItem type='main' />
                </UserWrap>
                <LogoWrap>
                    <LogoItem>
                        <RectItem imgUrl={IMG_URL+'/img/asset/banner-01-2.png'} />
                    </LogoItem>
                    <LogoItem>
                        <RectItem imgUrl={IMG_URL+'/img/asset/banner-01-3.png'} />
                    </LogoItem>
                    <LogoItem>
                        <RectItem imgUrl={IMG_URL+'/img/asset/banner-01-4.png'} />
                    </LogoItem>
                </LogoWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background: ${`url(${IMG_URL}/img/asset/banner-01.png)`};
    padding: 30px 0;
`;

const imgAni = keyframes`
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.15);
    }
    100%{
        transform: scale(1);
    }
`;

const ImgWrap = styled(Center)`
    overflow: hidden;
    &>img{
        width: 100%;
        max-width: 540px;
        animation: ${imgAni} 0.7s infinite linear;
    }
`;

const UserWrap = styled(Center)``;
const LogoWrap = styled(Center)`
    gap: 20px;
    padding-top: 20px;
`;

const LogoItem = styled.div`
    width: 50px;  
`;

