import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { phoneNumberFormat } from '../../../utils/format/string';

export default function InquiryContentPart({info}:any) {
    const [imgOpen,setImgOpen] = useState(false);
    const [img,setImg] = useState('');
    
    useEffect(()=>{
        if(imgOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[open]);

    const imgOpenOn = (imgUrl:string) =>{
        setImg(imgUrl);
        setImgOpen(true);
    }

    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>문의 답변 보기</BasicText>
            </TitleWrap>
            <ContentWrap>
                <ItemWrap>
                    <BasicText>작성자</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.inquiry_user_name}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>연락처</BasicText>
                    <ItemInputWrap>
                        <BasicText>{phoneNumberFormat(info?.inquiry_user_phone_number)}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>이메일</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.inquiry_user_email}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>카테고리</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.i_category_title}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap align='start'>
                    <BasicText>문의 내용</BasicText>
                    <div style={{width:'100%'}}>
                        <ItemInputWrap style={{minHeight:'150px',alignItems:'start'}}>
                            <BasicText wrapText='word-break' wh='pre-wrap'>{info?.inquiry_question}</BasicText>
                        </ItemInputWrap>
                        {info?.inquiry_question_image === undefined || info?.inquiry_question_image === '' ?
                            <></> :
                            <ImgBtnWrap onClick={()=>{imgOpenOn(info?.inquiry_question_image)}}>이미지 확인</ImgBtnWrap>
                        }
                    </div>
                </ItemWrap>
                <ItemWrap align='start'>
                    <BasicText>문의 답변</BasicText>
                    <div style={{width:'100%'}}>
                        <ItemInputWrap style={{minHeight:'150px',alignItems:'start'}}>
                            <BasicText wrapText='word-break' wh='pre-wrap'>{info?.inquiry_answer}</BasicText>
                        </ItemInputWrap>
                        {info?.inquiry_answer_image === undefined || info?.inquiry_answer_image === '' ?
                            <></> :
                            <ImgBtnWrap onClick={()=>{imgOpenOn(info?.inquiry_answer_image)}}>이미지 확인</ImgBtnWrap>
                        }
                    </div>
                </ItemWrap>
            </ContentWrap>
            <ImgWrap imgOpen={imgOpen}>
                <ImgBg onClick={()=>setImgOpen(false)}></ImgBg>
                <img src='/img/asset/banner-01-3.png'></img>
            </ImgWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 590px;
    margin: 0 auto;
`;

const TitleWrap = styled.div`
    margin-top: 100px;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 100px;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const ImgWrap = styled(Center)<{imgOpen:boolean}>`
    display: ${({imgOpen}) => imgOpen ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &>img{
        max-width: 720px;
        max-height: 100%;
        position: relative;
        z-index: 5;
    }
    background-color: rgba(0,0,0,0.4);
`;

const ImgBg = styled.div`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    z-index: 4;
`;