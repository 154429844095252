import { axiosConfigInstance } from "./config"

// 리뷰 작성
export const apiSetReview = async(phone_id:any,user_name:any,review_rate:any,review_content:any,review_image:any) =>{
    try {
        const res = await axiosConfigInstance('/review/info').post('',{
            method: 'submitReview',
            params:{
                phone_id,user_name,review_rate,review_content,review_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 디테일
export const apiGetReviewDetail = async(review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/review/info').post('',{
            method: 'getReviewDetailInfo',
            params:{
                review_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 리스트
export const apiGetReview = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/review/info').post('',{
            method: 'getReviewList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}